import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  makeStyles,
} from '@material-ui/core';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { LocationState } from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import styled from 'styled-components';
import { PaywallDialogDescription } from './core/PaywallDialogDescription';
import { PaywallPaymentCard } from './core/PaywallPaymentCard';
import { PaywallPaymentTaxForm } from './core/PaywallPaymentTaxForm';
import { PERMITTED_PAGES } from './data/useShowSubscriptionCancelledDialog';

const Dialog = styled(MuiDialog)`
  padding: 0;
  .MuiDialog-paper {
    overflow: hidden;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0 !important;
  border-radius: 4px !important;
`;

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,0,0.32)',
  },
}));

export function PaywallDialog({ isTrialExpired }: { isTrialExpired: boolean }) {
  const [pageName] = useSearchParam('page');
  const { location } = window;
  const path = location.pathname;
  const page = path.split('/').pop()?.split('?')[0];
  const [_, setQuery] = useQuery();
  const classes = useStyles();

  const isPermitted = PERMITTED_PAGES.some((pagePath) =>
    window.location.pathname.startsWith(pagePath),
  );

  useEffect(() => {
    if (isTrialExpired && !isPermitted) {
      setQuery({
        page,
        path,
      });
    }
  }, [isTrialExpired, page, path, setQuery, isPermitted]);

  if (isPermitted) {
    return null;
  }
  return (
    <Dialog
      scroll="body"
      maxWidth="md"
      fullWidth={true}
      open={isTrialExpired}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      {isTrialExpired && <TrialExpiredDialogContent pageName={pageName} />}
    </Dialog>
  );
}

interface TrialExpiredDialogContentProps {
  pageName: string;
}

const StyledBox = styled(Box)`
  padding: 40px 32px;
  flex: 1;
`;

const DialogContainer = styled(Box)`
  height: 100%;
  display: grid;
  grid-template-columns: 456px 1fr;
  overflow: hidden;
`;

function TrialExpiredDialogContent({
  pageName,
}: TrialExpiredDialogContentProps) {
  const [billingInfo, setBillingInfo] = useState<LocationState>();
  const [pagePath] = useSearchParam('path');

  useEffect(() => {
    trackEvent('Carrier Opened Paywall Dialog', {
      utm_medium: pageName,
      utm_source: pagePath,
    });
  }, [pageName, pagePath]);

  return (
    <DialogContent>
      <DialogContainer>
        <PaywallDialogDescription pageName={pageName} />

        <StyledBox>
          {billingInfo ? (
            <PaywallPaymentTaxForm
              onBack={() => {
                setBillingInfo(undefined);
              }}
              billingInfo={billingInfo}
            />
          ) : (
            <PaywallPaymentCard onSubmitSuccess={setBillingInfo} />
          )}
        </StyledBox>
      </DialogContainer>
    </DialogContent>
  );
}
