import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { useCarrierSettingsCache } from 'shared/settings/CarrierSettingsAPI';
import { CarrierInfoDTO, carrierInfoSchema } from './CarrierInfoDTO';

export function useCarrierInfo(
  options?: UseAPIQueryOptions<CarrierInfoDTO>,
): APIQueryResult<CarrierInfoDTO> {
  return useAPIQuery(
    ['profile', 'carrier-info'],
    () => requestCarrierAPI('GET /internal/web/carrier/profile/carrier-info/'),
    { ...options, schema: carrierInfoSchema },
  );
}

export function useUpdateCarrierInfo() {
  const { invalidateCarrierSettings } = useCarrierSettingsCache();
  return (values: Partial<CarrierInfoDTO>) => {
    return requestCarrierAPI(
      'PATCH /internal/web/carrier/profile/carrier-info/',
      {
        json: values,
      },
    ).then((response) => {
      invalidateCarrierSettings();
      return response;
    });
  };
}
