import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useOnboardingDialogState } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { useBecomeVerifiedCarrierNavigation } from './LoadboardAccessAPI';
import { LoadboardAccessBanner } from './LoadboardAccessBanner';

export function LoadboardAccessBannerController() {
  const becomeVerifiedCarrier = useBecomeVerifiedCarrierNavigation();
  const { openBecomeVerifiedDialog } = useOnboardingDialogState();

  return (
    <LoadboardAccessBanner
      onClick={() => {
        if (MobileAppBridge.isInjected()) {
          MobileAppBridge.openVerifiedCarrierPromotionalModal();
        } else {
          becomeVerifiedCarrier({ source: 'become_verified_banner' });
        }
      }}
      onVerifiedClick={openBecomeVerifiedDialog}
    />
  );
}
