import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { CheckboxField, ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import styled from 'styled-components';
import limitedExperience from './assets/limited_experience.png';
import { saveShippersExperienceWarningPreference } from './ShipperUtils';

const Illustration = styled.img`
  width: 100%;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 16px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0 16px;
`;

const CenteredDialogActions = styled(DialogActions)`
  justify-content: center;
  padding: 16px 16px 24px;
`;

export const List = styled.ul`
  margin: 0;
  padding-left: 20px;
  & > li {
    color: ${ColorDynamic.Dark300};
  }
`;

interface LimitedExperienceDialogProps {
  shippersGuids: string | string[];
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  source: 'Accept Offer' | 'Send Request';
}

export function LimitedExperienceDialog({
  shippersGuids,
  isOpen,
  onCancel,
  onConfirm,
  source,
}: LimitedExperienceDialogProps) {
  const [shouldNotWarn, setShouldNotWarn] = useState(false);

  useEffect(() => {
    if (isOpen) {
      trackEvent('Carrier Opened Limited Experience Dialog', {
        utm_medium: source,
      });
    }
  }, [isOpen, source]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth={true}>
      <CustomDialogTitle>
        <Stack space="small">
          <Illustration
            src={limitedExperience}
            alt="Limited expirience shipper warning"
          />
          <Typography variant="h3">Warning</Typography>
        </Stack>
      </CustomDialogTitle>
      <CustomDialogContent>
        <Stack space="small">
          <Typography>
            You are booking a load from a Verified Shipper who has limited
            experience transporting vehicles and/or using Super Dispatch. This
            shipper meets one or more of the following criteria:
          </Typography>
          <List>
            <li>
              <Typography variant="body1" color="textPrimary">
                Fewer than 3 ratings
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Fewer than 5 delivered, paid or invoiced loads
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="textPrimary">
                Less than 2 months on Super Dispatch
              </Typography>
            </li>
          </List>
          <Typography>
            These factors suggest a higher level of uncertainty and may affect
            reliability. By proceeding, you acknowledge and accept these
            potential risks.
          </Typography>
          <CheckboxField
            onChange={(_, value) => {
              setShouldNotWarn(value);
            }}
            label="Do not warn me about this shipper again"
          />
        </Stack>
      </CustomDialogContent>

      <CenteredDialogActions>
        <Inline space="small" horizontalAlign="center">
          <Button
            variant="critical"
            onClick={() => {
              trackEvent(
                'Carrier Clicked I Understand the Risks In Limited Experience Dialog',
                {
                  utm_medium: source,
                },
              );

              if (shouldNotWarn) {
                saveShippersExperienceWarningPreference(shippersGuids);
              }

              onConfirm();
            }}
          >
            I Understand the Risks
          </Button>
          <Button
            variant="neutral"
            onClick={() => {
              trackEvent(
                'Carrier Clicked Cancel In Limited Experience Dialog',
                {
                  utm_medium: source,
                },
              );
              onCancel();
            }}
          >
            Cancel
          </Button>
        </Inline>
      </CenteredDialogActions>
    </Dialog>
  );
}
