import { QueryBuilder } from '@material-ui/icons';
import { Color, Inline, Tag } from '@superdispatch/ui';
import { AccountOffIcon } from 'shared/icons';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';

interface DriverStatusProps {
  connectionStatus: string;
  connectionType: string;
  isSuspended?: boolean | null;
  descriptionID: string;
}

export function DriverStatus({
  connectionStatus,
  connectionType,
  isSuspended,
  descriptionID,
}: DriverStatusProps) {
  if (connectionStatus === 'deactivated') {
    return (
      <Tag variant="subtle" color="red" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (isSuspended) {
    return (
      <Tag variant="subtle" color="red" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (connectionStatus === 'pending' && connectionType === 'invite') {
    return (
      <Tag variant="subtle" color="yellow" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <QueryBuilder fontSize="small" />
          Invited
        </Inline>
      </Tag>
    );
  } else if (
    connectionStatus === 'pending' &&
    connectionType === 'join_request'
  ) {
    return (
      <Tag variant="subtle" color="blue" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <PersonAddAlt htmlColor={Color.Blue300} />
          Join Request
        </Inline>
      </Tag>
    );
  }

  return null;
}
