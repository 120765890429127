import { renderChildren, useResponsiveValue } from '@superdispatch/ui';
import mapboxgl from 'mapbox-gl';
import { useEffect, useMemo, useRef } from 'react';
import { MapRef, NavigationControl, useMap } from 'react-map-gl';
import { MapboxMap } from 'shared/geo/MapboxMap';
import styled from 'styled-components';
import { TrackingDriverDTO } from './data/TrackingDTO';
import {
  TrackingDriverWithLocation,
  TrackingMapboxMarker,
} from './TrackingMapboxMarker';

const KANSAS_CITY_LAT_LNG = [-94.5831797, 39.0938064];
const DEFAULT_ZOOM = 12;

const MapContainer = styled.div`
  flex-grow: 1;

  & .mapboxgl-marker {
    cursor: pointer;
  }
  .mapboxgl-popup-close-button {
    font-size: 24px;
    padding-bottom: 6px;
  }
`;

interface TrackingPageProps {
  drivers?: TrackingDriverDTO[];
  hoveredDriverId?: number;
  onFocusChange: (id?: number) => void;
}

export function TrackingMapboxMap(props: TrackingPageProps) {
  const mapRef = useRef<MapRef>(null);

  return (
    <MapContainer>
      <MapboxMap
        ref={mapRef}
        initialViewState={{
          longitude: KANSAS_CITY_LAT_LNG[0],
          latitude: KANSAS_CITY_LAT_LNG[1],
          zoom: DEFAULT_ZOOM,
        }}
        trackResize={true}
      >
        <TrackingMapboxMapMarkers {...props} />;
        <NavigationControl />
      </MapboxMap>
    </MapContainer>
  );
}

export function TrackingMapboxMapMarkers({
  drivers,
  hoveredDriverId,
  onFocusChange,
}: TrackingPageProps) {
  const map = useMap();
  const isMobile = useResponsiveValue(false, true);
  const activeDrivers = useMemo(() => {
    const driverstoShow: TrackingDriverWithLocation[] = [];

    drivers?.forEach((driver) => {
      if (driver.last_location && !driver.disabled) {
        driverstoShow.push(driver as TrackingDriverWithLocation);
      }
    });

    return driverstoShow;
  }, [drivers]);

  const isFitBoundsRef = useRef(false);

  useEffect(() => {
    if (isFitBoundsRef.current || activeDrivers.length === 0) {
      return;
    }

    isFitBoundsRef.current = true;

    const bounds = new mapboxgl.LngLatBounds();

    activeDrivers.forEach((driver) => {
      bounds.extend([
        driver.last_location.longitude,
        driver.last_location.latitude,
      ]);
    });

    if (!bounds.isEmpty()) {
      map.current?.fitBounds(bounds, {
        padding: isMobile ? 100 : 0,
        animate: false,
        maxZoom: 14,
      });
    }
  }, [activeDrivers, isMobile, map]);

  return renderChildren(
    activeDrivers.map((driver) => (
      <TrackingMapboxMarker
        key={driver.id}
        driver={driver}
        isFocused={hoveredDriverId === driver.id}
        onFocusChange={onFocusChange}
      />
    )),
  );
}
