import { Image } from '@superdispatch/ui';
import styled from 'styled-components';
import superpayLabel from './assets/superpay.png';
import superpayLabelDark from './assets/superpay_dark.png';

const StyledImg = styled(Image)<{ verticalAlign?: string }>`
  vertical-align: ${({ verticalAlign = 'middle' }) => verticalAlign};
`;

export function SuperPayIcon({
  height = 14,
  verticalAlign,
}: {
  height?: number;
  verticalAlign?: string;
}) {
  return (
    <StyledImg
      verticalAlign={verticalAlign}
      height={height}
      src={superpayLabel}
      srcDark={superpayLabelDark}
      aria-label="SuperPay label"
    />
  );
}
