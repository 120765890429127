import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  APIListQueryOptions,
  APIListQueryResult,
  findAPIListQueryItem,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryDataUpdater,
  APIQueryResult,
  setAPIQueryData,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { requestCarrierAPIPublic } from 'shared/api/PublicCarrierAPIClient';
import {
  DeactivationDriverDTO,
  DriverActivateDTO,
  DriverActivationDetailsDTO,
  driverActivationDetailsSchema,
  DriverDTO,
  DriverEditLegacyDTO,
  DriverInvitationDTO,
  driverInvitationSchema,
  driverSchema,
  InviteDriverDTO,
} from 'shared/modules/driver/DriverDTO';
import { useInvalidateSubscriptionQueries } from 'shared/modules/subscription/SubscriptionAPI';
import { shallowDiff } from 'shared/utils/DataUtils';

function setDriverQueryData(
  queryClient: QueryClient,
  guid: string,
  updater: APIQueryDataUpdater<DriverDTO>,
): void {
  setAPIQueryData(queryClient, ['drivers', 'item', { guid }], updater);
}

export function useDriversCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateDrivers() {
      void queryClient.invalidateQueries(['drivers']);
    }

    function invalidateDriverActivationDetails(activationCode: string) {
      void queryClient.invalidateQueries([
        'drivers',
        'activation-details',
        { activationCode },
      ]);
    }

    return { invalidateDrivers, invalidateDriverActivationDetails };
  }, [queryClient]);
}

export type DriverAcceptInvitationResponse =
  APIResponse<DriverInvitationDTO> & {
    url: string | null;
  };

export function useDriversAPI() {
  const queryClient = useQueryClient();
  const { invalidateDrivers, invalidateDriverActivationDetails } =
    useDriversCache();
  const invalidateSubscriptionDetails = useInvalidateSubscriptionQueries();

  return useMemo(
    () => ({
      createDriver: (
        json: DriverEditLegacyDTO,
      ): Promise<APIResponse<DriverDTO>> =>
        requestCarrierAPI('POST /internal/web/drivers/', { json }).then(
          (response) => {
            invalidateDrivers();
            return { ...response, data: driverSchema.cast(response.data) };
          },
        ),

      patchDriver: (
        guid: string,
        updates: Partial<DriverDTO>,
        original?: DriverDTO,
      ) =>
        requestCarrierAPI(['PATCH /internal/web/drivers/{guid}/', { guid }], {
          json: shallowDiff(updates, original),
        }).then((response) => {
          invalidateDrivers();
          return { ...response, data: driverSchema.cast(response.data) };
        }),

      setPassword: (activationCode: string, password: string) =>
        requestCarrierAPIPublic<
          APIResponse<DriverActivationDetailsDTO> & { url: string | null }
          // Backend sends url outside of data when response code is 302
        >(
          [
            'POST /internal/web/carrier/drivers/{activationCode}/activate/',
            { activationCode },
          ],
          { json: { password } },
        ).then((response) => {
          invalidateDriverActivationDetails(activationCode);
          return {
            ...response,
            data: driverActivationDetailsSchema.cast(response.data),
          };
        }),

      deactivateDriver: ({ guid, reason }: DeactivationDriverDTO) =>
        requestCarrierAPI(
          ['POST /internal/web/drivers/{guid}/deactivate/', { guid }],
          { json: { reason } },
        ).then((response) => {
          void queryClient.invalidateQueries(['drivers']);

          setDriverQueryData(queryClient, guid, (driver) => ({
            ...driver,
            is_suspended: false,
          }));

          return response;
        }),
      acceptInvite: (invitationCode: string, json: DriverActivateDTO) => {
        return requestCarrierAPIPublic<DriverAcceptInvitationResponse>(
          [
            'POST /internal/web/drivers/invites/{invitationCode}/',
            { invitationCode },
          ],
          {
            json,
          },
        );
      },

      inviteDriver: (json: InviteDriverDTO): Promise<DriverDTO> =>
        requestCarrierAPI('POST /internal/web/drivers/', { json }).then(
          (res) => {
            void queryClient.invalidateQueries(['drivers']);
            void queryClient.invalidateQueries(['settings', 'subscription']);
            invalidateSubscriptionDetails();

            return driverSchema.cast(res.data);
          },
        ),
      reinviteDriver: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/drivers/{guid}/invite/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries(['drivers']);

          return res;
        });
      },
      resendInviteDriver: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/drivers/{guid}/resend-invite/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries(['drivers']);

          return res;
        });
      },

      acceptJoinRequest: (guid: string, name?: string) => {
        return requestCarrierAPI(
          ['POST /internal/web/drivers/{guid}/accept/', { guid }],
          { json: { name } },
        ).then((res) => {
          void queryClient.invalidateQueries(['drivers']);

          return res;
        });
      },
      declineJoinRequest: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/drivers/{guid}/decline/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries(['drivers']);

          return res;
        });
      },

      updateDriverNotes: (guid: string, notes: string) => {
        return requestCarrierAPI(
          ['PUT /internal/web/drivers/{guid}/notes/', { guid }],
          {
            json: { notes },
          },
        ).then((data) => {
          void queryClient.invalidateQueries(['drivers']);

          return data;
        });
      },

      acceptExistingDriverJoinRequest: (activationCode: string) => {
        return requestCarrierAPIPublic<DriverAcceptInvitationResponse>([
          'POST /internal/web/drivers/invites/{activationCode}/accept/',
          { activationCode },
        ]);
      },

      declineExistingDriverJoinRequest: (activationCode: string) => {
        return requestCarrierAPIPublic([
          'POST /internal/web/drivers/invites/{activationCode}/decline/',
          { activationCode },
        ]);
      },
    }),
    [
      invalidateDrivers,
      invalidateDriverActivationDetails,
      queryClient,
      invalidateSubscriptionDetails,
    ],
  );
}

export function useDriver(
  guid: string | null | undefined,
  options?: UseAPIQueryOptions<DriverDTO>,
): APIQueryResult<DriverDTO> {
  const queryClient = useQueryClient();
  return useAPIQuery(
    ['drivers', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/drivers/{guid}/', { guid }]),
    {
      ...options,
      enabled: !!guid,
      schema: driverSchema,
      initialData() {
        return findAPIListQueryItem(
          ['drivers', 'list'],
          queryClient,
          (driver: DriverDTO) => driver.guid === guid,
        );
      },
    },
  );
}

export interface DriverListParams {
  q?: string;
  page_size?: number;
  exclude_suspended?: boolean;
  exclude_join_requests?: boolean;
}

export function useDriverList(
  params?: DriverListParams,
  options?: APIListQueryOptions<DriverDTO>,
): APIListQueryResult<DriverDTO> {
  return useAPIListQuery(
    ['drivers', 'list', { params }],
    (page) =>
      requestCarrierAPI([
        'GET /internal/web/drivers/{?page,params*}',
        { page, params },
      ]),
    { ...options },
  );
}

export function useDeleteDriver({
  onMutate,
  onSuccess,
  ...options
}: APIMutationOptions<
  string,
  unknown,
  string | undefined
> = {}): APIMutationResult<string, unknown, string | undefined> {
  const queryClient = useQueryClient();

  return useAPIMutation<string, unknown, string | undefined>(
    (guid) =>
      requestCarrierAPI(['DELETE /internal/web/drivers/{guid}/', { guid }]),
    {
      ...options,
      onMutate(guid) {
        let prevGUID: string | undefined = undefined;
        const driver = findAPIListQueryItem<DriverDTO>(
          ['drivers', 'list'],
          queryClient,
          (item) => {
            if (item.guid === guid) return true;
            prevGUID = item.guid;
            return false;
          },
        );

        return driver ? prevGUID : undefined;
      },
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(['drivers']);

        return onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useReactivateDriver(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/drivers/{guid}/reactivate/',
        { guid },
      ]).then((response) => {
        void queryClient.invalidateQueries(['drivers']);

        setDriverQueryData(queryClient, guid, (driver) => ({
          ...driver,
          is_suspended: false,
        }));

        return response;
      }),
    options,
  );
}

export function useResendActivationLink(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/drivers/{guid}/resend_link/',
        { guid },
      ]),
    options,
  );
}

export function useInviteResetPasswordLink(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/drivers/{guid}/invite-reset-password/',
        { guid },
      ]),
    options,
  );
}

export function useDriverActivationDetails(
  activationCode: string,
  options?: UseAPIQueryOptions<DriverActivationDetailsDTO>,
): APIQueryResult<DriverActivationDetailsDTO> {
  return useAPIQuery(
    ['drivers', 'activation-details', { activationCode }],
    () =>
      requestCarrierAPIPublic([
        'GET /internal/web/carrier/drivers/{activationCode}/activate/',
        { activationCode },
      ]),
    {
      ...options,
      schema: driverActivationDetailsSchema,
    },
  );
}

export function useDriverInvitationDetails(
  invitationCode: string,
  options?: UseAPIQueryOptions<DriverInvitationDTO>,
): APIQueryResult<DriverInvitationDTO> {
  return useAPIQuery(
    ['drivers', 'invitation-details', { invitationCode }],
    () =>
      requestCarrierAPIPublic([
        'GET /internal/web/drivers/invites/{invitationCode}/',
        { invitationCode },
      ]),
    {
      ...options,
      schema: driverInvitationSchema,
    },
  );
}

export function useDriversUndelieveredLoadsCount(
  guid?: string,
  options?: UseAPIQueryOptions<{ undelivered: number }>,
): APIQueryResult<{ undelivered: number }> {
  return useAPIQuery(
    ['undelivered-loads', 'count', { guid }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/drivers/{guid}/load-count/',
        { guid },
      ]),
    { ...options },
  );
}

export function useCancelDriverInvite(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/drivers/{guid}/cancel/',
        { guid },
      ]).then((res) => {
        void queryClient.invalidateQueries(['drivers']);
        return res;
      }),
    options,
  );
}

export function useReinviteDriver(options?: APIMutationOptions<string>) {
  const { reinviteDriver } = useDriversAPI();

  return useAPIMutation((guid) => reinviteDriver(guid), options);
}

export function useResendInviteDriver(options?: APIMutationOptions<string>) {
  const { resendInviteDriver } = useDriversAPI();

  return useAPIMutation((guid) => resendInviteDriver(guid), options);
}

export function useAcceptDriverRequest(
  options?: APIMutationOptions<[guid: string, name?: string]>,
) {
  const { acceptJoinRequest } = useDriversAPI();

  return useAPIMutation(
    ([guid, name]) => acceptJoinRequest(guid, name),
    options,
  );
}

export function useDeclineDriverRequest(options?: APIMutationOptions<string>) {
  const { declineJoinRequest } = useDriversAPI();

  return useAPIMutation((guid) => declineJoinRequest(guid), options);
}

export function useAcceptExistingDriverRequest(
  options?: APIMutationOptions<string>,
) {
  const { acceptExistingDriverJoinRequest } = useDriversAPI();

  return useAPIMutation(
    (activationCode) => acceptExistingDriverJoinRequest(activationCode),
    options,
  );
}

export function useDeclineExistingDriverRequest(
  options?: APIMutationOptions<string>,
) {
  const { declineExistingDriverJoinRequest } = useDriversAPI();

  return useAPIMutation(
    (activationCode) => declineExistingDriverJoinRequest(activationCode),
    options,
  );
}

export function invalidateQuery(
  queryClient: QueryClient,
  invitationCode: string,
  status: 'declined' | 'activated' | 'expired' = 'activated',
) {
  void queryClient.invalidateQueries(['settings', 'subscription']);
  void queryClient.invalidateQueries(['settings', 'subscription-details']);
  const queryKey = ['drivers', 'invitation-details', { invitationCode }];
  const invitaionDetails =
    queryClient.getQueryData<DriverInvitationDTO>(queryKey);

  void queryClient.setQueryData(queryKey, {
    ...invitaionDetails,
    status,
  });

  void queryClient.invalidateQueries(['drivers']);
}
