import {
  ZENDESK_EXPIRES_AT_KEY,
  ZENDESK_TOKEN_KEY,
} from 'shared/modules/zendesk/ZendeskData';
import { logWarning } from '../ErrorTracker';
import { LocalStore } from '../Store';
import { ZendeskApi } from './ZendeskInitialize';
import { ZendeskConversationField } from './ZendeskTypes';

export function showZendeskMessenger() {
  ZendeskApi('messenger', 'show');
}

export function hideZendeskMessenger() {
  ZendeskApi('messenger', 'hide');
}

export function openZendeskMessenger() {
  ZendeskApi('messenger', 'open');
}

export function closeZendeskMessenger() {
  ZendeskApi('messenger', 'close');
}

export function setZendeskLocale(locale: string) {
  ZendeskApi('messenger:set', 'locale', locale);
}

export function setZendeskZIndex(zIndex: number) {
  ZendeskApi('messenger:set', 'zIndex', zIndex);
}

export function setZendeskCookies(enabled: boolean) {
  ZendeskApi('messenger:set', 'cookies', enabled);
}

export function setZendeskConversationFields(
  fields: ZendeskConversationField[],
) {
  ZendeskApi('messenger:set', 'conversationFields', fields);
}

export function setZendeskConversationTags(tags: string[]) {
  ZendeskApi('messenger:set', 'conversationTags', tags);
}

export function loginZendeskUser(jwtToken: string | null) {
  if (jwtToken) {
    ZendeskApi(
      'messenger',
      'loginUser',
      (callback: (token: string) => void) => {
        callback(jwtToken);
      },
      (error: string) => {
        if (error) {
          const errorMessage = `ZendeskAuth: ${error}`;
          logWarning(errorMessage, { error });
          LocalStore.delete(ZENDESK_TOKEN_KEY);
        }
      },
    );
  }
}

const ZENDESK_SUID = 'ZD-suid';
const ZENDESK_LAST_PLAYED_TIME = 'ZD-lastPlayedTimestamp';
const ZENDESK_BUID = 'ZD-buid';
const ZENDESK_UID = '671a5f85c4f65dba4d4e4863';

export function clearZendeskUser() {
  LocalStore.delete(ZENDESK_SUID);
  LocalStore.delete(ZENDESK_LAST_PLAYED_TIME);
  LocalStore.delete(ZENDESK_BUID);
  LocalStore.delete(`${ZENDESK_UID}.appUserId`);
  LocalStore.delete(`${ZENDESK_UID}.clientId`);
  LocalStore.delete(`${ZENDESK_UID}.conversationStartedAt`);
  LocalStore.delete(ZENDESK_TOKEN_KEY);
  LocalStore.delete(ZENDESK_EXPIRES_AT_KEY);
}

export function logoutZendeskUser() {
  ZendeskApi('messenger', 'logoutUser');
}
