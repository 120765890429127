export type OfferSource = (typeof OFFER_SOURCES)[number];
export const OFFER_SOURCES = [
  'DIRECT',
  'BOOKING',
  'LOAD_REQUEST',
  'AUTO_DISPATCH',
  'COUNTER_OFFER',
  'INSTANT_DISPATCH',
  'AUTO_PRICE_NEGOTIATION',
  'INSTANT_MOVE',
] as const;
