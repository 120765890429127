import { CircularProgress, ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Inline, VisibilityObserver } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { LoadAssign } from '../../loads/core/LoadAssign';
import { LoadDTO } from '../../loads/data/LoadDTO';
import { useAssignedDrivers } from '../data/DashboardAPI';
import { DashboardDriverListItem } from './DashboardDriverListItem';
import { DashboardDriversLoad } from './DashboardDriversLoad';

export function AssignedDrivers() {
  const [assignDriverLoad, setAssignDriverLoad] = useState<LoadDTO | undefined>(
    undefined,
  );
  const assignedDrivers = useAssignedDrivers();

  if (assignedDrivers.isLoading) {
    return (
      <Inline horizontalAlign="center">
        <CircularProgress />
      </Inline>
    );
  }

  const closeReassignDriverDrawer = () => {
    setAssignDriverLoad(undefined);
  };

  return (
    <>
      <LoadAssign
        onClose={closeReassignDriverDrawer}
        load={assignDriverLoad}
        onSubmitSuccess={() => {
          closeReassignDriverDrawer();
          void assignedDrivers.refetch();
        }}
      />

      {assignedDrivers.data?.pages.flatMap((page) =>
        page.data.map((driver) => (
          <DashboardDriverListItem
            key={driver.guid}
            driver={driver}
            loads={driver.loads?.map((load) => (
              <DashboardDriversLoad
                key={load.guid}
                load={load}
                actions={
                  driver.connection_status === 'deactivated' && (
                    <Column width="content">
                      <Button
                        variant="neutral"
                        onClick={() => {
                          setAssignDriverLoad({
                            ...load,
                            driver,
                          } as unknown as LoadDTO);
                        }}
                      >
                        Reassign
                      </Button>
                    </Column>
                  )
                }
              />
            ))}
          />
        )),
      )}

      {assignedDrivers.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (
              !assignedDrivers.isFetchingNextPage &&
              visibility === 'visible'
            ) {
              void assignedDrivers.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </>
  );
}
