import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ClockAlertIcon = createSvgIcon(
  'ClockAlertIcon',
  <path
    d="M14.8 10V17.2L21.04 20.92L22 19.48L16.6 16.24V10H14.8ZM25.6 16V23.2H28V16H25.6ZM25.6 25.6V28H28V25.6H25.6ZM23.2 25.6C21.16 27.16 18.76 28 16 28C9.4 28 4 22.6 4 16C4 9.4 9.4 4 16 4C21.76 4 26.68 8.08 27.76 13.6H25.24C24.16 9.52 20.44 6.4 16 6.4C10.72 6.4 6.4 10.72 6.4 16C6.4 21.28 10.72 25.6 16 25.6C18.88 25.6 21.4 24.28 23.2 22.36V25.6Z"
    fill={Color.Dark100}
  />,
  { viewBox: '0 0 32 32', style: { height: '32px', width: '32px' } },
);
