import {
  Card,
  CardContent as MuiCardContent,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
  VisibilityObserver,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { AccountSearchIcon } from 'shared/icons';
import { ArrowForwardIcon } from 'shared/icons/ArrowForwardIcon';
import { LinkAnchor } from 'shared/routing/Links';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { LoadAssign } from '../../loads/core/LoadAssign';
import { loadSchema } from '../../loads/data/LoadDTO';
import {
  useDelayedLoadsCount,
  useUnassignedLoads,
  useUnassignedLoadsCache,
} from '../data/DashboardAPI';
import { useDashboardContext } from '../data/DashboardContext';
import { UnassignedLoadDTO } from '../data/DashboardDTO';
import { StickyTopBox } from './StickyTopContainer';

const CardContent = styled(MuiCardContent)`
  padding: 0;
`;

function LoadingSkeleton() {
  return (
    <>
      <Stack space="small">
        <Box paddingLeft="medium" paddingRight="medium" paddingTop="small">
          <Stack space="xxsmall">
            <Skeleton width="177px" height="24px" />
            <Skeleton width="60px" height="28px" />
          </Stack>
        </Box>
        <Divider />
      </Stack>
      <Box maxHeight="348px" overflow="auto">
        <List>
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <ListItem key={index}>
                <ListItemText>
                  <Stack space="xxsmall">
                    <Skeleton width="93px" height="20px" />
                    <Skeleton width="217px" height="20px" />
                    <Skeleton width="93px" height="20px" />
                  </Stack>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Box
                    width="78px"
                    height="32px"
                    borderRadius="medium"
                    borderColor="Silver500"
                    borderWidth="medium"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
}

export function UnassignedLoads() {
  const [assignDriverLoad, setAssignDriverLoad] = useState<
    UnassignedLoadDTO | undefined
  >(undefined);
  const { data: delayedLoadsCount, refetch: refetchDelayedLoadsCount } =
    useDelayedLoadsCount();
  const {
    data: unassignedLoads,
    isInitialLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useUnassignedLoads();
  const castedAssignDriverLoad = useMemo(() => {
    return assignDriverLoad ? loadSchema.cast(assignDriverLoad) : undefined;
  }, [assignDriverLoad]);
  const { invalidateUnassignedLoads, updateUnassignedLoads } =
    useUnassignedLoadsCache();
  const closeAssignDriverDrawer = () => {
    setAssignDriverLoad(undefined);
  };
  const { params } = useDashboardContext();
  const loads = useMemo(() => {
    return unassignedLoads?.pages.flatMap((page) => page.data) ?? [];
  }, [unassignedLoads]);

  if (!loads.length || isInitialLoading) {
    return (
      <Card>
        <CardContent>
          {isInitialLoading ? (
            <LoadingSkeleton />
          ) : (
            <Box
              height="456px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Stack space="medium" align="center">
                <AccountSearchIcon fontSize="large" color="action" />
                <Stack align="center">
                  <TextBox variant="heading-3">No Unassigned Loads</TextBox>
                  <Box maxWidth="200px">
                    <TextBox color="secondary" align="center">
                      Great! You don&apos;t have any unassigned loads.
                    </TextBox>
                  </Box>
                </Stack>
                <AnchorButton href="/loadboard" variant="neutral">
                  Find Loads on Super Loadboard
                </AnchorButton>
              </Stack>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <LoadAssign
        onClose={closeAssignDriverDrawer}
        load={castedAssignDriverLoad}
        onSubmitSuccess={() => {
          closeAssignDriverDrawer();
          invalidateUnassignedLoads();
          updateUnassignedLoads((data) => {
            if (!data) return data;
            return {
              ...data,
              pages: data.pages.map((page) => ({
                ...page,
                data: page.data.filter(
                  (load) => load.guid !== assignDriverLoad?.guid,
                ),
              })),
            };
          });
          void refetchDelayedLoadsCount();
        }}
      />

      <Card>
        <CardContent>
          <Stack space="small">
            <Box paddingLeft="medium" paddingRight="medium" paddingTop="small">
              <Stack space="xxsmall">
                <Columns space="small" align="center">
                  <Column width="fluid">
                    <Inline verticalAlign="center">
                      <Typography variant="h3">Unassigned Loads</Typography>
                      <Tooltip
                        placement="right"
                        interactive={true}
                        title="Loads which have not been assigned to any driver."
                      >
                        <Info color="action" fontSize="small" />
                      </Tooltip>
                    </Inline>
                  </Column>
                  <Column width="content">
                    <LinkAnchor
                      aria-label="View All Unassigned Loads"
                      to={parseURITemplate(
                        '/dashboard/loads?stage={stage}&driver_status={driver_status}&delayed_load_stage={delayed_load_stage}',
                        {
                          driver_status: params.driver_status,
                          delayed_load_stage: params.delayed_load_stage,
                          stage: 'aging',
                        },
                      )}
                      underline="none"
                      color="primary"
                    >
                      <Inline verticalAlign="center" space="xxsmall">
                        View All
                        <ChevronRight fontSize="small" />
                      </Inline>
                    </LinkAnchor>
                  </Column>
                </Columns>
                <Inline space="xsmall" verticalAlign="center">
                  <AccountSearchIcon fontSize="large" color="action" />
                  <Typography variant="h1">
                    {delayedLoadsCount?.unassigned}
                  </Typography>
                </Inline>
              </Stack>
            </Box>
          </Stack>
          <StickyTopBox minHeight="348px" maxHeight="348px" marginTop="small">
            <List>
              {loads.map((load) => {
                const origin = joinStrings(
                  ', ',
                  load.delivery_city,
                  load.delivery_state,
                );
                const destination = joinStrings(
                  ', ',
                  load.pickup_city,
                  load.pickup_state,
                );

                const vehiclesCount = load.vehicles.length;

                return (
                  <ListItem key={load.guid}>
                    <ListItemText>
                      <Box maxWidth="288px">
                        <Stack space="xxsmall">
                          <LinkAnchor
                            to={`/loads/${load.guid}`}
                            variant="body1"
                          >
                            {load.number || "Load doesn't have ID"}
                          </LinkAnchor>

                          <Inline verticalAlign="center">
                            <OverflowText
                              color={origin ? 'textPrimary' : 'textSecondary'}
                            >
                              {origin || 'N/A'}
                            </OverflowText>
                            <ArrowForwardIcon />
                            <OverflowText
                              color={
                                destination ? 'textPrimary' : 'textSecondary'
                              }
                            >
                              {destination || 'N/A'}
                            </OverflowText>
                          </Inline>
                          {vehiclesCount ? (
                            <TextBox color="secondary">
                              {vehiclesCount}{' '}
                              {formatPlural(
                                vehiclesCount,
                                'Vehicle',
                                'Vehicles',
                              )}
                            </TextBox>
                          ) : (
                            <TextBox color="secondary">No Vehicles</TextBox>
                          )}
                        </Stack>
                      </Box>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Button
                        variant="neutral"
                        onClick={() => {
                          setAssignDriverLoad(load);
                        }}
                      >
                        Assign
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}

              {hasNextPage && (
                <VisibilityObserver
                  onChange={(visibility) => {
                    if (!isFetchingNextPage && visibility === 'visible') {
                      void fetchNextPage();
                    }
                  }}
                  render={({ ref }) => (
                    <ListItem ref={ref}>
                      <ListItemText
                        primary={<Skeleton />}
                        secondary={<Skeleton />}
                      />
                    </ListItem>
                  )}
                />
              )}
            </List>
          </StickyTopBox>
        </CardContent>
      </Card>
    </>
  );
}
