import { Avatar } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import { Color, ColorDynamic } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { PersonDeactivatedIcon } from 'shared/icons/PersonDeactivatedIcon';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { AvailableDriverDTO } from '../data/DashboardDTO';

const StyledBox = styled(Box)`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function DriverAvatar({ driver }: { driver: AvailableDriverDTO }) {
  if (
    driver.connection_status === 'pending' &&
    driver.connection_type === 'request'
  ) {
    return (
      <StyledBox backgroundColor="Blue300" padding="xsmall">
        <PersonAddAlt htmlColor={Color.White} fontSize="small" />
      </StyledBox>
    );
  }

  if (
    driver.connection_status === 'pending' &&
    driver.connection_type === 'invite'
  ) {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Yellow300"
        backgroundColor="Yellow50"
        padding="xsmall"
      >
        <QueryBuilder fontSize="small" htmlColor={ColorDynamic.Yellow500} />
      </StyledBox>
    );
  }

  if (driver.connection_status === 'deactivated') {
    return (
      <StyledBox
        borderWidth="small"
        borderColor="Silver400"
        backgroundColor="Silver200"
        padding="xsmall"
      >
        <PersonDeactivatedIcon />
      </StyledBox>
    );
  }

  return <Avatar>{getInitials(driver.name || driver.email)}</Avatar>;
}
