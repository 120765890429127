import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { SuperPayRequestShipperBanner } from 'shared/modules/superpay/SuperPayRequestShipperBanner';
import { useBypassNavigationPrompt } from 'shared/routing/NavigationBlock';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { trackSuperPayEvent } from '../../data/SuperPayAnalytics';
import { useSuperPaySettings } from '../../data/SuperPayAPI';
import { ProfilePageLayout } from '../../ProfilePageLayout';
import { logPaymentInfo } from '../PaymentLogger';
import { SUPERPAY_PROFILE_ROUTES } from '../SuperPayRoute';
import { BankDetails } from './BankDetails';
import { BankVerificationDrawer } from './BankVerificationDrawer';
import { MicroDepositInitiatedDialog } from './MicroDepositInitiatedDialog';
import { PromoTerms } from './PromoTerms';
import { useVerificationAvailable } from './useVerificationAvailable';

export function AccountDetailsPage() {
  const [query, setQuery] = useQuery();
  const navigate = useBypassNavigationPrompt();

  const { data: settings } = useCarrierSettings();
  const hasAccountingFeature = settings?.user.has_accounting_feature;

  const { data: superPaySettings, refetch: fetchSuperPaySettings } =
    useSuperPaySettings();
  const bankAccount = superPaySettings?.bank_accounts?.[0];
  const verificationStatus = bankAccount?.verification_status;

  useVerificationAvailable(SUPERPAY_PROFILE_ROUTES.accountDetails);

  const onDrawerClose = () => {
    navigate(
      `${SUPERPAY_PROFILE_ROUTES.accountDetails}?utm_medium=Verify Bank Account Drawer`,
    );
  };

  const onDialogClose = () => {
    setQuery({ open_microdeposit_dialog: undefined });
  };

  useEffect(() => {
    trackSuperPayEvent({ name: 'Carrier Opened SuperPay Settings Page' });
    logPaymentInfo(
      'Carrier Opened SuperPay Settings Page',
      'BankAccountDetailsPage',
      {
        status: verificationStatus,
      },
    );
  }, [verificationStatus]);

  return (
    <>
      <MicroDepositInitiatedDialog
        open={!!query.open_microdeposit_dialog}
        onClose={onDialogClose}
      />
      <ProfilePageLayout
        title="SuperPay"
        isLoading={!superPaySettings}
        headerEndActions={false}
        size="medium"
      >
        <Stack space="large">
          <Box margin="auto" marginTop="medium" maxWidth="496px">
            <BankDetails
              bankAccount={bankAccount}
              hasAccountFeature={hasAccountingFeature}
              path="/profile/superpay/onboarding/account-details"
            />
          </Box>
          {settings?.superpay.is_invite_available && (
            <Box margin="auto" maxWidth="496px">
              <SuperPayRequestShipperBanner />
            </Box>
          )}
          <Box width="100%">
            <PromoTerms />
          </Box>
        </Stack>
        <BankVerificationDrawer
          bankAccount={bankAccount}
          onClose={onDrawerClose}
          onComplete={() => {
            onDrawerClose();
            void fetchSuperPaySettings();
          }}
        />
      </ProfilePageLayout>
    </>
  );
}
