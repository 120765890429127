import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useAPIQuery } from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

export const ZENDESK_TOKEN_KEY = 'zendesk_token';
export const ZENDESK_EXPIRES_AT_KEY = 'zendesk_expires_at';

export function useZendeskUser() {
  const shouldFetchZendesk = useMatch('settings/subscription/*');
  const zendeskToken = useLocalStore(ZENDESK_TOKEN_KEY);
  const zendeskExpiresAt = useLocalStore(ZENDESK_EXPIRES_AT_KEY, '');

  const isEnabled = useMemo(() => {
    if (shouldFetchZendesk) return false;
    if (!zendeskToken) return true;
    return new Date(zendeskExpiresAt) < new Date();
  }, [zendeskToken, zendeskExpiresAt, shouldFetchZendesk]);

  return useAPIQuery(
    ['zendesk', 'loginUser', { zendeskToken }],
    () => requestCarrierAPI('GET /internal/web/zendesk/jwt/'),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: { token: string; expires_at: string }) => {
        LocalStore.set(ZENDESK_TOKEN_KEY, data.token);
        LocalStore.set(ZENDESK_EXPIRES_AT_KEY, data.expires_at);
      },
      enabled: isEnabled,
    },
  );
}
