import { trackEvent } from 'shared/helpers/Analytics';

export type DashboardAnalyticsEvent =
  | { name: 'Carrier Opened Dashboard' }
  | { name: 'Carrier Clicked Delayed Loads Tab'; stage: string }
  | { name: 'Carrier Clicked Drivers Tab'; stage: string }
  | { name: 'Carrier Clicked Ratings Received Tab' }
  | { name: 'Carrier Clicked Ratings To Give Tab' }
  | { name: 'Carrier Clicked View All Ratings'; stage: string }
  | { name: 'Carrier Clicked Add Response' }
  | { name: 'Carrier Clicked Rate Shipper'; shipper_guid: string }
  | { name: 'Carrier Resolved All Attention Required Notifications' };

export function trackDashboardEvent(event: DashboardAnalyticsEvent) {
  const { name, ...props } = event;

  trackEvent(name, props as Record<string, unknown>);
}
