import { Typography } from '@material-ui/core';
import { useDeepEqualValue } from '@superdispatch/hooks';
import {
  Color,
  ColorDark,
  ColorDynamic,
  Column,
  Columns,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { LoadOfferSidebarIcon } from 'shared/icons/LoadOfferSidebarIcon';
import { SidebarLogoIcon } from 'shared/icons/SidebarLogoIcon';
import { AppCarrierMenuLegacy } from 'shared/layout/sidebar/AppCarrierMenuLegacy';
import {
  AppSidebarMenuLegacy,
  SidebarMenuLinkLegacy,
  StyledBadge,
  StyledLinkLegacyIcon,
} from 'shared/layout/sidebar/AppSidebarMenuLegacy';
import { LoadOffersButton } from 'shared/modules/load-offers/LoadOffersButton';
import styled from 'styled-components';
import { useCarrierMenuLegacy } from './useCarrierMenuLgacy';
import { useSidebarItems } from './useSidebarItems';

const Aside = styled.aside<{ isTrialExpired: boolean }>`
  height: 100vh;
  max-width: 88px;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border-right: 1px solid ${ColorDynamic.Silver400};
  top: 0;
  left: 0;
  z-index: ${({ isTrialExpired }) => (isTrialExpired ? 1400 : 1)};
  position: sticky;

  color: ${ColorDynamic.Silver500};
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};

  @media print {
    display: none;
  }
`;

export const LogoContainer = styled.div(({ theme }) => {
  const mode = theme.palette.type;
  const color = mode === 'dark' ? ColorDark.White : Color.Dark500;
  return `
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  width: 100%;
  background-color: ${color};
  z-index: 2;
`;
});

const BoxContainer = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function LoadOffersLink() {
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const [isHovered, setIsHovered] = useState(false);

  return (
    <LoadOffersButton
      render={({ loadOffersCount, onClick }) => {
        return (
          <SidebarMenuLinkLegacy
            onClick={onClick}
            onMouseOver={() => {
              setIsHovered(true);
            }}
            onMouseOut={() => {
              setIsHovered(false);
            }}
          >
            {isMobile ? (
              <Columns align="center">
                <Column width="fluid">
                  <Columns align="center" space="small">
                    <Column width="content">
                      <StyledLinkLegacyIcon as={LoadOfferSidebarIcon} />
                    </Column>

                    <Column width="fluid">
                      <Typography variant="subtitle1">Offers</Typography>
                    </Column>
                  </Columns>
                </Column>
                <Column width="content">
                  {Boolean(loadOffersCount) && (
                    <BoxContainer backgroundColor="Red300">
                      <Typography align="center" variant="caption">
                        {loadOffersCount}
                      </Typography>
                    </BoxContainer>
                  )}
                </Column>
              </Columns>
            ) : (
              <>
                <StyledBadge
                  badgeContent={loadOffersCount}
                  color="error"
                  overlap="rectangular"
                  $highlighted={isHovered}
                >
                  <StyledLinkLegacyIcon as={LoadOfferSidebarIcon} />
                </StyledBadge>
                Offers
              </>
            )}
          </SidebarMenuLinkLegacy>
        );
      }}
    />
  );
}

export interface AppLayoutSidebarLegacyProps {
  localPaths?: {
    dashboard?: string;
    loads?: string;
    contacts?: string;
    drivers?: string;
    trips?: string;
    tracking?: string;
    loadboard?: string;
    ratings?: string;
    dispatcherProfile?: string;
    carrierProfile?: string;
    reports?: string;
    settings?: string;
    subscriptionDetails?: string;
    logout?: string;
    dispatcherManagement?: string;
  };
  hasMenuBadge: boolean;
}

export function AppLayoutSidebarLegacy({
  localPaths: localPathsProp = {},
  hasMenuBadge,
}: AppLayoutSidebarLegacyProps) {
  const localPaths = useDeepEqualValue(localPathsProp);
  const { isOpenTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { isOpenSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();
  const { ratingsLink, accountItems, carrierItems, dispatcherItems } =
    useCarrierMenuLegacy({ localPaths });
  const { offersLinks, primaryLinks, secondaryLinks } = useSidebarItems({
    localPaths,
  });

  const isTrialExpired =
    !!isOpenTrialExpiredDialog || !!isOpenSubscriptionCancelledDialog;

  return (
    <Aside isTrialExpired={isTrialExpired}>
      <LogoContainer>
        <a href="/tms/loads" aria-label="Dashboard">
          <SidebarLogoIcon fontSize="large" htmlColor={Color.White} />
        </a>
      </LogoContainer>

      <AppSidebarMenuLegacy
        offersLinks={offersLinks}
        primaryLinks={primaryLinks}
        secondaryLinks={secondaryLinks}
      />

      <AppCarrierMenuLegacy
        ratingsLink={ratingsLink}
        carrierItems={carrierItems}
        dispatcherItems={dispatcherItems}
        accountItems={accountItems}
        hasMenuBadge={hasMenuBadge}
      />
    </Aside>
  );
}
