import { ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DriverStatus } from 'shared/modules/driver/DriverStatus';
import { LinkAnchor } from 'shared/routing/Links';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { AvailableDriverDTO } from '../data/DashboardDTO';
import { DriverAvatar } from './DriverAvatar';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 24px !important',
  },
}));

interface DashboardDriverListItemProps {
  driver: AvailableDriverDTO;
  loads?: React.ReactNode;
}

export function DashboardDriverListItem({
  driver,
  loads,
}: DashboardDriverListItemProps) {
  const styles = useStyles();

  return (
    <ListItem
      // eslint-disable-next-line react/forbid-component-props
      className={styles.root}
    >
      <Columns>
        <Column width="fluid">
          <Columns align="top" space="xsmall">
            <Column width="content">
              <DriverAvatar driver={driver} />
            </Column>
            <Column width="fluid">
              <Stack space="xsmall">
                <Stack space="xxsmall">
                  <Inline>
                    <Typography variant="body1" noWrap={true}>
                      <LinkAnchor to={`/drivers/${driver.guid}`}>
                        {driver.name || driver.email}
                      </LinkAnchor>
                    </Typography>
                    {driver.connection_status === 'deactivated' && (
                      <DriverStatus
                        descriptionID={driver.guid}
                        isSuspended={false}
                        connectionStatus={driver.connection_status}
                        connectionType={driver.connection_type}
                      />
                    )}
                  </Inline>
                  <Typography variant="body1" color="textSecondary">
                    <InlineBulletItems>
                      {driver.phone}
                      {driver.trailer_capacity
                        ? `${driver.trailer_capacity} ${formatPlural(
                            driver.trailer_capacity,
                            'vehicle trailer',
                            'vehicles trailer',
                          )}`
                        : ''}
                    </InlineBulletItems>
                  </Typography>
                </Stack>

                {loads}
              </Stack>
            </Column>
          </Columns>
        </Column>

        <Column width="content">
          {driver.connection_status !== 'deactivated' && (
            <DriverStatus
              descriptionID={driver.guid}
              isSuspended={false}
              connectionStatus={driver.connection_status}
              connectionType={driver.connection_type}
            />
          )}
        </Column>
      </Columns>
    </ListItem>
  );
}
