import { snakeCase, startCase } from 'lodash-es';
import { MouseEvent, useEffect } from 'react';
import { getAuthRole, getCarrierGuid } from 'shared/auth/AuthToken';
import { logError } from 'shared/helpers/ErrorTracker';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { parseSearchQuery } from 'shared/utils/URLUtils';

function tryRun(fn: () => void, fallback?: () => void): void {
  if (typeof analytics != 'undefined') {
    try {
      fn();
      return;
    } catch (error: unknown) {
      logError(error, 'Analytics');
    }
  }

  if (fallback) {
    try {
      fallback();
    } catch (error: unknown) {
      logError(error, 'Analytics');
    }
  }
}

export function onAnalyticsReady(fn: () => void): void {
  tryRun(() => {
    analytics.ready(() => {
      fn();
    });
  });
}

export function identifyAnalytics(user?: {
  guid: string;
  hash?: string;
  accountType?: 'owner_operator' | 'owner_operator_pro' | 'fleet' | null;
  experiment_group?: number[] | null;
  sd_importer_installed?: boolean | null;
  company?: {
    id?: string; //id is required for intercom
    guid?: string;
    name?: string;
    email?: string;
    state?: string;
    usdot?: string;
    is_super?: boolean;
    superpay_onboarding_status?: string;
    is_fmcsa_phone_verified?: boolean;
  };
}): void {
  onAnalyticsReady(() => {
    const authRole = getAuthRole();

    if (user) {
      const companyType =
        user.accountType == null
          ? undefined
          : user.accountType === 'fleet'
          ? 'Fleet'
          : 'Owner Operator';

      const { company } = user;

      analytics.identify(
        user.guid,
        {
          company: {
            ...company,
            superpay_onboarding_status:
              company?.superpay_onboarding_status ?? null,
            type: companyType,
          },
          experiment_group: user.experiment_group,
          //intercom has a problem with parsing array,
          // that's why we are sending duplicate property as a string
          experiment_group_list: user.experiment_group?.toString(),
          sd_importer_installed: user.sd_importer_installed,
        },
        !user.hash || authRole === 'admin'
          ? { integrations: { Intercom: false } }
          : ({
              Intercom: {
                user_hash: user.hash,
                company: { type: companyType },
              },
            } as SegmentAnalytics.SegmentOpts),
      );
    } else {
      try {
        // Handle errors when `analytics.user` can be undefined.
        if (analytics.user().id()) {
          const previousAnonoymousId = analytics.user().anonymousId();
          // Reset storage and track new page-view event when user is authorized.
          analytics.reset();
          // To keep the previous anonymousId and do duplicate the same device.
          analytics.setAnonymousId(previousAnonoymousId);
        }
      } catch (error: unknown) {
        analytics.identify();
        logError(error, 'Analytics');
      }
    }

    analytics.page();
  });
}

const initialUTMTags = getUTMTags('initial_');

export function trackEvent(
  event: string,
  properties: Record<string, unknown> = {},
  callback?: () => void,
): void {
  const queryUtmTags = getUTMTags();
  const carrierGuid = getCarrierGuid();

  properties = {
    carrier_guid: carrierGuid,
    ...initialUTMTags,
    ...queryUtmTags,
    ...properties,
    utm_source:
      properties.utm_source || queryUtmTags.utm_source || chooseUTMSource(),
  };

  if (import.meta.env.MODE !== 'production') {
    logEvent(event, properties);
    lintEvent(event, properties);
  }

  tryRun(
    () => {
      analytics.track(event, properties, callback);
    },
    () => {
      callback?.();

      if (import.meta.env.MODE !== 'production') {
        // eslint-disable-next-line no-console
        console.debug('%s (%j)', event, properties);
      }
    },
  );
}

export function trackAnchorClick(
  event: string,
  properties: Record<string, unknown>,
  mouseEvent: MouseEvent<HTMLAnchorElement>,
) {
  if (
    // Capture only left clicks.
    mouseEvent.button !== 0 ||
    // Do not capture prevented clicks.
    mouseEvent.defaultPrevented
  ) {
    return;
  }

  const { href, target } = mouseEvent.currentTarget;

  const shouldWaitTrackComplete =
    // Skip links without href.
    !!href &&
    // Skip clicks with modifiers.
    !mouseEvent.altKey &&
    !mouseEvent.ctrlKey &&
    !mouseEvent.metaKey &&
    !mouseEvent.shiftKey &&
    // Wait only for links to current window.
    (!target || target === '_self');

  if (shouldWaitTrackComplete) {
    mouseEvent.preventDefault();
  }

  trackEvent(event, properties, () => {
    if (shouldWaitTrackComplete) {
      openExternalURL(href);
    }
  });
}

export function trackPage(
  name: string,
  properties: Record<string, unknown> = {},
) {
  tryRun(
    () => {
      analytics.page(name, properties);
    },
    () => {
      if (import.meta.env.MODE !== 'production') {
        // eslint-disable-next-line no-console
        console.debug('trackPage %j', name);
      }
    },
  );
}

export function usePageTrack(name: string) {
  useEffect(() => {
    trackPage(name, {
      carrier_guid: getCarrierGuid(),
    });
  }, [name]);
}

/**
 * @see https://www.notion.so/superdispatch/Event-and-Properties-Naming-Guidelines-15f21c5508074091869c6c0dc18e0564
 * */
function lintEvent(eventName: string, properties: Record<string, unknown>) {
  const prefix = eventName.slice(0, eventName.indexOf(':'));
  const body = eventName.slice(eventName.indexOf(':') + 1).trim();
  const errors = [];

  /**
   * Good: "CTMS: Clicked Driver"
   * Bad:  "Clicked Driver"
   * */
  if (!['SLB', 'CTMS'].includes(prefix)) {
    errors.push(
      `Expected event name prefix to be "SLB:" or "CTMS:" but got "${prefix}."`,
    );
  }

  /**
   * Event body should be in Proper Case.
   *
   * Good: "CTMS: Clicked Driver"
   * Bad:  "CTMS: clicked driver"
   * */
  if (startCase(body) !== body) {
    errors.push(`Expected event name to be in Proper Case but got "${body}".`);
  }

  /**
   * Checks weather event body has "a" or "an" articles.
   *
   * Good: "CTMS: Clicked Driver"
   * Bad:  "CTMS: Clicked A Driver"
   * */
  if (/\san?\s/i.exec(body)) {
    errors.push('Expected event body to not have articles (.e.g "a", "an").');
  }

  {
    /**
     * Event body should start with past tense verb.
     *
     * Good: "CTMS: Clicked Driver"
     * Bad:  "CTMS: Click Driver"
     * */
    const [verb] = body.split(/\s/);

    if (!verb?.endsWith('ed')) {
      errors.push(
        `Expect event body to start with past tense verb but got "${verb}".`,
      );
    }
  }

  /**
   * Good: { driver_guid: "sj248d" }
   * Bad:  { driverGuid: "sj248d" }
   * */
  {
    const errorKeys = Object.keys(properties).filter((k) => k !== snakeCase(k));

    if (errorKeys.length) {
      errors.push(
        `Expected event properties keys to be in snake_case but got ${errorKeys
          .map((k) => `"${k}"`)
          .join(', ')}`,
      );
    }
  }

  if (errors.length) {
    // eslint-disable-next-line no-console
    console.groupCollapsed(
      '%cAnalytics: Found some issues for event:\n "%s"',
      'color: green',
      eventName,
    );
    for (const error of errors) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    // eslint-disable-next-line no-console
    console.info('Properties: %O', properties);
    // eslint-disable-next-line no-console
    console.info(
      'Link to the docs https://www.notion.so/superdispatch/Event-and-Properties-Naming-Guidelines-15f21c5508074091869c6c0dc18e0564',
    );
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}

function logEvent(eventName: string, properties: Record<string, unknown>) {
  // eslint-disable-next-line no-console
  console.groupCollapsed('Analytics: "%s"', eventName);
  // eslint-disable-next-line no-console
  console.info('Properties: %O', properties);
  // eslint-disable-next-line no-console
  console.groupEnd();
}

export type UTMSource = 'Web CTMS' | 'Mobile CTMS' | 'Web SLB' | 'Mobile SLB';
export function chooseUTMSource(): UTMSource {
  if (import.meta.env.VITE_APP_NAME === 'ctms') {
    return 'Web CTMS';
  }

  if (import.meta.env.VITE_APP_NAME === 'ctmsw') {
    return 'Mobile CTMS';
  }

  if (import.meta.env.VITE_APP_NAME === 'slbd') {
    return 'Web SLB';
  }

  return 'Mobile SLB';
}

function getUTMTags(prefix = '') {
  const query = parseSearchQuery(window.location.search);
  const tags: Record<string, unknown> = {};

  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (key.startsWith('utm_') && value) {
      tags[prefix + key] = decodeURIComponent(value);
    }
  });

  return tags;
}

export function getInitialSource() {
  return initialUTMTags.initial_utm_source;
}

export function getUTMTag(name: string) {
  const tags = getUTMTags();

  if (name in tags) {
    return tags[name];
  }

  return null;
}
