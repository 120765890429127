import {
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Add, Check, Phone, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useCallback, useMemo, useState } from 'react';
import { useAuthToken } from 'shared/auth/AuthToken';
import { getPlatform } from 'shared/helpers/BrowserHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { TowTruckIcon } from 'shared/icons';
import { NotesIcon } from 'shared/icons/NotesIcon';
import { splitTextByLine } from 'shared/modules/driver/DriverUtils';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import {
  useSubscriptionBillableSeats,
  useSubscriptionDetails,
} from 'shared/modules/subscription/SubscriptionAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { getDriverLabel } from '../../../drivers/data/DriverPageUtils';
import { OffersPageLayout } from '../../core/OffersPageLayout';
import { PublicOfferInviteDriverForm } from '../core/PublicOfferInviteDriverForm';
import { trackPublicOfferEvent } from '../data/PublicOfferAnalytics';
import {
  PublicDriverAcceptDriverResponseDTO,
  PublicOfferAssignResponseDTO,
  PublicOfferDriverDTO,
  PublicOfferDTO,
  PublicOfferInviteResponseDTO,
} from '../data/PublicOfferDTO';
import {
  usePublicOfferAPI,
  usePublicOfferDriversList,
} from '../data/PublicOffersAPI';
import { PublicOfferPageFooter } from '../PublicOfferPageFooter';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';
import { PublicOfferStepper } from '../PublicOfferStepper';

const StyledNotesIcon = styled(NotesIcon)`
  &:hover {
    cursor: pointer;
    & path {
      fill: ${ColorDynamic.Blue500};
    }
  }
`;
interface AssignDriverItemProps {
  name: string;
  notes: string | null;
  phone: string;
  trailerCapacity: number | null | undefined;
  selected: boolean;
  onSelect: () => void;
}

function AssignDriverItem({
  name,
  phone,
  trailerCapacity,
  selected,
  onSelect,
  notes,
}: AssignDriverItemProps) {
  return (
    <ListItem button={true} onClick={onSelect} selected={selected}>
      <Stack space="xxsmall">
        <TextBox variant="heading-4">
          <Columns space="xxsmall" align="center">
            <Column width="content">{name}</Column>
          </Columns>
          {notes && (
            <Column width="content">
              <Tooltip interactive={true} title={splitTextByLine(notes)}>
                <StyledNotesIcon htmlColor={Color.Dark100} />
              </Tooltip>
            </Column>
          )}
        </TextBox>
        {phone && (
          <Inline verticalAlign="center">
            <Phone color="action" fontSize="small" />
            {phone}
          </Inline>
        )}
        <Inline verticalAlign="center">
          <DescriptionItem
            icon={<TowTruckIcon color="action" fontSize="small" />}
            label="Trailer capacity"
            fallback="Not Available"
          >
            {trailerCapacity}
          </DescriptionItem>
        </Inline>
      </Stack>
      {selected && (
        <ListItemSecondaryAction>
          <Check color="primary" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function AssignDriverItemLoading() {
  return (
    <ListItem>
      <Stack space="xxsmall">
        <TextBox variant="heading-4">
          <Skeleton width={200} />
        </TextBox>

        <Skeleton width={400} />

        <Skeleton width={400} />
      </Stack>
    </ListItem>
  );
}

interface Props {
  onClose: () => void;
  offer: PublicOfferDTO;
}

export function PublicOfferAssignDriverPage({ offer, onClose }: Props) {
  const { assignDriver } = usePublicOfferAPI();
  const [selectedDriverId, setSelectedDriverId] = useState<string | undefined>(
    offer.order.driver?.guid,
  );
  const [searchKey, setSearchKey] = useState('');
  const [modalState, setModalState] = useState<
    'invite_driver' | 'plan_update'
  >();
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const { data: settings } = useCarrierSettings();
  const token = useAuthToken();

  const debouncedSearchKey = useDebouncedValue(searchKey, 500);
  const driverList = usePublicOfferDriversList(token, {
    q: debouncedSearchKey,
    page_size: 20,
  });

  const { data: subscriptionDetails } = useSubscriptionDetails();
  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';
  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: isSubscriptionOnLitePlan
      ? subscriptionDetails?.upgrade_option?.plan?.measurement_type
      : subscriptionDetails?.subscription?.plan?.measurement_type,
  });
  const { isFetching: isNextPlanLoading, refetch: fetchNextPlan } =
    useNextPlanEstimate();

  const { addSnackbar } = useSnackbarStack();

  const canInviteDriver = useMemo(() => {
    if (subscriptionDetails?.subscription?.plan) {
      const purchasedSeatsCount = Number(
        subscriptionDetails?.subscription?.plan?.quantity,
      );
      const billableSeatsCount = Number(billableSeats?.billable_seats);
      return purchasedSeatsCount > billableSeatsCount;
    }
    return false;
  }, [subscriptionDetails, billableSeats]);

  const onAssignSubmitSuccess = useCallback(
    (
      driver:
        | PublicOfferDriverDTO
        | PublicOfferInviteResponseDTO
        | PublicDriverAcceptDriverResponseDTO,
      response: PublicOfferAssignResponseDTO,
    ) => {
      setModalState(undefined);
      void driverList.refetch();
      onClose();
      trackPublicOfferEvent({ name: 'CTMS: Assigned Load Offer' });

      if (
        getPlatform() === 'mobile' &&
        driver.is_myself &&
        offer.is_first_offer &&
        response.autologin_link
      ) {
        openExternalURL(response.autologin_link);
      }
    },
    [driverList, offer.is_first_offer, onClose],
  );

  const { isSubmitting, handleSubmit } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () =>
      assignDriver(
        token,
        offer.guid,
        selectedDriverId as string,
        false,
        !!offer.is_first_offer,
      ),
    onSubmitSuccess: (response) => {
      const selectedDriver = driverList.data?.pages
        .flatMap(({ data }) => data)
        .find((d) => d.guid === selectedDriverId);

      if (selectedDriver) {
        onAssignSubmitSuccess(selectedDriver, response);
      }
      onClose();
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  const drivers = useMemo(() => {
    return driverList.data?.pages.flatMap((pages) => pages.data);
  }, [driverList]);

  return (
    <>
      <PublicOfferPageHeader offer={offer} />

      <PlanUpdateDrawer
        open={modalState === 'plan_update'}
        onClose={() => {
          setModalState(undefined);
        }}
        onSubmitSuccess={() => {
          setModalState('invite_driver');
        }}
      />

      <OffersPageLayout
        header={<PublicOfferStepper />}
        footer={
          modalState !== 'invite_driver' && (
            <PublicOfferPageFooter>
              <Columns space="large" align="center">
                <Column width="1/2">
                  <Button
                    fullWidth={true}
                    variant="neutral"
                    size="large"
                    onClick={onClose}
                    disabled={driverList.isLoading || isSubmitting}
                  >
                    Cancel
                  </Button>
                </Column>

                <Column width="1/2">
                  <Button
                    fullWidth={true}
                    variant="primary"
                    size="large"
                    disabled={driverList.isLoading}
                    pending={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Assign
                  </Button>
                </Column>
              </Columns>
            </PublicOfferPageFooter>
          )
        }
      >
        {modalState === 'invite_driver' ? (
          <Box marginTop="xlarge">
            <PublicOfferInviteDriverForm
              offer={offer}
              token={token}
              onCancel={() => {
                setModalState(undefined);
              }}
              onSubmitSuccess={onAssignSubmitSuccess}
            />
          </Box>
        ) : (
          <Stack space="large">
            <Columns align="center">
              <Column width="fluid">
                <TextBox variant="heading-2">Assign to Driver</TextBox>
              </Column>
              <Column width="content">
                <Button
                  startIcon={<Add color="primary" />}
                  variant="neutral"
                  pending={isNextPlanLoading}
                  onClick={() => {
                    if (shouldShowPlanUpdateDrawer) {
                      void fetchNextPlan().then(({ data }) => {
                        setModalState(
                          settings?.is_paying && data && !canInviteDriver
                            ? 'plan_update'
                            : 'invite_driver',
                        );
                      });
                    } else {
                      setModalState('invite_driver');
                    }
                  }}
                >
                  Invite Driver
                </Button>
              </Column>
            </Columns>

            <Stack>
              <TextField
                value={searchKey}
                onChange={({ target }) => {
                  setSearchKey(target.value);
                }}
                fullWidth={true}
                placeholder="Enter name to search a driver"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="action" />
                    </InputAdornment>
                  ),
                }}
              />

              <Box overflow="auto">
                {driverList.isLoading && (
                  <>
                    <AssignDriverItemLoading />
                    <AssignDriverItemLoading />
                  </>
                )}

                <List>
                  {!driverList.isLoading && !drivers?.length && (
                    <TextBox align="center" color="secondary">
                      No drivers found
                    </TextBox>
                  )}

                  {drivers?.map((driver) => {
                    const driverLabel = getDriverLabel(driver);

                    return (
                      <AssignDriverItem
                        key={driver.guid}
                        name={driverLabel}
                        notes={driver.notes}
                        phone={driver.phone}
                        trailerCapacity={driver.trailer?.capacity}
                        selected={driver.guid === selectedDriverId}
                        onSelect={() => {
                          setSelectedDriverId(driver.guid);
                        }}
                      />
                    );
                  })}

                  {driverList.hasNextPage && (
                    <VisibilityObserver
                      onChange={(visibility) => {
                        if (
                          !driverList.isFetchingNextPage &&
                          visibility === 'visible'
                        ) {
                          void driverList.fetchNextPage();
                        }
                      }}
                      render={({ ref }) => (
                        <div ref={ref}>
                          <AssignDriverItemLoading />
                          <AssignDriverItemLoading />
                        </div>
                      )}
                    />
                  )}
                </List>
              </Box>
            </Stack>
          </Stack>
        )}
      </OffersPageLayout>
    </>
  );
}
