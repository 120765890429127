import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import {
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
} from '@superdispatch/ui';
import { DriverIcon } from 'shared/icons/DriverIcon';
import { NotesIcon } from 'shared/icons/NotesIcon';
import { CopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { LoadTags } from '../core/LoadTags';
import { LoadDTO } from '../data/LoadDTO';

export function ViewLoadInfoMobileCard({
  load,
  onEditClick,
}: {
  load: LoadDTO;
  onEditClick: () => void;
}) {
  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Columns space="small">
            <Column width="fluid">
              <Stack>
                <OverflowText aria-label="Load Number" variant="h2">
                  {load.number || 'No Load ID'}
                </OverflowText>
                <Typography color="textSecondary" variant="caption">
                  Internal Load ID: {load.internal_load_id}
                </Typography>
              </Stack>
            </Column>
            <Column width="content">
              <Inline space="small">
                <IconButton onClick={onEditClick}>
                  <Edit />
                </IconButton>
              </Inline>
            </Column>
          </Columns>
          <LoadTags load={load} />

          <Stack space="small">
            <Columns align="top" space="xsmall">
              <Column width="content">
                <DriverIcon />
              </Column>
              <Column width="fluid">
                <Stack space="xxsmall">
                  <Typography color="textSecondary">Assigned to</Typography>

                  <Typography>
                    {load.driver?.name}

                    <CopyToClipboardTooltip
                      textToCopy={load.driver?.name || ''}
                      title="Copy Driver Name"
                    >
                      <IconButton size="small">
                        <NotesIcon />
                      </IconButton>
                    </CopyToClipboardTooltip>
                  </Typography>

                  <Typography color="textSecondary">
                    {load.driver?.phone_number}
                  </Typography>
                </Stack>
              </Column>
            </Columns>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
