import { Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  Image,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import attemptsExceededDark from 'shared/modules/carrier-profile/assets/attempts-exceeded-dark.png';
import attemptsExceeded from 'shared/modules/carrier-profile/assets/attempts-exceeded.png';
import styled from 'styled-components';
const MainImage = styled(Image)`
  max-width: 180px;
`;

interface PhoneVerificationAttemptsExceededProps {
  onBack: () => void;
}

export function PhoneVerificationAttemptsExceeded({
  onBack,
}: PhoneVerificationAttemptsExceededProps) {
  const { showLauncherMessanger } = useCustomerSupport();
  const isMobile = useResponsiveValue(true, false);
  return (
    <Stack space="small">
      <MainImage src={attemptsExceeded} srcDark={attemptsExceededDark} />
      <Stack space="xxsmall">
        <Typography variant="h4">
          You reached your maximum amount of requests
        </Typography>
        <Typography color="textSecondary">
          Please try again in an hour.
        </Typography>
      </Stack>
      <Columns collapseBelow="tablet" space="small">
        <Column width="content">
          <Button variant="primary" fullWidth={isMobile} onClick={onBack}>
            Go Back
          </Button>
        </Column>
        <Column width="content">
          <Button
            variant="neutral"
            fullWidth={isMobile}
            onClick={() => {
              showLauncherMessanger();
            }}
          >
            Contact Support
          </Button>
        </Column>
      </Columns>
    </Stack>
  );
}
