import {
  CanadaProvince,
  CanadaProvinceOptionsSet,
} from 'shared/form/CanadaProvince';
import { InferType, StringSchema } from 'yup';
import {
  CountryFieldOption,
  countryFieldOptions,
} from '../../form/CountryFieldOptions';
import { USAState, USAStateOptions } from '../../form/USAState';
import {
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from '../../utils/YupUtils';

export const CARRIER_LOGISTICS = [
  'auto_hauler',
  'towing',
  'driveaway',
] as const;

export const CARRIER_LOGISTICS_LABELS: Array<
  [(typeof CARRIER_LOGISTICS)[number], string]
> = [
  ['auto_hauler', 'Auto Hauler'],
  ['towing', 'Towing'],
  ['driveaway', 'Driveaway'],
];

export const carrierAddressSchema = yupObject({
  address: yupString().max(200).required('Enter Address'),
  city: yupString().max(100).required('Enter City'),
  zip: yupString()
    .max(10)
    .when('country', (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'CA') {
        return schema.required('Enter Postal Code');
      }
      return schema.required('Enter Zip');
    }),
  country: yupEnum([null, ...countryFieldOptions.keys()]).required(
    'Select Country',
  ),
  state: yupString().when(
    'country',
    (country: CountryFieldOption, schema: StringSchema) => {
      if (country === 'US') {
        return schema.test('us-state', 'Select valid state', (state) =>
          USAStateOptions.has(state as USAState),
        );
      }

      if (country === 'CA') {
        return schema.test('ca-province', 'Select valid province', (state) =>
          CanadaProvinceOptionsSet.has(state as CanadaProvince),
        );
      }

      return schema;
    },
  ),
});

export type CarrierInfoDTO = InferType<typeof carrierInfoSchema>;
export const carrierInfoSchema = carrierAddressSchema.concat(
  yupObject({
    contact_name: yupString().required(),
    email: yupString().email().required(),
    phone_numbers: yupString().required(),
    usdot_number: yupString(),
    name: yupString(),
    logo_url: yupString(),
    about: yupString(),
    mc_number: yupString(),
    website: yupString(),
    largest_trailer_capacity: yupNumber()
      .min(1, 'Trailer capacity can not be less than 1')
      .max(11, 'Trailer capacity can not be more than 11'),
    fmcsa_phone: yupString(),
    is_fmcsa_phone_verified: yupBoolean(),
    logistics_type: yupEnum(CARRIER_LOGISTICS, null).required(),
  }),
);
