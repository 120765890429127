import {
  IconButton,
  TableCell as MuiTableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { ColorDynamic, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { DeliveryIcon } from 'shared/icons/DeliveryIcon';
import { PickupIcon } from 'shared/icons/PickupIcon';
import { LinkAnchor } from 'shared/routing/Links';
import { CopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import styled from 'styled-components';
import { TripPaymentStatusTag } from '../core/TripPaymentStatusTag';
import { TripStatusTag } from '../core/TripStatusTag';
import { TripActionsDTO, TripShortDTO } from '../data/TripsDTO';
import { TripActions } from '../trip-actions/TripActions';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  &:hover {
    .MuiTableCell-body {
      background-color: ${ColorDynamic.Silver400};
    }
  }
`;

const StickyTableCell = styled(MuiTableCell)`
  left: 0;
  z-index: 12;
  max-width: 250px;
  position: sticky;
  padding: 12px 16px;
  background-color: ${ColorDynamic.White};
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  word-break: break-word;
`;

const StickyTableActionCell = styled(MuiTableCell)`
  right: 0;
  z-index: 1;
  max-width: 100px;
  position: sticky;
  padding: 12px 16px;
  background-color: ${ColorDynamic.White};
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  word-break: break-word;
`;

const TableCell = styled(MuiTableCell)`
  padding: 12px 16px;
  max-width: 400px;
  border-bottom: 1px solid ${ColorDynamic.Silver400};
`;

export function TripsTableRow({
  guid,
  archived,
  name,
  loads,
  driver,
  dispatcher,
  price,
  route,
  changed_at: changedAt,
  ...props
}: TripShortDTO) {
  const navigate = useNavigate();
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const handleRowClick = (event: React.MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault();
      navigate(`/trips/view/${guid}`);
    }
  };

  const tripDetails: TripActionsDTO = {
    guid,
    name,
    archived,
    driver: {
      guid: driver?.guid || '',
      name: driver?.name || '',
      email: driver?.email || '',
      phone: '',
    },
    status: props.status,
    is_driver_paid: props.is_driver_paid,
    is_paid_by_customer: props.is_paid_by_customer,
    is_customer_billed: props.is_customer_billed,
    is_completed: props.is_completed,
    map_route_url: '',
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorElement(event.currentTarget);
  };

  return (
    <StyledTableRow hover={true} onClick={handleRowClick}>
      <StickyTableCell>
        <Box minWidth="200px">
          <CopyToClipboardTooltip
            textToCopy={name}
            title="Copy Name"
            onCopy={(event) => {
              event.stopPropagation();
            }}
          >
            <LinkAnchor underline="none" to={`/trips/view/${guid}`}>
              {name}
            </LinkAnchor>
          </CopyToClipboardTooltip>
        </Box>
      </StickyTableCell>
      <TableCell>
        {props.status && <TripStatusTag status={props.status} />}
      </TableCell>
      <TableCell>
        <Tooltip
          interactive={true}
          placement="bottom"
          PopperProps={{
            onClick: (event) => {
              event.stopPropagation();
            },
          }}
          title={
            <Stack space="xxsmall">
              <TextBox color="white">{loads.new} New</TextBox>
              <TextBox color="white">{loads.picked_up} Picked Up</TextBox>
              <TextBox color="white">{loads.delivered} Delivered</TextBox>
            </Stack>
          }
        >
          {/* Wrapped in a Box component due to accept of the Tooltip component */}
          <Box>
            <Stack align="left" space="small">
              <Box>
                {loads.total}&nbsp;
                {formatPlural(loads.total, 'load', 'loads')}
              </Box>
              {!!loads.total && (
                <Inline noWrap={true} space="xsmall">
                  {loads.new !== null && loads.new > 0 && (
                    <Tag color="teal" variant="subtle">
                      {loads.new} New
                    </Tag>
                  )}
                  {loads.picked_up !== null && loads.picked_up > 0 && (
                    <Tag color="yellow" variant="subtle">
                      <Inline space="xxsmall" verticalAlign="center">
                        {loads.picked_up} <PickupIcon />
                      </Inline>
                    </Tag>
                  )}
                  {loads.delivered !== null && loads.delivered > 0 && (
                    <Tag color="green" variant="subtle">
                      <Inline space="xxsmall" verticalAlign="center">
                        {loads.delivered} <DeliveryIcon />
                      </Inline>
                    </Tag>
                  )}
                </Inline>
              )}
            </Stack>
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={driver?.name || driver?.email || ''}
          title="Copy Driver Name"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Typography>{driver?.name || driver?.email}</Typography>
        </CopyToClipboardTooltip>
      </TableCell>

      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={dispatcher?.name || ''}
          title="Copy Dispatcher Name"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Typography>{dispatcher?.name}</Typography>
        </CopyToClipboardTooltip>
      </TableCell>
      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={formatAddressLine(
            route?.origin?.address,
            route?.origin?.city,
            route?.origin?.state,
          )}
          title="Copy Start Address"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Box>
            <TextBox color="secondary">{route?.origin?.address}</TextBox>
            {formatAddressLine(' ', route?.origin?.city, route?.origin?.state)}
          </Box>
        </CopyToClipboardTooltip>
      </TableCell>
      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={formatAddressLine(
            route?.destination?.address,
            route?.destination?.city,
            route?.destination?.state,
          )}
          title="Copy End Address"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Box>
            <TextBox color="secondary">{route?.destination?.address}</TextBox>
            {formatAddressLine(
              ' ',
              route?.destination?.city,
              route?.destination?.state,
            )}
          </Box>
        </CopyToClipboardTooltip>
      </TableCell>
      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={formatCurrency(price)}
          title="Copy Price"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Typography>{formatCurrency(price)}</Typography>
        </CopyToClipboardTooltip>
      </TableCell>
      <TableCell>
        <TripPaymentStatusTag
          isDriverPaid={props.is_driver_paid}
          isPaidByCustomer={props.is_paid_by_customer}
          isCustomerBilled={props.is_customer_billed}
        />
      </TableCell>
      <TableCell>
        <CopyToClipboardTooltip
          textToCopy={changedAt || ''}
          title="Copy Modified Date"
          onCopy={(event) => {
            event.stopPropagation();
          }}
        >
          <Box>
            <FormattedDate variant="Date" date={changedAt} />
          </Box>
        </CopyToClipboardTooltip>
      </TableCell>

      <StickyTableActionCell align="right">
        <IconButton
          onClick={handleMenuClick}
          aria-label={`${name} trip actions`}
          id="options-button"
          aria-controls={menuAnchorElement ? 'options-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuAnchorElement ? 'true' : undefined}
        >
          <MoreHoriz />
        </IconButton>
        <TripActions
          id="options-menu"
          showDownloadReport={true}
          anchorEl={menuAnchorElement}
          trip={tripDetails}
          onItemClick={() => {
            setMenuAnchorElement(null);
          }}
          onBlur={() => {
            setMenuAnchorElement(null);
          }}
          open={!!menuAnchorElement}
        />
      </StickyTableActionCell>
    </StyledTableRow>
  );
}
