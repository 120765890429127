import { Link, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikPasswordField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useQueryClient } from '@tanstack/react-query';
import { Form, FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  driverActivationSchema,
  DriverInvitationDTO,
} from 'shared/modules/driver/DriverDTO';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import { validatePasswordStrength } from 'shared/utils/PasswordUtils';
import {
  DriverAcceptInvitationResponse,
  invalidateQuery,
  useDriversAPI,
} from '../data/DriversAPI';

interface DriverInvitationFormProps {
  invitationCode: string;
  driver?: DriverInvitationDTO;
}

export function DriverInvitationForm({
  invitationCode,
  driver,
}: DriverInvitationFormProps) {
  const { acceptInvite } = useDriversAPI();
  const queryClient = useQueryClient();

  const formik = useAppFormik({
    validationSchema: driverActivationSchema,
    initialValues: {
      email: driver?.email,
      phone_number: driver?.phone_number,
    },
    onSubmit(values) {
      return acceptInvite(invitationCode, values);
    },
    onSubmitSuccess(response: DriverAcceptInvitationResponse) {
      if (response.url) {
        openExternalURL(response.url, { target: '_self' });
      }
      invalidateQuery(queryClient, invitationCode);
    },
  });

  const passwordstrength = useMemo(
    () => validatePasswordStrength(formik.values.password),
    [formik.values.password],
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <Inline horizontalAlign="center">
          <Box
            padding="large"
            marginTop="large"
            maxWidth="440px"
            borderRadius="small"
            borderWidth="small"
            borderColor="Silver400"
            backgroundColor="White"
          >
            <Stack space="medium">
              <Stack space="small">
                <Typography variant="h2">
                  {driver?.contact_name} invited you to join{' '}
                  {driver?.carrier_name}
                </Typography>
                <Typography variant="h4">
                  Create your account to accept this invitation
                </Typography>
              </Stack>
              <Stack space="small">
                <FormikTextField
                  type="email"
                  name="email"
                  label="Email"
                  disabled={true}
                  fullWidth={true}
                  helperText={
                    <Typography variant="caption" color="textSecondary">
                      Your driver account will be created with this email
                      address.
                    </Typography>
                  }
                />
                <FormikTextField
                  fullWidth={true}
                  name="full_name"
                  label="Full Name"
                />
                <SuspendedFormikPhoneField
                  fullWidth={true}
                  name="phone_number"
                  label="Phone Number"
                />

                <Stack space="xsmall">
                  <FormikPasswordField
                    label="Password"
                    name="password"
                    fullWidth={true}
                    autoFocus={true}
                  />

                  <PasswordValidationStepper
                    passwordstrength={passwordstrength}
                    value={formik.values.password}
                  />
                </Stack>
              </Stack>

              <FormikCheckboxField
                name="is_subscribed"
                label={
                  <Typography variant="caption">
                    I agree to receive SMS and Email messages from Super
                    Dispatch such as the latest offers and deals.
                  </Typography>
                }
              />

              <Stack space="small">
                <Button
                  type="submit"
                  size="large"
                  fullWidth={true}
                  pending={formik.isSubmitting}
                  disabled={!formik.dirty || !formik.values.is_subscribed}
                >
                  Join Carrier
                </Button>
                <Stack align="center" space="xxsmall">
                  <Typography variant="caption" color="textSecondary">
                    By creating account you agree to Super Dispatch’s
                  </Typography>
                  <Inline space="xxsmall">
                    <Link
                      rel="noreferrer"
                      target="_blank"
                      variant="caption"
                      href="https://superdispatch.com/terms-of-service"
                    >
                      Terms & Conditions
                    </Link>{' '}
                    <Typography variant="caption" color="textSecondary">
                      and
                    </Typography>{' '}
                    <Link
                      rel="noreferrer"
                      target="_blank"
                      variant="caption"
                      href="https://superdispatch.com/privacy-policy/"
                    >
                      Privacy Policy
                    </Link>
                  </Inline>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Inline>
      </Form>
    </FormikProvider>
  );
}
