import { useEffect, useState } from 'react';
import { DriverDTO } from 'shared/modules/driver/DriverDTO';
import { useSubscriptionDetails } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverJoinRequestBanner } from './DriverJoinRequestBanner';
import { DriverPendingBanner } from './DriverPendingBanner';
import { DriverTrialExpiredBanner } from './DriverTrialExpiredBanner';

interface DriverDetailsBannersProps {
  currentDriver: DriverDTO;
}

export function DriverDetailsBanner({
  currentDriver,
}: DriverDetailsBannersProps) {
  const [isOpen, setBannerIsOpen] = useState<boolean>(true);
  const shouldShowTrialExpiredBanner = useFlag('trial_expired_banner');
  const { data: subscriptionDetails } = useSubscriptionDetails();

  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';

  useEffect(() => {
    if (!currentDriver.is_activated) {
      setBannerIsOpen(true);
    }
  }, [currentDriver]);

  const handleClose = () => {
    setBannerIsOpen(false);
  };

  const hasJoinRequest =
    currentDriver.connection_status === 'pending' &&
    currentDriver.connection_type === 'join_request';

  if (hasJoinRequest) {
    return <DriverJoinRequestBanner currentDriver={currentDriver} />;
  }

  if (
    isSubscriptionOnLitePlan &&
    !hasJoinRequest &&
    !shouldShowTrialExpiredBanner
  ) {
    return <DriverTrialExpiredBanner />;
  }

  if (
    !currentDriver.is_activated &&
    isOpen &&
    currentDriver.connection_status === 'pending' &&
    currentDriver.connection_type === 'invite' &&
    !isSubscriptionOnLitePlan
  ) {
    return <DriverPendingBanner onClose={handleClose} />;
  }

  return null;
}
