import { Badge, Typography } from '@material-ui/core';
import {
  Color,
  Column,
  Columns,
  Inline,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Key, useRef, useState } from 'react';
import { LoadSuggestionsSidebarIcon } from 'shared/icons/LoadSuggestionsSidebarIcon';
import { LoadSuggestionsButton } from 'shared/modules/load-suggestions/LoadSuggestionsButton';
import styled, { css, keyframes } from 'styled-components';
import {
  SidebarMenuLinkLegacy,
  StyledLinkLegacyIcon,
} from './AppSidebarMenuLegacy';

const Sonar = keyframes`
  0% { box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2); }
  100% { box-shadow: 0 0 0 20px rgba(0, 0, 0, 0); }
`;
const Pulse = keyframes`
  0% {transform: scale(1) translate(50%, -50%);}
  20% {transform: scale(1.4) translate(50%, -50%); } 
  50% {transform: scale(.9) translate(50%, -50%);} 
  80% {transform: scale(1.2) translate(50%, -50%);} 
  100% {transform: scale(1) translate(50%, -50%);}
`;

export const HighlightedBadgeWithAnimation = styled(Badge)<{
  $animate: boolean;
}>`
  ${SidebarMenuLinkLegacy}:hover & .MuiBadge-badge {
    border-color: ${Color.Dark400};
  }
  & .MuiBadge-badge {
    border: 2px solid ${Color.Dark500};
    top: 2px;
    animation-name: ${(props) =>
      props.$animate
        ? css`
            ${Pulse}, ${Sonar}
          `
        : 'none'};
    animation-duration: 1.5s;
    animation-iteration-count: 1;
  }

  & .MuiBadge-dot {
    margin-top: 2px;
    margin-right: 2px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
`;

const StyledBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function AppSidebarLoadSuggestionsLink() {
  const isMobile = useResponsiveValue(true, true, false);
  const [badgeKey, setBadgeKey] = useState<Key>();
  const previousCounterValue = useRef(0);

  return (
    <LoadSuggestionsButton
      render={({ loadSuggestionsCount = 0, onClick }) => {
        if (previousCounterValue.current < loadSuggestionsCount) {
          // Reset animation when counter increases.
          setBadgeKey(Math.random());
        }

        previousCounterValue.current = loadSuggestionsCount;

        return (
          <SidebarMenuLinkLegacy
            onClick={onClick}
            data-intercom-target="load-suggestions-menu-item"
          >
            {isMobile ? (
              <Columns align="center">
                <Column width="fluid">
                  <Inline space="small">
                    <StyledLinkLegacyIcon as={LoadSuggestionsSidebarIcon} />

                    <Typography variant="subtitle1">Suggested</Typography>
                  </Inline>
                </Column>

                <Column width="content">
                  {Boolean(loadSuggestionsCount) && (
                    <StyledBox backgroundColor="Blue300">
                      <Typography align="center" variant="caption">
                        {loadSuggestionsCount}
                      </Typography>
                    </StyledBox>
                  )}
                </Column>
              </Columns>
            ) : (
              <>
                <HighlightedBadgeWithAnimation
                  max={99}
                  badgeContent={loadSuggestionsCount}
                  overlap="rectangular"
                  color="primary"
                  $animate={!!loadSuggestionsCount}
                  key={badgeKey}
                >
                  <StyledLinkLegacyIcon as={LoadSuggestionsSidebarIcon} />
                </HighlightedBadgeWithAnimation>
                Suggested
              </>
            )}
          </SidebarMenuLinkLegacy>
        );
      }}
    />
  );
}
