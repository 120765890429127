import { createSvgIcon } from 'shared/icons/IconUtils';

export const CampaignIcon = createSvgIcon(
  'CampaignIcon',
  <>
    <g clipPath="url(#clip0_1573_3567)">
      <path
        d="M24.0003 14.6668V17.3335H29.3337V14.6668H24.0003ZM21.3337 23.4802C22.6137 24.4268 24.2803 25.6802 25.6003 26.6668C26.1337 25.9602 26.667 25.2402 27.2003 24.5335C25.8803 23.5468 24.2137 22.2935 22.9337 21.3335C22.4003 22.0535 21.867 22.7735 21.3337 23.4802ZM27.2003 7.46683C26.667 6.76016 26.1337 6.04016 25.6003 5.3335C24.2803 6.32016 22.6137 7.5735 21.3337 8.5335C21.867 9.24016 22.4003 9.96016 22.9337 10.6668C24.2137 9.70683 25.8803 8.46683 27.2003 7.46683ZM5.33366 12.0002C3.86699 12.0002 2.66699 13.2002 2.66699 14.6668V17.3335C2.66699 18.8002 3.86699 20.0002 5.33366 20.0002H6.66699V25.3335H9.33366V20.0002H10.667L17.3337 24.0002V8.00016L10.667 12.0002H5.33366ZM20.667 16.0002C20.667 14.2268 19.8937 12.6268 18.667 11.5335V20.4535C19.8937 19.3735 20.667 17.7735 20.667 16.0002Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1573_3567">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </>,
  { viewBox: '0 0 32 32', style: { width: 32, height: 32 } },
);
