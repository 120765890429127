import {
  Card,
  CardContent as MuiCardContent,
  CircularProgress,
  List as MuiList,
  ListItem,
  ListItemText,
  Tab,
  Tabs as MuiTabs,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { ClockAlertIcon } from 'shared/icons/ClockAlertIcon';
import { LinkAnchor } from 'shared/routing/Links';
import styled from 'styled-components';
import { trackDashboardEvent } from '../DashboardAnalytics';
import {
  useDelayedDeliveryLoads,
  useDelayedPickupLoads,
} from '../data/DashboardAPI';
import { useDashboardContext } from '../data/DashboardContext';
import { DelayedLoadStage } from '../data/DashboardDTO';
import { DelayedLoadItem } from './DelayedLoadListItem';

const CardContent = styled(MuiCardContent)`
  padding: 0;
`;

const EmptyBox = styled(MuiCardContent)`
  padding: 158px 0;
`;

const List = styled(MuiList)`
  max-height: 315px;
  overflow: auto;
  padding-top: 0;
`;

const Tabs = withStyles({
  root: {
    borderBottom: `1px solid ${ColorDynamic.Silver400}`,
  },
})(MuiTabs);

export function DelayedLoads() {
  const {
    params,
    updateParams,
    delayedLoadsTabs,
    totalDelayedLoadsCount,
    isDelayedLoadsLoading,
  } = useDashboardContext();

  if (isDelayedLoadsLoading) {
    return (
      <Card>
        <Box
          height="450px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Inline horizontalAlign="center">
            <CircularProgress />
          </Inline>
        </Box>
      </Card>
    );
  }

  if (!totalDelayedLoadsCount) {
    return (
      <Box minHeight="460px">
        <Card>
          <EmptyBox>
            <Inline horizontalAlign="center">
              <Box maxWidth="242px">
                <Stack space="medium" align="center">
                  <ClockAlertIcon />
                  <Stack align="center" space="xsmall">
                    <Typography variant="h3">No Delayed Loads</Typography>
                    <Typography
                      color="textSecondary"
                      align="center"
                      variant="body2"
                    >
                      Great! You don’t have any loads with delayed pickups or
                      deliveries.
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Inline>
          </EmptyBox>
        </Card>
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack space="none">
          <Stack space="small">
            <Box paddingLeft="medium" paddingRight="medium" paddingTop="small">
              <Stack space="xxsmall">
                <Columns space="small" align="center">
                  <Column width="fluid">
                    <Inline verticalAlign="center">
                      <Typography variant="h3">Delayed Loads</Typography>
                      <Tooltip
                        placement="right"
                        interactive={true}
                        title={
                          params.delayed_load_stage === 'pickup'
                            ? 'Loads which scheduled pickup date has already passed.'
                            : 'Loads which scheduled delivery date has already passed.'
                        }
                      >
                        <Info color="action" fontSize="small" />
                      </Tooltip>
                    </Inline>
                  </Column>
                  <Column width="content">
                    <LinkAnchor
                      aria-label="View All Delayed Loads"
                      to={parseURITemplate(
                        '/dashboard/loads?stage={stage}&driver_status={driver_status}&delayed_load_stage={delayed_load_stage}',
                        {
                          driver_status: params.driver_status,
                          delayed_load_stage: params.delayed_load_stage,
                          stage:
                            params.delayed_load_stage === 'pickup'
                              ? 'delayed-pickups'
                              : 'delayed-deliveries',
                        },
                      )}
                      underline="none"
                      color="primary"
                    >
                      <Inline verticalAlign="center" space="xxsmall">
                        View All
                        <ChevronRight fontSize="small" />
                      </Inline>
                    </LinkAnchor>
                  </Column>
                </Columns>
                <Inline space="xsmall" verticalAlign="center">
                  <ClockAlertIcon />
                  <Typography variant="h1">{totalDelayedLoadsCount}</Typography>
                </Inline>
              </Stack>
            </Box>
          </Stack>

          <Tabs
            value={params.delayed_load_stage}
            variant="scrollable"
            scrollButtons="on"
            onChange={(_, nextStage: DelayedLoadStage) => {
              updateParams(
                (prev) => ({
                  ...prev,
                  delayed_load_stage: nextStage,
                }),
                { strategy: 'merge' },
              );

              trackDashboardEvent({
                name: 'Carrier Clicked Delayed Loads Tab',
                stage: nextStage,
              });
            }}
          >
            {delayedLoadsTabs
              .filter(({ count }) => !!count)
              .map(({ label, count, stage }) => {
                const isActive = params.delayed_load_stage === stage;

                return (
                  <Tab
                    key={stage}
                    value={stage}
                    label={
                      <Inline space="xsmall" noWrap={true}>
                        <span>{label}</span>
                        <Tag
                          variant="subtle"
                          color={isActive ? 'blue' : 'grey'}
                        >
                          {count}
                        </Tag>
                      </Inline>
                    }
                  />
                );
              })}
          </Tabs>

          <List>
            {params.delayed_load_stage === 'pickup' && <DelayedPickupLoads />}
            {params.delayed_load_stage === 'delivery' && (
              <DelayedDeliveryLoads />
            )}
          </List>
        </Stack>
      </CardContent>
    </Card>
  );
}

const Container = styled.div`
  min-height: 315px;
`;

function DelayedPickupLoads() {
  const delayedPickupLoads = useDelayedPickupLoads({
    keepPreviousData: false,
  });

  if (delayedPickupLoads.isLoading) {
    return (
      <Container>
        <Inline horizontalAlign="center">
          <CircularProgress />
        </Inline>
      </Container>
    );
  }

  return (
    <Container>
      {delayedPickupLoads.data?.pages.flatMap((page) =>
        page.data.map((load) => (
          <DelayedLoadItem key={load.guid} load={load} stage="pickup" />
        )),
      )}

      {delayedPickupLoads.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (
              !delayedPickupLoads.isFetchingNextPage &&
              visibility === 'visible'
            ) {
              void delayedPickupLoads.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </Container>
  );
}

function DelayedDeliveryLoads() {
  const delayedDeliveryLoads = useDelayedDeliveryLoads({
    keepPreviousData: false,
  });

  if (delayedDeliveryLoads.isLoading) {
    return (
      <Container>
        <Inline horizontalAlign="center">
          <CircularProgress />
        </Inline>
      </Container>
    );
  }

  return (
    <Container>
      {delayedDeliveryLoads.data?.pages.flatMap((page) =>
        page.data.map((load) => (
          <DelayedLoadItem key={load.guid} load={load} stage="delivery" />
        )),
      )}

      {delayedDeliveryLoads.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (
              !delayedDeliveryLoads.isFetchingNextPage &&
              visibility === 'visible'
            ) {
              void delayedDeliveryLoads.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </Container>
  );
}
