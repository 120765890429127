import {
  Card,
  CardContent as MuiCardContent,
  CircularProgress,
  InputAdornment,
  List as MuiList,
  Tab,
  Tabs as MuiTabs,
  TextField as MuiTextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Info, Search } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DriverIcon } from 'shared/icons/DriverIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { trackDashboardEvent } from '../DashboardAnalytics';
import { useDeliveredLoadsCount } from '../data/DashboardAPI';
import { useDashboardContext } from '../data/DashboardContext';
import { DriverStage } from '../data/DashboardDTO';
import { AssignedDrivers } from './AssignedDrivers';
import { AvailableDrivers } from './AvailableDrivers';
import { DeliveringDrivers } from './DeliveringDrivers';
import { DriversList } from './DriversList';

const CardContent = styled(MuiCardContent)`
  padding: 0;
`;

const Tabs = withStyles({
  root: {
    borderBottom: `1px solid ${ColorDynamic.Silver400}`,
  },
})(MuiTabs);

const Container = styled.div`
  width: 100%;
  min-height: 224px;
  overflow-x: hidden;
`;

const EmptyBox = styled(MuiCardContent)`
  padding: 130px 0;
  height: 450px;
`;

const List = styled(MuiList)`
  max-height: 245px;
  overflow: auto;
  padding-top: 0;
`;

const TextField = styled(MuiTextField)`
  input {
    padding: 6px 0;
  }
`;

export function DriversStatistics() {
  const shouldShowNewDashboard = useFlag('ctms_new_dashboard');
  const navigate = useNavigate();
  const {
    params,
    updateParams,
    driversTabs,
    totalDriversCount,
    isDriversLoading,
  } = useDashboardContext();
  const { data: deliveredLoadsCount } = useDeliveredLoadsCount({
    enabled: shouldShowNewDashboard,
  });

  const totalDeliveredLoads = deliveredLoadsCount?.count ?? 0;

  if (isDriversLoading) {
    return (
      <Card>
        <Box
          height="450px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Inline horizontalAlign="center">
            <CircularProgress />
          </Inline>
        </Box>
      </Card>
    );
  }

  if (!totalDriversCount) {
    return (
      <Card>
        <EmptyBox>
          <Inline horizontalAlign="center">
            <Box maxWidth="242px">
              <Stack space="medium" align="center">
                <DriverIcon />
                <Stack align="center" space="xsmall">
                  <Typography variant="h3">No Drivers</Typography>
                  <Typography
                    color="textSecondary"
                    align="center"
                    variant="body2"
                  >
                    You don’t have any drivers. Once you invite drivers you will
                    see their statuses here.
                  </Typography>
                  <Button
                    variant="neutral"
                    onClick={() => {
                      navigate('/drivers');
                    }}
                  >
                    Invite Drivers
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Inline>
        </EmptyBox>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Box paddingLeft="medium" paddingRight="medium" paddingTop="small">
            <Stack space="small">
              <Stack space="xxsmall">
                <Inline verticalAlign="center">
                  <Typography variant="h3">Drivers</Typography>
                  <Tooltip
                    placement="right"
                    interactive={true}
                    title="Status of your active drivers"
                  >
                    <Info color="action" fontSize="small" />
                  </Tooltip>
                </Inline>
                <Columns>
                  <Column width="fluid">
                    <Inline
                      space="xsmall"
                      verticalAlign="center"
                      horizontalAlign="left"
                    >
                      <DriverIcon />
                      <Typography variant="h1">{totalDriversCount}</Typography>
                    </Inline>
                  </Column>

                  <Column width="content">
                    <Stack space="xxsmall" align="right">
                      <Typography variant="body1" color="textSecondary">
                        {totalDeliveredLoads}{' '}
                        {formatPlural(totalDeliveredLoads, 'load', 'loads')}
                      </Typography>
                      <Typography color="textSecondary">
                        Delivered this Month
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Stack>
              <SearchField />
            </Stack>
          </Box>

          {params.q ? (
            <DriversList />
          ) : (
            <Stack space="none">
              <Tabs
                value={params.driver_status}
                variant="scrollable"
                scrollButtons="off"
                onChange={(_, nextStage: DriverStage) => {
                  updateParams(
                    (prev) => ({
                      ...prev,
                      driver_status: nextStage,
                    }),
                    { strategy: 'reset' },
                  );

                  trackDashboardEvent({
                    name: 'Carrier Clicked Drivers Tab',
                    stage: nextStage,
                  });
                }}
              >
                {driversTabs
                  .filter(({ count }) => !!count)
                  .map(({ label, count, stage }) => {
                    const isActive = params.driver_status === stage;

                    return (
                      <Tab
                        key={stage}
                        value={stage}
                        label={
                          <Inline space="xsmall" noWrap={true}>
                            <span>{label}</span>
                            <Tag
                              variant="subtle"
                              color={isActive ? 'blue' : 'grey'}
                            >
                              {count}
                            </Tag>
                          </Inline>
                        }
                      />
                    );
                  })}
              </Tabs>

              <List>
                <Container>
                  {params.driver_status === 'available' && <AvailableDrivers />}
                  {params.driver_status === 'assigned' && <AssignedDrivers />}
                  {params.driver_status === 'delivering' && (
                    <DeliveringDrivers />
                  )}
                </Container>
              </List>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

function SearchField() {
  const [search, setSearch] = useSearchParams();
  const q = search.get('q');

  return (
    <TextField
      fullWidth={true}
      size="small"
      variant="outlined"
      value={q}
      onChange={(e) => {
        setSearch((prev) => {
          prev.set('q', e.target.value);
          return prev;
        });
      }}
      placeholder="Enter name to search a driver"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search htmlColor={Color.Dark100} />
          </InputAdornment>
        ),
      }}
    />
  );
}
