import { createContext, useEffect, useMemo } from 'react';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { UpdateParams, useLocationParams } from 'shared/routing/LocationParams';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useDelayedLoadsCount, useDriversCount } from './DashboardAPI';
import { DashboardPageParams, dashboardPageParamsSchema } from './DashboardDTO';

export const DashboardContext = createContext<DashboardContextType | null>(
  null,
);

export interface DashboardContextType {
  isDriversLoading: boolean;
  isDelayedLoadsLoading: boolean;
  deliveryCount: number;
  pickupCount: number;
  availableCount: number;
  assignedCount: number;
  deliveringCount: number;
  totalDriversCount: number;
  totalDelayedLoadsCount: number;
  driversTabs: Array<{
    label: string;
    count: number;
    stage: string;
  }>;
  delayedLoadsTabs: Array<{
    label: string;
    count: number;
    stage: string;
  }>;
  params: DashboardPageParams;
  updateParams: UpdateParams<DashboardPageParams>;
}

export const useDashboardContext = () => {
  return useNullableContext('DashboardContext', DashboardContext);
};

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const shouldShowNewDashboard = useFlag('ctms_new_dashboard');
  const [params, updateParams] = useLocationParams({
    yupSchema: dashboardPageParamsSchema,
    persistName: 'Dashboard',
  });

  const { data: driversCount, isLoading: isDriversLoading } = useDriversCount({
    enabled: shouldShowNewDashboard,
  });
  const { data: delayedLoadsCount, isLoading: isDelayedLoadsLoading } =
    useDelayedLoadsCount({
      enabled: shouldShowNewDashboard,
    });

  const deliveryCount = delayedLoadsCount?.delivery ?? 0;
  const pickupCount = delayedLoadsCount?.pickup ?? 0;
  const totalDelayedLoadsCount = deliveryCount + pickupCount;

  const availableCount = driversCount?.available ?? 0;
  const assignedCount = driversCount?.assigned ?? 0;
  const deliveringCount = driversCount?.delivering ?? 0;
  const totalDriversCount = driversCount?.total ?? 0;

  const driversTabs = useMemo(() => {
    return [
      {
        label: 'Available',
        count: availableCount,
        stage: 'available',
      },
      {
        label: 'Assigned',
        count: assignedCount,
        stage: 'assigned',
      },
      {
        label: 'Delivering',
        count: deliveringCount,
        stage: 'delivering',
      },
    ];
  }, [availableCount, assignedCount, deliveringCount]);

  const delayedLoadsTabs = useMemo(() => {
    return [
      {
        label: 'Delayed Pickups',
        count: pickupCount,
        stage: 'pickup',
      },
      {
        label: 'Delayed Deliveries',
        count: deliveryCount,
        stage: 'delivery',
      },
    ];
  }, [pickupCount, deliveryCount]);

  const [defaultDelayedLoadStage] = delayedLoadsTabs.filter(
    ({ count }) => !!count,
  );
  const [defaultDriverStage] = driversTabs.filter(({ count }) => !!count);

  useEffect(() => {
    if (
      isDelayedLoadsLoading ||
      isDriversLoading ||
      params.driver_status === defaultDriverStage?.stage ||
      params.delayed_load_stage === defaultDelayedLoadStage?.stage
    ) {
      return;
    }

    if (
      (defaultDelayedLoadStage || defaultDriverStage) &&
      shouldShowNewDashboard
    ) {
      updateParams((prev) => ({
        ...prev,
        ...(defaultDriverStage && {
          driver_status: defaultDriverStage.stage,
        }),
        ...(defaultDelayedLoadStage && {
          delayed_load_stage: defaultDelayedLoadStage.stage,
        }),
      }));
    }
    // Only re-render when the counts change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversTabs, delayedLoadsTabs, shouldShowNewDashboard]);

  const ctx = useMemo<DashboardContextType>(() => {
    return {
      params,
      driversTabs,
      delayedLoadsTabs,
      isDriversLoading,
      isDelayedLoadsLoading,
      deliveryCount,
      pickupCount,
      availableCount,
      assignedCount,
      deliveringCount,
      updateParams,
      totalDriversCount,
      totalDelayedLoadsCount,
    };
  }, [
    params,
    driversTabs,
    delayedLoadsTabs,
    updateParams,
    totalDriversCount,
    totalDelayedLoadsCount,
    isDriversLoading,
    isDelayedLoadsLoading,
    deliveryCount,
    pickupCount,
    availableCount,
    assignedCount,
    deliveringCount,
  ]);

  return (
    <DashboardContext.Provider value={ctx}>
      {children}
    </DashboardContext.Provider>
  );
};
