import { useScrollTrigger } from '@material-ui/core';
import { Box, BoxProps } from '@superdispatch/ui-lab';
import { useRef } from 'react';

export function StickyTopBox(props: BoxProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const isScrolled = useScrollTrigger({
    target: elementRef.current ?? window,
    disableHysteresis: true,
    threshold: 10,
  });

  return (
    <Box
      {...props}
      ref={elementRef}
      overflowY="auto"
      overflowX="hidden"
      borderTopWidth="small"
      borderTopColor={isScrolled ? 'Silver400' : 'White'}
    >
      {props.children}
    </Box>
  );
}
