import { Paper, PaperProps, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import {
  ColorDynamic,
  ColorDynamicProp,
  Image,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface PositionProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  rotate?: string;
}

export const GradientBox = styled.div<PositionProps>(
  ({ theme, top, bottom, left, right, rotate }) => {
    const mode = theme.palette.type;
    const color =
      mode === 'dark'
        ? 'linear-gradient(44.06deg,  #3D1E00 3.9%, #34003D 29.46%, rgba(0, 61, 61, 0.00) 55.02%)'
        : 'linear-gradient(44.06deg, #ffe6ce 5.56%, #fbe5ff 30.47%, #c8ffff 55.38%)';

    return `
    position: absolute;
    top: ${top || 'initial'};
    bottom: ${bottom || 'initial'};
    left: ${left || 'initial'};
    right: ${right || 'initial'};
    transform: rotate(${rotate || 'initial'});
    z-index: -1;
    width: 184px;
    height: 230px;
    filter: blur(8px);
    background: ${color};
  `;
  },
);

export const PromoChipContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
`;

const PromoChipBase = styled(Paper)<PositionProps>`
  padding: 8px 12px;
  border-radius: 28px;
  border: none;
  background-color: ${ColorDynamic.Silver200};
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  top: ${({ top }) => top || 'initial'};
  left: ${({ left }) => left || 'initial'};
`;

export function PromoChip({ children, ...props }: PaperProps & PositionProps) {
  return (
    <PromoChipBase {...props}>
      <Box color="Dark200">
        <Check color="inherit" />
      </Box>
      <TextBox variant="body-semibold" color="primary">
        {children}
      </TextBox>
    </PromoChipBase>
  );
}

export const PromoCardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const NumberBox = styled(Box)`
  border-radius: 24px;
  padding-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function PromoCard({
  backgroundColor,
  imgSrc,
  imgSrcDark,
  title,
  subtitle,
}: {
  backgroundColor: ColorDynamicProp;
  imgSrc: string;
  imgSrcDark?: string;
  title: string;
  subtitle: string;
}) {
  return (
    <Box
      backgroundColor={backgroundColor}
      borderRadius="medium"
      maxWidth={['initial', '310px', '310px']}
      width={['100%', undefined, undefined]}
      padding={['small', 'medium', 'medium']}
    >
      <Stack align="center">
        <Box maxWidth="240px">
          <Image src={imgSrc} srcDark={imgSrcDark} width="100%" />
        </Box>

        <div>
          <Typography align="center" variant="h4">
            {title}
          </Typography>
          <Typography align="center" color="textSecondary">
            {subtitle}
          </Typography>
        </div>
      </Stack>
    </Box>
  );
}

interface PromoStepCardProps {
  imgSrc: string;
  step: number;
  children: ReactNode;
}

export function PromoStepCard({ imgSrc, step, children }: PromoStepCardProps) {
  return (
    <Box
      backgroundColor="Silver200"
      borderRadius="medium"
      maxWidth={['initial', '400px', '400px']}
      width={['100%', 'auto', 'auto']}
      paddingTop="small"
      paddingLeft={['none', 'none', 'xlarge']}
      paddingRight={['none', 'xlarge', 'xxlarge']}
      overflow="hidden"
    >
      <Inline space="medium" noWrap={true}>
        <Box
          display="flex"
          maxWidth={['140px', '148px', '148px']}
          maxHeight={['86px', '92px', '92px']}
          marginLeft={['-xsmall', '-xsmall', 'none']}
        >
          <img src={imgSrc} width="100%" height="100%" />
        </Box>

        <Box maxWidth={['148px', '132px', '132px']}>
          <NumberBox
            width="24px"
            height="24px"
            backgroundColor="Blue50"
            marginBottom="xxsmall"
          >
            <TextBox align="center" variant="heading-6" color="blue">
              {step}
            </TextBox>
          </NumberBox>

          {children}
        </Box>
      </Inline>
    </Box>
  );
}
