import { Typography } from '@material-ui/core';
import {
  FormikNumberField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { DrawerActions, Inline, Stack } from '@superdispatch/ui';
import { Alert, Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  BaseFormikDrawerContentProps,
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import {
  DriverDTO,
  driverEditLegacySchema,
  driverEditSchema,
} from 'shared/modules/driver/DriverDTO';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackDriversEvent } from './data/DriversAnalytics';
import { useDriver, useDriversAPI } from './data/DriversAPI';
import { PlanUpdateDialog } from './PlanUpdateDialog';

export function DriverFormFields({
  hasWarning,
  type,
}: {
  hasWarning: boolean;
  type: 'add' | 'edit' | 'invite';
}) {
  const disabled = type === 'edit' && !isWebView;
  return (
    <Stack space="small">
      {hasWarning && (
        <Alert severity="caution" icon={<WarningIcon />}>
          This driver has both dispatcher and driver roles. Profile information
          for both roles will be updated.
        </Alert>
      )}

      {(type === 'add' || type === 'invite') && (
        <TextBox color="secondary" variant="body">
          Information entered will be used to send the driver text message and
          email with instructions to download the Super Dispatch Driver App.
        </TextBox>
      )}

      {type !== 'invite' && (
        <FormikTextField
          fullWidth={true}
          name="name"
          inputProps={{
            'aria-label': 'Full Name',
          }}
          label={
            <Inline space="xxsmall">
              Full Name
              <Typography color="textSecondary">(Optional)</Typography>
            </Inline>
          }
        />
      )}
      <FormikTextField
        fullWidth={true}
        type="email"
        name="email"
        label="Email"
        disabled={disabled}
      />
      <SuspendedFormikPhoneField
        fullWidth={true}
        name={type === 'invite' ? 'phone_number' : 'phone'}
        label="Phone"
        disabled={disabled}
      />
      {type !== 'invite' && (
        <FormikNumberField
          fullWidth={true}
          name="trailer.capacity"
          label="Trailer Capacity"
          disabled={disabled}
        />
      )}
    </Stack>
  );
}

interface DriverFormDrawerContent
  extends Omit<BaseFormikDrawerContentProps, 'children'> {
  onClose: () => void;
  showWaring?: boolean;
  type: 'add' | 'edit';
}

function DriverFormDrawerContent({
  actions = <FormikDrawerActions />,
  ...props
}: DriverFormDrawerContent) {
  return (
    <FormikDrawerContent {...props} actions={actions}>
      <Box maxWidth="368px">
        <DriverFormFields type={props.type} hasWarning={!!props.showWaring} />
      </Box>
    </FormikDrawerContent>
  );
}

interface DriverAddDrawerContentProps {
  onClose: () => void;
  onSuccess: (driver: DriverDTO) => void;
}

function DriverAddDrawerContent({
  onClose,
  onSuccess,
}: DriverAddDrawerContentProps) {
  const { createDriver } = useDriversAPI();
  const { data: settings } = useCarrierSettings();
  const shouldShowBillingDialog = useFlag('show_billing_dialog');
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const formik = useAppFormik({
    validationSchema: driverEditLegacySchema,
    onSubmit(values) {
      return createDriver(values);
    },
    onSubmitSuccess(response: APIResponse<DriverDTO>) {
      trackDriversEvent({
        name: 'CTMS: Driver Added',
        properties: {
          guid: response.data.guid,
          source: 'New Drivers Page',
          userGuid: settings?.user.guid,
        },
      });

      onSuccess(response.data);
    },
    getSuccessMessage(response) {
      return response.user_message || 'New Driver added';
    },
  });

  if (!settings) {
    return <ContentProgressIndicator />;
  }

  return (
    <>
      <PlanUpdateDialog
        open={shouldConfirm}
        onClose={() => {
          setShouldConfirm(false);
        }}
        onClick={() => {
          setShouldConfirm(false);
          void formik.submitForm();
        }}
      />

      <DriverFormDrawerContent
        formik={formik}
        onClose={onClose}
        title="Add Driver"
        type="add"
        actions={
          <DrawerActions>
            <Button
              pending={formik.isSubmitting}
              onClick={() => {
                if (
                  shouldShowBillingDialog &&
                  settings.is_paying &&
                  driverEditLegacySchema.isValidSync(formik.values)
                ) {
                  setShouldConfirm(true);
                } else {
                  void formik.submitForm();
                }
              }}
            >
              Save
            </Button>
          </DrawerActions>
        }
      />
    </>
  );
}

interface DriverEditDrawerContentProps {
  guid?: null | string;
  onClose: () => void;
}

function DriverEditDrawerContent({
  guid,
  onClose,
}: DriverEditDrawerContentProps) {
  const isDriverUpdateProfileWarningEnabled = useFlag(
    'driver_update_profile_warning',
  );

  const { data } = useDriver(guid);
  const { patchDriver } = useDriversAPI();
  const formik = useAppFormik({
    initialValues: data,
    validationSchema: driverEditSchema,
    onSubmit(values) {
      return patchDriver(guid as string, values, data);
    },
    onSubmitSuccess() {
      onClose();
    },
    getSuccessMessage(response) {
      return response.user_message || 'Driver updated';
    },
  });

  const isShowWarning =
    isDriverUpdateProfileWarningEnabled && !!data?.has_dispatcher;

  if (!data) {
    return <ContentProgressIndicator />;
  }

  return (
    <DriverFormDrawerContent
      type="edit"
      title="Edit Driver"
      formik={formik}
      onClose={onClose}
      showWaring={isShowWarning}
      actions={<FormikDrawerActions submitDisabled={!formik.dirty} />}
    />
  );
}

export type DriverFormDrawerProps =
  | ({ type: 'add'; open: boolean } & DriverAddDrawerContentProps)
  | ({ type: 'edit'; open: boolean } & DriverEditDrawerContentProps);

export function DriverFormDrawer(props: DriverFormDrawerProps) {
  return (
    <FormikDrawer open={props.open} onClose={props.onClose}>
      {props.type === 'add' && <DriverAddDrawerContent {...props} />}
      {props.type === 'edit' && <DriverEditDrawerContent {...props} />}
    </FormikDrawer>
  );
}
