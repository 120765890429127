import { ReactElement } from 'react';
import { render } from 'react-dom';
import { logError, logInfo } from 'shared/helpers/ErrorTracker';
function isValidBrowser(): boolean {
  const pattern = import.meta.env.BROWSERSLIST_PATTERN;

  if (pattern) {
    try {
      const browsersPattern = new RegExp(pattern);

      if (!browsersPattern.test(navigator.userAgent)) {
        logInfo('UnsupportedBrowser');
        document.getElementById('splash-screen')?.setAttribute('hidden', '');
        document.getElementById('outdated-browser')?.removeAttribute('hidden');
        return false;
      }
    } catch (e: unknown) {
      logError(e, 'AppRenderer');
    }
  }

  return true;
}

export function renderApp(ui: ReactElement): void {
  if (isValidBrowser()) {
    render(ui, document.getElementById('root'));
  }
}
