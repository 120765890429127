import { ListItem, ListItemText } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import {
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { chooseUTMSource } from 'shared/helpers/Analytics';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import { PaymentInfo } from 'shared/ui/PaymentInfo';
import styled from 'styled-components';
import { OfferDTO } from '../../../carrier/offers/offers/data/OffersDTO';

const PriceBox = styled(TextBox)`
  line-height: normal;
`;

interface Props {
  offer: OfferDTO;
  disabled?: boolean;
}

export function LoadOffersDrawerItem({
  offer: {
    load,
    created_at,
    guid,
    source,
    is_counter_offer,
    bid_price,
    original_price,
    carrier_contact_name,
  },
  disabled,
}: Props) {
  const navigate = useNavigate();
  const isMobile = useResponsiveValue(true, false);
  const { data: carrierSettings } = useCarrierSettings();
  const shouldShowContactName =
    carrierSettings?.company_type === 'fleet' &&
    source !== 'DIRECT' &&
    !!carrier_contact_name;
  const vehicleInfo = useMemo(() => {
    if (!!load?.vehicles && load.vehicles.length === 1) {
      const { year, make, model } = load.vehicles[0] || {};
      return `${year} ${make} ${model} `;
    }

    return `${load?.vehicles?.length} Vehicles`;
  }, [load?.vehicles]);

  return (
    <ListItem
      data-cy="OfferListItem"
      button={true}
      divider={true}
      disabled={disabled}
      component="div"
      ContainerComponent="div"
      onClick={() => {
        if (isMobile) {
          navigate(
            parseURITemplate('/offers/{guid}/{?utm_source}', {
              guid,
              utm_source: chooseUTMSource(),
            }),
          );
        } else {
          openExternalURL('/tms/offers/{guid}/{?utm_source}', {
            guid,
            target: '_blank',
            utm_source: chooseUTMSource(),
          });
        }
      }}
    >
      <ListItemText
        disableTypography={true}
        primary={
          <Box paddingTop="xsmall" paddingBottom="xsmall">
            <Stack space="small">
              <Stack space="xsmall">
                {shouldShowContactName && (
                  <TextBox color="secondary">{carrier_contact_name}</TextBox>
                )}
                <Stack space="xxsmall">
                  <Columns align="top" space="medium">
                    <Column width="fluid">
                      <Inline verticalAlign="bottom" space="small">
                        <Stack>
                          {is_counter_offer && (
                            <Tag variant="subtle" color="blue">
                              Counter Offer
                            </Tag>
                          )}
                          <Inline space="xsmall" verticalAlign="bottom">
                            <PriceBox variant="heading-3">
                              {formatCurrency(load?.price, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 0,
                              })}
                            </PriceBox>

                            {!!load?.price_per_mile && (
                              <TextBox variant="caption" color="secondary">
                                {formatCurrency(load.price_per_mile, {
                                  maximumFractionDigits: 2,
                                })}
                                /mi
                              </TextBox>
                            )}
                          </Inline>
                        </Stack>

                        {is_counter_offer && (
                          <>
                            <Stack space="xxsmall">
                              <TextBox variant="caption" color="secondary">
                                Bid
                              </TextBox>
                              <TextBox>
                                {formatCurrency(bid_price, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 0,
                                })}
                              </TextBox>
                            </Stack>

                            <Stack space="xxsmall">
                              <TextBox variant="caption" color="secondary">
                                Original
                              </TextBox>
                              <TextBox>
                                {formatCurrency(original_price, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 0,
                                })}
                              </TextBox>
                            </Stack>
                          </>
                        )}
                      </Inline>
                    </Column>
                    <Column width="content">
                      <TextBox variant="caption" color="secondary">
                        <FormattedDate date={created_at} variant="ShortDate" />,{' '}
                        <FormattedDate date={created_at} variant="Time" />
                      </TextBox>
                    </Column>
                  </Columns>

                  {load && (
                    <PaymentInfo
                      source="Load Offer"
                      method={load.payment_method_display}
                      terms={load.payment_terms_display}
                    />
                  )}

                  <TextBox color="secondary">{load?.broker_name}</TextBox>
                </Stack>
              </Stack>
              <Stack space="xxsmall">
                <Inline>
                  {!!load?.vehicles?.length && (
                    <TextBox variant="body-semibold">{vehicleInfo}</TextBox>
                  )}
                  {load?.is_inoperable && (
                    <Tag color="yellow" variant="bold">
                      INOP
                    </Tag>
                  )}
                  {load?.requires_enclosed_trailer && (
                    <Tag color="grey" variant="bold">
                      ENCLOSED
                    </Tag>
                  )}
                </Inline>

                <Stack>
                  <LoadWaypoints
                    dense={true}
                    space="small"
                    pickupAddress={
                      <TextBox>
                        {formatAddressLine(
                          load?.pickup_zip,
                          load?.pickup_city,
                          load?.pickup_state,
                        )}
                      </TextBox>
                    }
                    pickupBusinessType={load?.pickup_business_type}
                    pickupDate={
                      load?.pickup_date && (
                        <FormattedDate variant="Date" date={load.pickup_date} />
                      )
                    }
                    deliveryAddress={
                      <TextBox>
                        {formatAddressLine(
                          load?.delivery_zip,
                          load?.delivery_city,
                          load?.delivery_state,
                        )}
                      </TextBox>
                    }
                    deliveryBusinessType={load?.delivery_business_type}
                    deliveryDate={
                      load?.delivery_date && (
                        <FormattedDate
                          variant="Date"
                          date={load.delivery_date}
                        />
                      )
                    }
                  />

                  {!!load?.distance_miles && (
                    <TextBox color="secondary">
                      {formatNumber(load.distance_miles, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      mi
                    </TextBox>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        }
      />
    </ListItem>
  );
}
