import { startCase } from 'lodash-es';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { trackEvent } from 'shared/helpers/Analytics';
import { joinStrings } from 'shared/utils/StringUtils';
import { OfferDeclinePayloadDTO } from '../../../loads/data/LoadDTO';
import { OfferDetailsDTO } from './OffersDTO';

export type OffersAnalyticsEvent =
  | {
      name: 'CTMS: Viewed Load Offer';
      guid: string;
      is_counter_offer: boolean | null;
    }
  | {
      name: 'Declined Load Offer';
      offer: OfferDetailsDTO;
      payload: OfferDeclinePayloadDTO;
    }
  | { name: 'Accepted Load Offer'; offer: OfferDetailsDTO }
  | { name: 'Carrier Clicked Learn More Button' }
  | { name: 'Carrier Clicked Place Bid on Load Offer' };

function formatOfferDetails(offer: OfferDetailsDTO) {
  const { load } = offer;
  return {
    offer_price: load.price,
    shipper_guid: load.shipper_guid,
    vehicle_count: load.vehicles?.length,
    is_counter_offer: offer.is_counter_offer,
    is_price_negotiation: offer.is_price_negotiation,
    payment_type: load.payment_method_display,
    payment_terms: load.payment_terms_display,
    pickup_business_type: load.pickup_business_type,
    delivery_business_type: load.delivery_business_type,

    origin: formatAddressLine(
      load.pickup_zip,
      load.pickup_city,
      load.pickup_state,
    ),
    destination: formatAddressLine(
      load.delivery_zip,
      load.delivery_city,
      load.delivery_state,
    ),
    lane: joinStrings(
      ' -> ',
      joinStrings(', ', load.pickup_city, load.pickup_state),
      joinStrings(', ', load.delivery_city, load.delivery_state),
    ),
  };
}

export function trackOffersEvent(event: OffersAnalyticsEvent) {
  const baseParams = { is_public_offer: false };

  switch (event.name) {
    case 'Accepted Load Offer': {
      const { offer } = event;

      trackEvent(event.name, {
        ...baseParams,
        ...formatOfferDetails(offer),
      });

      break;
    }
    case 'Declined Load Offer': {
      const {
        offer,
        payload: { decline_comment, decline_reasons = [] },
      } = event;

      trackEvent(event.name, {
        ...baseParams,
        ...formatOfferDetails(offer),

        decline_reason: [...decline_reasons, decline_comment && 'Comment']
          .filter(Boolean)
          .map(startCase)
          .join(', '),
      });

      break;
    }
    default: {
      const { name, ...props } = event;
      trackEvent(event.name, {
        ...baseParams,
        ...props,
      });
    }
  }
}
