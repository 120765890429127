import { Outlet, RouteObject } from 'react-router-dom';
import { LoginContainerPage } from './LoginContainerPage';
import { RedirectToTMS } from './LoginContextProvider';
import { LogoutContainerPage } from './LogoutContainerPage';
import { ResetPasswordSuccessPage } from './ResetPasswordSuccessPage';
import { VerifyAccountContainer } from './VerifyAccountContainer';
import { VerifyAccountPage } from './VerifyAccountPage';

function LoginOutlet() {
  return (
    <RedirectToTMS>
      <Outlet />
    </RedirectToTMS>
  );
}

export const loginRoutes: RouteObject[] = [
  {
    path: '/login/:page?',
    element: <LoginOutlet />,
    children: [
      {
        index: true,
        element: <LoginContainerPage />,
      },
      {
        path: 'verify',
        element: (
          <VerifyAccountContainer>
            <VerifyAccountPage />
          </VerifyAccountContainer>
        ),
      },
    ],
  },
  {
    path: '/reset-password-success',
    element: <ResetPasswordSuccessPage />,
  },
  { path: '/logout', element: <LogoutContainerPage /> },
];
