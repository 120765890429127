import {
  Badge,
  Card,
  CardContent as MuiCardContent,
  Divider,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { ChevronRight, ThumbUp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Image,
  Inline,
  Stack,
  Tag,
  useSnackbarStack,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo, useState } from 'react';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { CarrierRatingsList } from 'shared/modules/ratings/CarrierRatingsList';
import { CarrierRatingsToGive } from 'shared/modules/ratings/CarrierRatingsToGive';
import { CarrierReviewResponseDialog } from 'shared/modules/ratings/CarrierReviewResponseDialog';
import {
  useCarrierRatingsList,
  useCarrierRatingsSummary,
  useDeleteRatingResponse,
  useUnratedShippersCount,
} from 'shared/modules/ratings/data/CarrierRatingsAPI';
import { CarrierRatingDTO } from 'shared/modules/ratings/data/CarrierRatingsDTO';
import { LinkAnchor } from 'shared/routing/Links';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import styled from 'styled-components';
import EmptyRatingIllustrationDark from '../assets/empty-ratings-illustration-dark.svg';
import EmptyRatingIllustration from '../assets/empty-ratings-illustration.svg';
import { trackDashboardEvent } from '../DashboardAnalytics';
import { useDashboardContext } from '../data/DashboardContext';

const CardContent = styled(MuiCardContent)`
  padding: 0;
`;

export function DashboardRatings() {
  const { params, updateParams } = useDashboardContext();
  const { data: carrierRatingsSummary, isInitialLoading } =
    useCarrierRatingsSummary();
  const carrierRatings = useCarrierRatingsList();

  const ratings = useMemo(() => {
    return carrierRatings.data?.pages.flatMap((page) => page.data) ?? [];
  }, [carrierRatings.data?.pages]);

  const { data } = useUnratedShippersCount();

  const { addSnackbar } = useSnackbarStack();

  const [currentResponseRating, setCurrentResponseRating] =
    useState<CarrierRatingDTO>();
  const [currentDeleteResponseRating, setCurrentDeleteResponseRating] =
    useState<CarrierRatingDTO>();

  const onCloseResponseDialog = () => {
    setCurrentResponseRating(undefined);
  };

  const onCloseDeleteResponseDialog = () => {
    setCurrentDeleteResponseRating(undefined);
  };

  const deleteRatingResponse = useDeleteRatingResponse({
    onSuccess: () => {
      addSnackbar('Response deleted');
      onCloseDeleteResponseDialog();
    },
    onError: ({ message }) => {
      addSnackbar(message || 'Failed to delete response', { variant: 'error' });
    },
  });

  const isLoading = isInitialLoading || carrierRatings.isInitialLoading;

  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: 'ratings_received' | 'ratings_to_give',
  ) => {
    trackDashboardEvent({
      name:
        newValue === 'ratings_received'
          ? 'Carrier Clicked Ratings Received Tab'
          : 'Carrier Clicked Ratings To Give Tab',
    });
    updateParams({ ratings_stage: newValue });
  };

  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Box
            height="450px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ContentProgressIndicator />
          </Box>
        </CardContent>
      </Card>
    );
  }

  const activeTab = params.ratings_stage;

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Box paddingLeft="medium" paddingRight="medium" paddingTop="small">
            <Stack space="xxsmall">
              <Columns space="small" align="center">
                <Column width="fluid">
                  <Typography variant="h3">Company Ratings</Typography>
                </Column>
                <Column width="content">
                  <LinkAnchor
                    to={parseURITemplate('/profile/overview?tab={tab}', {
                      tab: params.ratings_stage,
                    })}
                    underline="none"
                    color="primary"
                    onClick={() => {
                      trackDashboardEvent({
                        name: 'Carrier Clicked View All Ratings',
                        stage: params.ratings_stage,
                      });
                    }}
                  >
                    <Inline verticalAlign="center" space="xxsmall">
                      View All
                      <ChevronRight fontSize="small" />
                    </Inline>
                  </LinkAnchor>
                </Column>
              </Columns>
              {!!carrierRatingsSummary?.rating.percentage && (
                <Inline space="xsmall" verticalAlign="center">
                  <ThumbUp fontSize="large" color="action" />
                  <Typography variant="h1">
                    {carrierRatingsSummary.rating.percentage}%
                  </Typography>
                </Inline>
              )}
            </Stack>
          </Box>
        </Stack>
        <Tabs
          value={params.ratings_stage}
          onChange={handleTabChange}
          variant="scrollable"
        >
          <Tab
            value="ratings_received"
            label={
              <Inline>
                <Typography>Ratings received</Typography>

                {!!carrierRatingsSummary?.rating.count && (
                  <Tag
                    variant="subtle"
                    color={activeTab === 'ratings_received' ? 'blue' : 'grey'}
                  >
                    {carrierRatingsSummary.rating.count}
                  </Tag>
                )}
              </Inline>
            }
          />
          <Tab
            value="ratings_to_give"
            label={
              <Inline verticalAlign="center">
                <Typography>Ratings to Give</Typography>
                {!!data?.unrated_count && <Badge />}
              </Inline>
            }
          />
        </Tabs>
        <Divider />
        <Box
          paddingLeft="medium"
          paddingRight="medium"
          paddingBottom="small"
          paddingTop="small"
          maxHeight="320px"
          overflow="auto"
        >
          {activeTab === 'ratings_received' &&
            (ratings.length ? (
              <>
                <CarrierReviewResponseDialog
                  rating={currentResponseRating}
                  onClose={onCloseResponseDialog}
                />

                <MutationDialog
                  open={!!currentDeleteResponseRating?.guid}
                  onClose={onCloseDeleteResponseDialog}
                  maxWidth="xs"
                >
                  <ConfirmDialogContent
                    onClose={onCloseDeleteResponseDialog}
                    title="Delete response?"
                    cancelButtonProps={{
                      disabled: deleteRatingResponse.isLoading,
                    }}
                    confirmButtonProps={{
                      children: 'Delete',
                      pending: deleteRatingResponse.isLoading,
                      disabled: !currentDeleteResponseRating?.guid,
                      onClick() {
                        if (currentDeleteResponseRating?.guid) {
                          deleteRatingResponse.mutate(
                            currentDeleteResponseRating.guid,
                          );
                        }
                      },
                    }}
                  >
                    <Box width="312px" />
                  </ConfirmDialogContent>
                </MutationDialog>

                <CarrierRatingsList
                  ratings={ratings}
                  onClickAddResponse={(rating) => {
                    setCurrentResponseRating(rating);
                    trackDashboardEvent({
                      name: 'Carrier Clicked Add Response',
                    });
                  }}
                  onClickDeleteResponse={setCurrentDeleteResponseRating}
                />
                {carrierRatings.hasNextPage && (
                  <VisibilityObserver
                    onChange={(visibility) => {
                      if (
                        !carrierRatings.isFetchingNextPage &&
                        visibility === 'visible'
                      ) {
                        void carrierRatings.fetchNextPage();
                      }
                    }}
                    render={({ ref }) => (
                      <ListItem ref={ref}>
                        <ListItemText
                          primary={<Skeleton />}
                          secondary={<Skeleton />}
                        />
                      </ListItem>
                    )}
                  />
                )}
              </>
            ) : (
              <Box
                height="280px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Stack align="center">
                  <Image
                    src={EmptyRatingIllustration}
                    srcDark={EmptyRatingIllustrationDark}
                  />
                  <TextBox variant="heading-3">No ratings yet</TextBox>
                  <Box maxWidth="200px">
                    <TextBox color="secondary" align="center">
                      Ask shippers to give you a rating after delivering the
                      load
                    </TextBox>
                  </Box>
                </Stack>
              </Box>
            ))}
          {activeTab === 'ratings_to_give' && (
            <CarrierRatingsToGive
              dense={true}
              onRateShipper={(guid) => {
                trackDashboardEvent({
                  name: 'Carrier Clicked Rate Shipper',
                  shipper_guid: guid,
                });
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
