import {
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, Clear, Close, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  CardButton as MuiCardButton,
  Color,
  DrawerContent,
  DrawerList,
  DrawerTitle,
  Stack,
  useSnackbarStack,
  useUID,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DriverDTO } from 'shared/modules/driver/DriverDTO';
import { BackButton } from 'shared/ui/BackButton';
import { toSearchQueryText } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { useDriverList } from '../../drivers/data/DriversAPI';
import { LoadDriverDTO, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadsContext } from '../data/LoadsContext';
import { DriverItem } from './DriverItem';
import { LoadsAddDriverForm } from './LoadsAddDriverForm';
import { LoadsInviteDriverForm } from './LoadsInviteDriverForm';

const CardButton = styled(MuiCardButton)`
  padding: 0;
  min-height: 48px;
`;

export type LoadAssignDrawerStep =
  | 'add_driver'
  | 'assign_driver'
  | 'invite_driver';
export interface LoadAssignDrawerValues {
  loadGUID: string;
  newDriverGUID: string;
  currentDriver?: LoadDriverDTO | null;
}

export interface LoadAssignDrawerProps {
  load?: LoadDTO;
  open: boolean;
  step: LoadAssignDrawerStep;
  onSetStep: (step: LoadAssignDrawerStep) => void;
  onSetCurrentDriver: (driver: DriverDTO | null) => void;
  onSetPendingActivationDriver: (driver: DriverDTO | null | undefined) => void;
  onInviteDriver: () => void;
  onClose: () => void;
  onSubmitSuccess: (values: LoadAssignDrawerValues) => void;
}

export function LoadAssignDrawer({
  load,
  open,
  step,
  onSetStep,
  onSetCurrentDriver,
  onSetPendingActivationDriver,
  onInviteDriver,
  onClose,
  onSubmitSuccess,
}: LoadAssignDrawerProps) {
  const uid = useUID();
  const { loadsAPI } = useLoadsContext();
  const { addSnackbar } = useSnackbarStack();

  const [searchText, setSearchText] = useState('');
  const query = useDebouncedValue(searchText, 300);
  const drivers = useDriverList(
    { exclude_suspended: true, q: toSearchQueryText(query) },
    { enabled: !!load },
  );

  const { values, handleSubmit, isSubmitting, setFieldValue } =
    useFormikEnhanced<LoadAssignDrawerValues, APIResponse>({
      initialValues: {
        newDriverGUID: '',
        loadGUID: load?.guid || '',
        currentDriver: load?.driver,
      },
      onSubmit: ({ loadGUID, newDriverGUID, currentDriver }) => {
        if (currentDriver) {
          return loadsAPI.reassignDriver(loadGUID, newDriverGUID);
        }

        return loadsAPI.assignDriver(loadGUID, newDriverGUID);
      },
      onSubmitSuccess: ({ user_message }, nextValues) => {
        trackLoadsEvent({
          name: 'Carrier Assigned Driver',
        });
        onSubmitSuccess(nextValues);

        const driversList = drivers.data?.pages.flatMap((page) => page.data);

        const driver = driversList?.find(
          (item) => item.guid === nextValues.newDriverGUID,
        );

        if (!driver?.is_activated && !nextValues.currentDriver) {
          onSetPendingActivationDriver(driver);
        } else {
          addSnackbar(user_message, { variant: 'success' });
        }
      },
      onSubmitFailure: ({ message }) => {
        addSnackbar(message, { variant: 'error' });
      },
    });

  useEffect(() => {
    if (values.newDriverGUID) {
      handleSubmit();
    }
  }, [handleSubmit, values.newDriverGUID]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        onSetStep('assign_driver');
        onClose();
      }}
      PaperProps={{ 'aria-labelledby': uid }}
      SlideProps={{
        onExited: () => {
          setSearchText('');
        },
      }}
    >
      <DrawerTitle
        title={getTitle(step, values.currentDriver)}
        titleTypographyProps={{ id: uid }}
        startAction={
          step !== 'assign_driver' &&
          !values.currentDriver && (
            <BackButton
              onClick={() => {
                onSetStep('assign_driver');
              }}
            />
          )
        }
        endAction={
          <IconButton
            edge="end"
            aria-label="close"
            onClick={onClose}
            disabled={isSubmitting}
          >
            <Close />
          </IconButton>
        }
      />

      {step === 'assign_driver' && (
        <>
          <DrawerContent>
            <Stack space="medium">
              <TextField
                autoFocus={true}
                fullWidth={true}
                placeholder="Enter name to search a driver"
                value={searchText}
                disabled={isSubmitting}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText && (
                    <InputAdornment position="end">
                      {drivers.isFetching ? (
                        <CircularProgress size="1em" />
                      ) : (
                        <IconButton
                          aria-label="clear"
                          onClick={() => {
                            setSearchText('');
                          }}
                        >
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              {!values.currentDriver && (
                <CardButton
                  onClick={() => {
                    trackLoadsEvent({
                      name: 'Carrier Clicked Invite Driver Button Drawer',
                    });
                    onInviteDriver();
                  }}
                  startIcon={<Add htmlColor={Color.Blue300} />}
                >
                  Invite Driver
                </CardButton>
              )}
            </Stack>
          </DrawerContent>
          <DrawerList>
            {!drivers.data ? (
              <ListItem>
                <ListItemText
                  disableTypography={true}
                  primary={<Skeleton />}
                  secondary={<Skeleton />}
                />
              </ListItem>
            ) : !drivers.data.pages[0]?.pagination.count ? (
              <ListItem>
                <ListItemText
                  disableTypography={true}
                  secondary={
                    <Typography align="center" color="textSecondary">
                      No results
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              <>
                {drivers.data.pages.flatMap((page) =>
                  page.data.map((driver) => (
                    <DriverItem
                      driver={driver}
                      key={driver.guid}
                      selectedGUID={values.newDriverGUID}
                      isSubmitting={isSubmitting}
                      isAssigned={driver.guid === values.currentDriver?.guid}
                      onClick={() => {
                        void setFieldValue('newDriverGUID', driver.guid);
                      }}
                    />
                  )),
                )}

                {drivers.hasNextPage && (
                  <VisibilityObserver
                    threshold={0.01}
                    onChange={(visibility) => {
                      if (
                        !drivers.isFetchingNextPage &&
                        visibility === 'visible'
                      ) {
                        void drivers.fetchNextPage();
                      }
                    }}
                    render={({ ref }) => (
                      <ListItem ref={ref}>
                        <ListItemText
                          disableTypography={true}
                          primary={<Skeleton />}
                          secondary={<Skeleton />}
                        />
                      </ListItem>
                    )}
                  />
                )}
              </>
            )}
          </DrawerList>
        </>
      )}

      {step === 'add_driver' && (
        <Slide
          direction="left"
          in={true}
          mountOnEnter={true}
          unmountOnExit={false}
          timeout={200}
        >
          <Box>
            <LoadsAddDriverForm
              onClose={() => {
                onClose();
                onSetStep('assign_driver');
              }}
              onSuccess={onSetCurrentDriver}
              load={load}
            />
          </Box>
        </Slide>
      )}

      <Slide
        direction="left"
        in={step === 'invite_driver'}
        mountOnEnter={true}
        unmountOnExit={false}
        timeout={200}
      >
        <Box>
          <LoadsInviteDriverForm
            onClose={() => {
              onClose();
              onSetStep('assign_driver');
            }}
            onSuccess={onSetCurrentDriver}
            load={load}
          />
        </Box>
      </Slide>
    </Drawer>
  );
}

function getTitle(
  step: LoadAssignDrawerStep,
  driver: LoadDriverDTO | null | undefined,
) {
  if (step === 'add_driver') {
    return 'Add Driver';
  }
  if (step === 'invite_driver') {
    return 'Invite Driver';
  }
  if (driver) {
    return 'Reassign to Driver';
  }
  return 'Assign to Driver';
}
