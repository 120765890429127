import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Inline, Stack, VisibilityObserver } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { UserNotFoundIcon } from 'shared/icons/UserNotFoundIcon';
import styled from 'styled-components';
import { LoadAssign } from '../../loads/core/LoadAssign';
import { LoadDTO } from '../../loads/data/LoadDTO';
import { useDrivers } from '../data/DashboardAPI';
import { useDashboardContext } from '../data/DashboardContext';
import { DriverDTO } from '../data/DashboardDTO';
import { DashboardDriverListItem } from './DashboardDriverListItem';
import { DashboardDriversLoad } from './DashboardDriversLoad';

const Container = styled.div`
  width: 100%;
  min-height: 224px;
  height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export function DriversList() {
  const { params } = useDashboardContext();
  const [assignDriverLoad, setAssignDriverLoad] = useState<LoadDTO | undefined>(
    undefined,
  );
  const query = useDebouncedValue(params.q, 500);
  const drivers = useDrivers(query);

  const closeReassignDriverDrawer = () => {
    setAssignDriverLoad(undefined);
  };

  const driversList = useMemo(() => {
    return drivers.data?.pages.flatMap((page) => page.data) || [];
  }, [drivers.data]);

  if (drivers.isLoading) {
    return (
      <Inline horizontalAlign="center">
        <CircularProgress />
      </Inline>
    );
  }

  if (!driversList.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="medium"
      >
        <Stack space="medium" align="center">
          <UserNotFoundIcon />
          <Box maxWidth="280px">
            <Stack align="center" space="small">
              <Typography variant="h3">No Drivers Found</Typography>
              <Stack align="center" space="none">
                <Typography color="textSecondary">
                  Check your search request.
                </Typography>
                <Typography align="center" color="textSecondary">
                  Try modifying it to find the team member you need.
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <List>
      <Container>
        <LoadAssign
          onClose={closeReassignDriverDrawer}
          load={assignDriverLoad}
          onSubmitSuccess={() => {
            closeReassignDriverDrawer();
            void drivers.refetch();
          }}
        />

        {driversList.map((driver) => (
          <DriverItem
            key={driver.guid}
            driver={driver}
            setAssignDriverLoad={setAssignDriverLoad}
          />
        ))}

        {drivers.hasNextPage && (
          <VisibilityObserver
            onChange={(visibility) => {
              if (!drivers.isFetchingNextPage && visibility === 'visible') {
                void drivers.fetchNextPage();
              }
            }}
            render={({ ref }) => (
              <ListItem ref={ref}>
                <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              </ListItem>
            )}
          />
        )}
      </Container>
    </List>
  );
}

function DriverItem({
  driver,
  setAssignDriverLoad,
}: {
  driver: DriverDTO;
  setAssignDriverLoad: (load: LoadDTO) => void;
}) {
  return (
    <DashboardDriverListItem
      driver={driver}
      loads={driver.loads?.map((load) => (
        <DashboardDriversLoad
          key={load.guid}
          load={load}
          actions={
            driver.connection_status === 'deactivated' && (
              <Column width="content">
                <Button
                  variant="neutral"
                  onClick={() => {
                    setAssignDriverLoad({
                      ...load,
                      driver,
                    } as unknown as LoadDTO);
                  }}
                >
                  Reassign
                </Button>
              </Column>
            )
          }
        />
      ))}
    />
  );
}
