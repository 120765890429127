import { Typography } from '@material-ui/core';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import { AnchorButton, Button, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { EmptySearchLoadsIcon } from 'shared/icons/EmptySearchLoadsIcon';
import styled from 'styled-components';
import { formatLoadStage } from '../data/LoadDTO';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';

const StyledText = styled.div`
  overflow: hidden;
  text-align: center;
  color: ${ColorDynamic.Dark300};

  &:before,
  &:after {
    background-color: ${ColorDynamic.Silver500};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 100px;
  }
  &:before {
    right: 1em;
    margin-left: -50%;
  }
  &:after {
    left: 1em;
    margin-right: -50%;
  }
`;

export function LoadsPageEmptyResult() {
  const { params, isEmptyFilterParams, isEmptySearchParams, updateParams } =
    useLoadsPageContext();
  const hasFilterOrSearch = !isEmptyFilterParams || !isEmptySearchParams;

  if (hasFilterOrSearch) {
    return (
      <Stack space="small" align="center">
        <Stack space="medium" align="center">
          <EmptySearchLoadsIcon />

          <Stack align="center">
            <Typography variant="h2">Couldn&apos;t Find Anything</Typography>
            <Stack space="none">
              <TextBox color="secondary" align="center">
                Try modifying your search request.
              </TextBox>
            </Stack>
          </Stack>
        </Stack>
        <Button
          variant="neutral"
          onClick={() => {
            updateParams((prev) => ({ stage: prev.stage }), {
              strategy: 'reset',
            });
          }}
        >
          Go Back to Loads
        </Button>
      </Stack>
    );
  }

  return (
    <Stack space="small" align="center">
      <Stack space="medium" align="center">
        <EmptySearchLoadsIcon />

        <Typography variant="h2">
          No {formatLoadStage(params.stage)} loads
        </Typography>
      </Stack>
      <AnchorButton
        href={parseURITemplate(
          '/loadboard{?utm_source,utm_medium,utm_content}',
          {
            utm_source: 'Web CTMS',
            utm_medium: 'Empty List',
            utm_content: params.stage,
          },
        )}
        variant="neutral"
      >
        Find Loads on Loadboard
      </AnchorButton>
      <StyledText>or</StyledText>
      <Stack space="none" align="center">
        <TextBox color="secondary">
          Create or Import loads using the buttons
        </TextBox>
        <TextBox color="secondary">at the top right</TextBox>
      </Stack>
    </Stack>
  );
}
