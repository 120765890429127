import { Typography } from '@material-ui/core';
import { Column, Columns, LightDark, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ArrowRightRoundedIcon } from 'shared/icons/ArrowRightRounded';
import {
  CustomerVerificationDarkIcon,
  CustomerVerificationIcon,
} from 'shared/icons/CustomerVerificationIcon';
import styled from 'styled-components';

const DialogContainer = styled(Box)`
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 140px 100px 100px;
  align-items: center;
`;

interface GettingStartedContentProps {
  onClick: () => void;
}

export function GettingStartedContent({ onClick }: GettingStartedContentProps) {
  return (
    <DialogContainer>
      <Wrapper>
        <Columns space="small" align="top">
          <Column width="content">
            <ArrowRightRoundedIcon />
          </Column>
          <Column width="content">
            <Stack space="large">
              <Stack space="xsmall">
                <Typography variant="h3">4 Steps to Get Started</Typography>
                <Box width="500px">
                  <Typography color="textSecondary">
                    Super Loadboard is a trusted platform where vetted carriers
                    and shippers build meaningful business relationships. We
                    need to verify your company first to ensure a safe and
                    secure environment for everyone.
                  </Typography>
                </Box>
              </Stack>
              <Button onClick={onClick}>Continue</Button>
            </Stack>
          </Column>
        </Columns>

        <LightDark
          light={<CustomerVerificationIcon />}
          dark={<CustomerVerificationDarkIcon />}
        />
      </Wrapper>
    </DialogContainer>
  );
}
