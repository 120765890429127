import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const UserNotFoundIcon = createSvgIcon(
  'UserNotFoundIcon',
  <>
    <path
      d="M93.652 43.1578C85.575 43.1578 82.1631 36.3741 81.4668 32.9823C79.7261 32.2806 77.9853 29.8244 77.9854 27.0174C77.9854 24.1467 79.2619 23.3917 80.4224 23.1578C79.1459 20.1168 80.4224 11.9297 87.0372 8.7718C95.4797 4.74136 99.8027 9.12268 100.963 11.9297C107.369 11.9297 107.346 19.4151 106.534 23.1578C106.998 23.1578 109.319 23.8595 109.319 27.0174C109.319 30.3029 107.23 32.2806 105.489 32.9823C104.909 36.3741 101.729 43.1578 93.652 43.1578Z"
      stroke={ColorDynamic.Dark100}
      strokeWidth="1.39259"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.79 2.09"
    />
    <path
      d="M83.5557 22.807C84.6001 22.1052 85.9927 20.9123 91.5631 20.3509C97.1334 19.7895 99.6865 17.4269 99.2223 16.4912C99.3384 17.4269 100.058 19.7193 102.008 21.4035"
      stroke={ColorDynamic.Dark100}
      strokeWidth="1.39259"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.79 2.09"
    />
    <path
      d="M94.3479 25.2629C95.2763 27.3682 96.785 31.8594 95.3924 32.9822C94.6961 33.5436 93.9998 33.3331 92.9554 33.3331M87.385 41.4033V47.3682M87.385 47.3682C87.7331 48.6548 89.6131 51.2279 94.3479 51.2279C99.0828 51.2279 100.034 48.6548 99.9183 47.3682V41.4033M87.385 47.3682C74.0161 47.3682 67.4245 57.8945 65.7998 63.1577"
      stroke={ColorDynamic.Dark100}
      strokeWidth="1.39259"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.79 2.09"
    />
    <path
      d="M131.27 73.8717L128.958 71.5417C127.378 69.9487 125.667 68.4984 124.222 67.5533C123.308 66.9529 122.589 66.5903 122.028 66.4536C123.579 64.2425 124.429 61.5974 124.429 58.8394C124.429 55.3146 123.054 51.9681 120.589 49.4835C118.118 46.999 114.804 45.614 111.3 45.614C107.803 45.614 104.482 46.999 102.017 49.5073C99.546 51.9979 98.1777 55.3384 98.1777 58.8632C98.1777 62.3879 99.5283 65.7106 102.017 68.219C104.488 70.7095 107.803 72.0885 111.3 72.0885C114.078 72.0885 116.708 71.2148 118.908 69.6218C119.056 70.1627 119.433 70.9295 120.2 72.0648C121.208 73.5626 122.6 75.2686 123.927 76.606L126.239 78.936C126.906 79.6017 127.796 79.9703 128.734 79.9703C129.672 79.9703 130.562 79.6017 131.27 78.9122C132.638 77.5392 132.638 75.2567 131.27 73.8717Z"
      fill={ColorDynamic.White}
    />
    <path
      d="M111.3 72.1004C107.803 72.1004 104.482 70.7155 102.017 68.2309C99.5224 65.7166 98.1777 62.3998 98.1777 58.8751C98.1777 55.3503 99.5519 52.0038 102.017 49.5192C104.488 46.999 107.803 45.614 111.3 45.614C114.798 45.614 118.118 46.999 120.583 49.4835C123.054 51.9741 124.423 55.3146 124.423 58.8394C124.423 62.3642 123.049 65.7106 120.583 68.1952C118.118 70.7155 114.827 72.1004 111.3 72.1004ZM111.3 46.5353C108.027 46.5353 104.966 47.8252 102.654 50.1552C100.342 52.4853 99.0624 55.5702 99.0624 58.8691C99.0624 62.168 100.342 65.2529 102.654 67.583C104.966 69.913 108.027 71.2029 111.3 71.2029C114.568 71.2029 117.634 69.913 119.946 67.583C122.258 65.2529 123.538 62.168 123.538 58.8691C123.538 55.5702 122.258 52.4853 119.946 50.1552C117.64 47.8252 114.573 46.5353 111.3 46.5353Z"
      fill={ColorDynamic.Dark500}
    />
    <path
      d="M111.3 69.5625C108.582 69.5625 105.857 68.5282 103.799 66.43C99.6583 62.2573 99.6583 55.4811 103.799 51.3085C107.939 47.1358 114.662 47.1358 118.802 51.3085C122.943 55.4811 122.943 62.2573 118.802 66.43C116.744 68.5044 114.025 69.5625 111.3 69.5625ZM111.3 49.0973C108.806 49.0973 106.34 50.0424 104.441 51.9564C100.667 55.7605 100.667 61.9839 104.441 65.788C108.216 69.5922 114.391 69.5922 118.165 65.788C121.94 61.9839 121.94 55.7605 118.165 51.9564C116.266 50.0424 113.795 49.0973 111.3 49.0973Z"
      fill={ColorDynamic.Dark500}
    />
    <path
      d="M114.892 52.6874C114.845 52.6874 114.821 52.6874 114.78 52.6636C112.698 51.4867 110.186 51.4392 108.057 52.5507C107.945 52.622 107.803 52.5745 107.762 52.4556C107.691 52.3427 107.738 52.2 107.856 52.1584C110.121 50.9815 112.798 51.029 115.016 52.2951C115.128 52.3664 115.175 52.5031 115.104 52.6161C115.051 52.6458 114.963 52.6874 114.892 52.6874Z"
      fill={ColorDynamic.Dark500}
    />
    <path
      d="M128.728 79.9821C127.791 79.9821 126.9 79.6136 126.234 78.9419L123.922 76.6118C122.595 75.2744 121.203 73.5685 120.194 72.0706C118.502 69.5563 118.708 68.8193 119.074 68.4507L120.855 66.6557C121.403 66.1029 122.459 66.4001 124.217 67.5532C125.656 68.4983 127.372 69.9486 128.952 71.5416L131.264 73.8717C132.639 75.2566 132.639 77.5391 131.264 78.9181C130.563 79.6136 129.666 79.9821 128.728 79.9821ZM119.74 69.0808C119.605 69.7287 121.728 73.093 124.565 75.9521L126.876 78.2821C127.378 78.7873 128.044 79.0667 128.728 79.0667C129.436 79.0667 130.079 78.7873 130.58 78.2821C131.606 77.2419 131.606 75.5598 130.58 74.5493L128.268 72.2192C124.771 68.6944 121.958 67.1728 121.474 67.3333L119.74 69.0808Z"
      fill={ColorDynamic.Dark500}
    />
    <path
      d="M130.704 73.9966L126.34 78.3951L126.664 78.7221L131.029 74.3235L130.704 73.9966Z"
      fill={ColorDynamic.Dark500}
    />
  </>,
  {
    viewBox: '0 0 188 80',
    style: { width: '188px', height: '80px', fill: 'none' },
  },
);
