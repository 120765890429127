import { Updater, useQueryClient } from '@tanstack/react-query';
import {
  APIListQueryData,
  APIListQueryOptions,
  APIListQueryResult,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { NotificationVariant } from 'shared/layout/notification-stack/data/NotificationDTO';
import {
  AssignedDriverDTO,
  AvailableDriverDTO,
  DelayedLoadDTO,
  DelayedLoadsCountDTO,
  DeliveringDriverDTO,
  DriverDTO,
  DriversCountDTO,
  UnassignedLoadDTO,
} from './DashboardDTO';

export function useDelayedLoadsCount(
  options?: APIQueryOptions<DelayedLoadsCountDTO>,
): APIQueryResult<DelayedLoadsCountDTO> {
  return useAPIQuery(
    ['dashboard', 'delayed-loads-count'],
    () => requestCarrierAPI('GET /internal/web/dashboard/loads/delayed/count/'),
    { ...options },
  );
}

export function useDelayedPickupLoads(
  options?: APIListQueryOptions<DelayedLoadDTO>,
): APIListQueryResult<DelayedLoadDTO> {
  return useAPIListQuery(
    ['dashboard-pickup', 'delayed-pickup-loads'],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/loads/delayed/pickup/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export function useDelayedDeliveryLoads(
  options?: APIListQueryOptions<DelayedLoadDTO>,
): APIListQueryResult<DelayedLoadDTO> {
  return useAPIListQuery(
    ['dashboard-delivery', 'delayed-delivery-loads'],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/loads/delayed/delivery/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export const UNASSIGNED_LOADS_API_CACHE_KEY = ['dashboard', 'unassigned-loads'];
export function useUnassignedLoads(
  options?: APIListQueryOptions<UnassignedLoadDTO>,
): APIListQueryResult<UnassignedLoadDTO> {
  return useAPIListQuery(
    UNASSIGNED_LOADS_API_CACHE_KEY,
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/loads/unassigned/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export function useUnassignedLoadsCache() {
  const queryClient = useQueryClient();
  return {
    invalidateUnassignedLoads: () => {
      void queryClient.invalidateQueries(UNASSIGNED_LOADS_API_CACHE_KEY);
    },
    updateUnassignedLoads: (
      updater: Updater<
        APIListQueryData<UnassignedLoadDTO> | undefined,
        APIListQueryData<UnassignedLoadDTO> | undefined
      >,
    ) => {
      queryClient.setQueryData<APIListQueryData<UnassignedLoadDTO> | undefined>(
        UNASSIGNED_LOADS_API_CACHE_KEY,
        updater,
      );
    },
  };
}

export function useDriversCount(
  options?: APIQueryOptions<DriversCountDTO>,
): APIQueryResult<DriversCountDTO> {
  return useAPIQuery(
    ['dashboard', 'drivers-count'],
    () => requestCarrierAPI('GET /internal/web/dashboard/drivers/count/'),
    { ...options },
  );
}

export function useDeliveredLoadsCount(
  options?: APIQueryOptions<{ count: number }>,
): APIQueryResult<{ count: number }> {
  return useAPIQuery(
    ['dashboard', 'delivered-loads-count'],
    () =>
      requestCarrierAPI('GET /internal/web/dashboard/loads/delivered/count/'),
    { ...options },
  );
}

export function useAvailableDrivers(
  options?: APIListQueryOptions<AvailableDriverDTO>,
): APIListQueryResult<AvailableDriverDTO> {
  return useAPIListQuery(
    ['dashboard', 'available-drivers'],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/drivers/available/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export function useAssignedDrivers(
  options?: APIListQueryOptions<AssignedDriverDTO>,
): APIListQueryResult<AssignedDriverDTO> {
  return useAPIListQuery(
    ['dashboard', 'assigned-drivers'],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/drivers/assigned/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export function useDeliveringDrivers(
  options?: APIListQueryOptions<DeliveringDriverDTO>,
): APIListQueryResult<DeliveringDriverDTO> {
  return useAPIListQuery(
    ['dashboard', 'delivering-drivers'],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/drivers/delivering/{?page,params*}`,
        { page, params: { page_size: 10 } },
      ]),
    { ...options },
  );
}

export function useDashboardNotifications(
  options?: APIQueryOptions<NotificationVariant[]>,
): APIQueryResult<NotificationVariant[]> {
  return useAPIQuery(
    ['dashboard', 'notifications'],
    () => requestCarrierAPI('GET /internal/web/dashboard/notifications/'),
    { ...options },
  );
}

export function useDrivers(
  search?: string,
  options?: APIListQueryOptions<DriverDTO>,
): APIListQueryResult<DriverDTO> {
  return useAPIListQuery(
    ['dashboard', 'drivers-search', { search }],
    (page) =>
      requestCarrierAPI([
        `GET /internal/web/dashboard/drivers/search/{?page,params*}`,
        { page, params: { page_size: 10, q: search } },
      ]),
    { ...options },
  );
}
