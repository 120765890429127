import { Color } from '@superdispatch/ui';

export function LoadboardAccessTimerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none">
      <path
        fill={Color.Blue200}
        d="M16.833 27.187a9.333 9.333 0 110-18.667 9.333 9.333 0 010 18.667zm0-21.334a12 12 0 100 24 12 12 0 000-24zm.667 5.334h-2v8l6.333 3.8 1-1.64-5.333-3.16v-7zM11.34 5.04L9.633 3 3.5 8.133l1.72 2.04 6.12-5.133zm18.827 3.107L24.033 3l-1.72 2.04 6.134 5.147 1.72-2.04z"
      />
    </svg>
  );
}
