import {
  createHTTP,
  HTTPEndpointInput,
  HTTPError,
  HTTPRequestJSONOptions,
} from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
// eslint-disable-next-line you-dont-need-lodash-underscore/get
import { get } from 'lodash-es';
import { createAPIError } from 'shared/api/APIError';
import { APIPageResponse, APIResponse } from 'shared/api/CarrierAPIClient';
import { getAuthToken } from 'shared/auth/AuthToken';
import { LOADBOARD_HOST } from 'shared/constants/ServerConstants';
import {
  getMobileAppToken,
  MobileAppBridge,
} from 'shared/data/MobileAppBridge';
import { emitServerError } from 'shared/errors/ServerErrorEvents';
import { logWarning } from 'shared/helpers/ErrorTracker';

export interface LoadboardAPIResponse<TData = unknown> {
  data: TData;
}

export interface LoadboardAPIPageResponse<TData = unknown> {
  data: TData[];
  pagination: {
    page: number;
    total_pages: number;
    limit: number;
    total_objects: number;
  };
}

export interface LoadboardAPIErrorResponse {
  status: 'fail';
  data: {
    message: string;
    error_id: string;
    details: unknown;
  };
}

export function isLoadboardAPIPageResponse(
  response: unknown,
): response is LoadboardAPIPageResponse {
  return (
    typeof response === 'object' &&
    response != null &&
    'data' in response &&
    'pagination' in response
  );
}

export function isLoadboardAPIErrorResponse(
  response: unknown,
): response is LoadboardAPIErrorResponse {
  return (
    typeof response === 'object' &&
    response != null &&
    !!get(response, 'data') &&
    !!get(response, 'status')
  );
}

const loadboardHttpClient = createHTTP({
  baseURL: LOADBOARD_HOST,
  headers(headers) {
    headers = { ...headers };

    if (!headers.authorization) {
      const token = getAuthToken();
      if (token) headers.authorization = `Token ${token}`;
    }

    if (MobileAppBridge.getCarrierGuid()) {
      headers['X-Carrier-Guid'] = MobileAppBridge.getCarrierGuid() ?? '';
    }

    if (import.meta.env.VITE_APP_TARGET === 'local' || localStorage.DEBUG) {
      headers['X-Super-Dispatch-Log-Level'] = 'DEBUG';
    }

    return headers;
  },
});

function sanitizeSecret(secret: string | undefined) {
  if (secret == null) {
    return undefined;
  }

  return secret.substring(0, 4) + '****' + secret.substring(secret.length - 4);
}

function handleError(input: unknown): (error: unknown) => Promise<never> {
  return async (errorResponse: unknown) => {
    let error: Error;

    if (errorResponse instanceof HTTPError) {
      let errorJson;
      try {
        errorJson = (await errorResponse.response.json()) as HTTPError;
      } catch (e: unknown) {}

      if (isLoadboardAPIErrorResponse(errorJson)) {
        error = createAPIError({
          ...errorResponse,
          requestParams: input,
          status: errorResponse.response.status,
          context: errorJson.data.details,
          type: errorJson.data.error_id,
          message: errorJson.data.message || errorJson.data.error_id,
        });
      } else {
        error = createAPIError({
          ...errorResponse,
          requestParams: input,
          status: errorResponse.response.status,
          message: errorResponse.message || errorResponse.response.statusText,
        });
      }
    } else if (errorResponse instanceof Error) {
      error = createAPIError(errorResponse);
    } else {
      error = createAPIError({
        message: 'Unknown Error',
        context: errorResponse,
      });
    }

    const isUnauthorized =
      errorResponse instanceof HTTPError &&
      errorResponse.response.status === 401;
    logWarning(`APIError: ${error.message}`, {
      error,
      response: errorResponse,
      hasToken: !!getMobileAppToken(),
      hasBridge: MobileAppBridge.isInjected(),
      driverGuid: MobileAppBridge.getDriverGuid(),
      deviceGuid: MobileAppBridge.getDeviceGuid(),
      authToken: isUnauthorized ? sanitizeSecret(getAuthToken()) : undefined,
      mobileToken: isUnauthorized
        ? sanitizeSecret(getMobileAppToken())
        : undefined,
    });

    emitServerError(error);

    return Promise.reject(error);
  };
}

export function requestLoadboardAPI<
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestJSONOptions<Response>,
): Promise<Response> {
  return loadboardHttpClient.request(input, options).catch(handleError(input));
}

function requestJSON<
  TData,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestJSONOptions<TData>,
): Promise<TData> {
  return loadboardHttpClient
    .requestJSON(input, options)
    .catch(handleError(input));
}

export function requestJSONLoadboardAPI<
  TData extends LoadboardAPIResponse,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: Omit<HTTPRequestJSONOptions<TData>, 'baseURL'>,
): Promise<TData> {
  return requestJSON(input, options);
}

export function mapLoadboardAPIPageToCarrierAPIPage<TData>(
  response: LoadboardAPIPageResponse<TData>,
): APIPageResponse<TData> {
  return {
    data: response.data,
    pagination: {
      count: response.pagination.total_objects,
      previous:
        response.pagination.page > 0
          ? `${LOADBOARD_HOST}?page=${response.pagination.page - 1}` // Immitate Carrier TMS pagination
          : undefined,
      next:
        response.pagination.page + 1 < response.pagination.total_pages
          ? `${LOADBOARD_HOST}?page=${response.pagination.page + 1}` // Immitate Carrier TMS pagination
          : undefined,
    },
    meta: {
      request_id: '',
      code: 200,
    },
    user_message: '',
  };
}

export function mapLoadboardAPIToCarrierAPI<TData>(
  response: LoadboardAPIResponse<TData>,
): APIResponse<TData> {
  return {
    data: response.data,
    meta: {
      request_id: '',
      code: 200,
    },
    user_message: '',
  };
}
