import { PaletteType } from '@material-ui/core';
import {
  Color,
  ColorDynamic,
  ThemeProvider,
  ThemeProviderProps,
} from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { createGlobalStyle } from 'styled-components';

if (navigator.userAgent.includes('Win')) {
  document.documentElement.classList.add('windows');
}

const GlobalStyles = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${ColorDynamic.Silver500};
    border-radius: 12px;
  }

  body,
  html {
    background: ${ColorDynamic.Silver200} !important;
  }

  /* Autocomplete disabled options tooltip config setup https://devjonas.medium.com/tooltip-on-disabled-options-in-material-ui-autocomplete-4727a0c65b9b  */
  .MuiAutocomplete-listbox li[aria-disabled='true'] {
    pointer-events: inherit;
  }
  .MuiAutocomplete-listbox li[aria-disabled='true']:hover,
  .MuiAutocomplete-listbox li[aria-disabled='true']:focus {
    background: ${Color.White};
  }
  .MuiAutocomplete-listbox li[aria-disabled='true']:active {
    background: ${Color.White};
    pointer-events: none;
  }
`;

const APP_THEME_MODE = 'app_theme_mode';
const APP_THEME_MODE_SETTING = 'app_theme_mode_setting';

export type AppThemeModeSetting = PaletteType | 'system';

export function useThemeModeSetting(): AppThemeModeSetting {
  return useLocalStore(APP_THEME_MODE_SETTING, 'light') as AppThemeModeSetting;
}

export function setThemeModeSetting(value: AppThemeModeSetting) {
  LocalStore.set(APP_THEME_MODE_SETTING, value);
}

export function useAppThemeMode(): PaletteType {
  return useLocalStore(APP_THEME_MODE, 'light') as PaletteType;
}

export function setAppThemeMode(value: PaletteType) {
  LocalStore.set(APP_THEME_MODE, value);
}

function useThemeMode() {
  const appThemeMode = useAppThemeMode();
  const themeModeSetting = useThemeModeSetting();

  const [mobileMode, setMobileMode] = useState<PaletteType>(() => {
    return MobileAppBridge.isDarkMode() ? 'dark' : 'light';
  });

  useEffect(() => {
    if (MobileAppBridge.isInjected()) {
      return MobileAppBridge.listenDarkModeChanges((isDarkMode) => {
        const value = isDarkMode ? 'dark' : 'light';
        setMobileMode(value);
        setAppThemeMode(value);
      });
    }

    if (themeModeSetting !== 'system') {
      setAppThemeMode(themeModeSetting);
    } else {
      const preferredColor = window.matchMedia('(prefers-color-scheme: dark)');

      setAppThemeMode(preferredColor.matches ? 'dark' : 'light');
      const handleChange = (event: MediaQueryListEvent) => {
        setAppThemeMode(event.matches ? 'dark' : 'light');
      };

      preferredColor.addEventListener('change', handleChange);
      return () => {
        preferredColor.removeEventListener('change', handleChange);
      };
    }

    return undefined;
  }, [themeModeSetting]);

  return MobileAppBridge.hasMethodSupported('isDarkMode')
    ? mobileMode
    : appThemeMode;
}

export function AppThemeProvider({ children, modifier }: ThemeProviderProps) {
  const mode = useThemeMode();

  return (
    <ThemeProvider mode={mode} modifier={modifier}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
