import { Link, Typography } from '@material-ui/core';
import { ArrowForward, Info } from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import {
  useEstimateTaxPrice,
  useSubscriptionBillableSeats,
  useSubscriptionDetails,
} from 'shared/modules/subscription/SubscriptionAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';

const InformationDivider = styled.div`
  border-bottom: 1px dotted ${ColorDynamic.Silver400};
  height: 18px;
`;

export function SubscriptionPlanInformation() {
  const navigate = useNavigate();
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const { data: settings } = useCarrierSettings();
  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';
  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: isSubscriptionOnLitePlan
      ? subscriptionDetails?.upgrade_option?.plan?.measurement_type
      : subscriptionDetails?.subscription?.plan?.measurement_type,
  });
  const { data: taxPrice } = useEstimateTaxPrice(
    {
      seats_count: billableSeats?.billable_seats,
      include_total_invoice: false,
    },
    {
      enabled: !!billableSeats?.billable_seats,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  const upcomingBillingDate =
    subscriptionDetails?.subscription?.current_term_end;

  const purchasedSeatsCount = Number(
    subscriptionDetails?.subscription?.plan?.quantity,
  );
  const billableSeatsCount = Number(billableSeats?.billable_seats);
  const shouldDowngrade = purchasedSeatsCount > billableSeatsCount;

  if (!subscriptionDetails?.subscription?.plan) {
    return null;
  }

  const totalPrice = isSubscriptionOnLitePlan
    ? taxPrice?.plan?.amount
    : subscriptionDetails?.subscription?.plan?.amount;

  return (
    <Stack space="small">
      <Typography display="inline" variant="h3">
        Plan
      </Typography>

      <Stack space="xxsmall">
        <Columns>
          <Column width="fluid">
            <Inline verticalAlign="center" space="xxsmall">
              <DriveawayIcon htmlColor={ColorDynamic.Dark100} />
              <TextBox variant="body-semibold" color="secondary">
                {purchasedSeatsCount} driver{' '}
                {formatPlural(purchasedSeatsCount, 'seat', 'seats')}
              </TextBox>
            </Inline>
          </Column>
          <Column width="content">
            <Inline space="none" verticalAlign="center">
              <Typography variant="h4">{formatCurrency(totalPrice)}</Typography>
              <Typography variant="h5" color="textSecondary">
                /month
              </Typography>
            </Inline>
          </Column>
        </Columns>
        <Typography color="textSecondary">Billed monthly</Typography>
      </Stack>

      {shouldDowngrade &&
        subscriptionDetails?.subscription?.status === 'active' && (
          <Box borderRadius="small" backgroundColor="Silver200" padding="small">
            <Inline verticalAlign="center" space="xxsmall">
              Plan downgrading from{' '}
              <Typography variant="body1">
                <Inline verticalAlign="center" space="xxsmall">
                  {purchasedSeatsCount} <ArrowForward fontSize="small" />{' '}
                  {billableSeatsCount}
                </Inline>
              </Typography>{' '}
              drivers on{' '}
              <Typography variant="body1">
                {formatDate(upcomingBillingDate, { variant: 'ShortDate' })},{' '}
                {DateTime.now().year}
              </Typography>
            </Inline>
          </Box>
        )}

      <Stack space="xsmall">
        <Columns space="xsmall">
          <Column width="content">
            <Typography>
              Total{' '}
              {settings?.is_paying && (
                <>
                  due on{' '}
                  {formatDate(upcomingBillingDate, { variant: 'ShortDate' })}
                </>
              )}
            </Typography>
          </Column>
          <Column width="fluid">
            <InformationDivider />
          </Column>
          <Column width="content">
            <Typography variant="h4">{formatCurrency(totalPrice)}</Typography>
          </Column>
        </Columns>
        <Typography align="right">+ tax if applicable</Typography>
      </Stack>

      <Button
        variant="neutral"
        onClick={() => {
          navigate('/drivers');
        }}
        size="large"
        fullWidth={true}
      >
        Manage Drivers
      </Button>

      <Box borderRadius="small" backgroundColor="Blue50" padding="xsmall">
        <Columns space="xsmall">
          <Column width="content">
            <Info htmlColor={Color.Blue300} />
          </Column>
          <Column width="fluid">
            {subscriptionDetails?.subscription?.status === 'canceled' ? (
              <Typography>
                Invite or deactivate drivers to adjust plan.
              </Typography>
            ) : (
              <Stack>
                <Stack space="xxsmall">
                  <Typography variant="body1">Billing Notice</Typography>
                  <Typography variant="body1">
                    Adding Drivers:{' '}
                    <Typography display="inline" noWrap={false}>
                      Your plan updates immediately, and you&apos;ll be charged
                      a pro-rated amount for any drivers added today.
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Deactivating Drivers:{' '}
                    <Typography display="inline">
                      The number of available drivers remains unchanged until
                      your next billing cycle.
                    </Typography>
                  </Typography>
                </Stack>
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://support.superdispatch.com/hc/articles/35612448514195-How-To-Manage-Your-Subscription-Plan-In-Carrier-TMS"
                >
                  Learn More
                </Link>
              </Stack>
            )}
          </Column>
        </Columns>
      </Box>
    </Stack>
  );
}
