import { Card, Link, TextField, Typography } from '@material-ui/core';
import { EMPTY_ERROR_MESSAGE, FormikPasswordField } from '@superdispatch/forms';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DriverActivationDetailsDTO } from 'shared/modules/driver/DriverDTO';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import {
  passwordCheckTypes,
  validatePasswordStrength,
} from 'shared/utils/PasswordUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { useDriversAPI } from '../data/DriversAPI';

const validationSchema = yupObject({
  password: yupString()
    .required(EMPTY_ERROR_MESSAGE)
    .min(8, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toLowerCaseAndUpperCase, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toSpecialCharacter, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toNumber, EMPTY_ERROR_MESSAGE),
});

interface Props {
  carrierName: string;
  email: string;
  activationCode: string;
}

export function DriverActivationSetPasswordPage({
  carrierName,
  email,
  activationCode,
}: Props) {
  const { setPassword } = useDriversAPI();
  const isMobile = useResponsiveValue(true, false);

  const formik = useAppFormik({
    enableReinitialize: false,
    initialValues: { password: '' },
    validationSchema,
    onSubmit: (values) => {
      return setPassword(activationCode, values.password);
    },
    onSubmitSuccess: (
      response: APIResponse<DriverActivationDetailsDTO> & {
        url: string | null;
      },
    ) => {
      if (response.url) {
        openExternalURL(response.url, { target: '_self' });
      }
    },
  });

  const passwordstrength = useMemo(
    () => validatePasswordStrength(formik.values.password),
    [formik.values.password],
  );

  return (
    <Box
      maxWidth="432px"
      marginLeft="auto"
      marginRight="auto"
      marginTop={['none', 'large']}
    >
      <Card square={isMobile}>
        <Box padding={['small', 'medium']}>
          <FormikProvider value={formik}>
            <Form aria-label="forgot password">
              <Stack space="large">
                <Stack space="small">
                  <Typography variant="h2">Set Password</Typography>

                  <Typography>
                    Set a password for the Super Dispatch app to access loads
                    from <b>{carrierName}</b>
                  </Typography>
                </Stack>

                <TextField
                  label="Mobile App Login"
                  value={email}
                  disabled={true}
                  fullWidth={true}
                />

                <Stack space="xsmall">
                  <FormikPasswordField
                    label="Password"
                    name="password"
                    fullWidth={true}
                    autoFocus={true}
                  />

                  <PasswordValidationStepper
                    passwordstrength={passwordstrength}
                    value={formik.values.password}
                  />
                </Stack>

                <Button
                  type="submit"
                  size="large"
                  fullWidth={true}
                  pending={formik.isSubmitting}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  Set Password
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Card>

      <Box margin="small">
        <Typography color="textSecondary" align="center">
          By creating account you agree to Super Dispatch’s{' '}
          <Link href="https://superdispatch.com/terms-of-service">
            Terms &amp; Conditions
          </Link>{' '}
          and{' '}
          <Link href="https://superdispatch.com/privacy-policy/">
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
