// eslint-disable-next-line import/no-internal-modules
import 'mapbox-gl/dist/mapbox-gl.css';
import { forwardRef, useEffect, useRef } from 'react';
import Map, { MapProps, MapRef } from 'react-map-gl';
import { MAPBOX_ACCESS_TOKEN } from 'shared/geo/Mapbox';
import { useAppThemeMode } from 'shared/theme/AppThemeProvider';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const MAP_STYLE = {
  light: 'mapbox://styles/mapbox/streets-v9',
  dark: 'mapbox://styles/mapbox/navigation-night-v1',
};

export const MapboxMap = forwardRef<
  MapRef,
  Omit<
    MapProps,
    'accessToken' | 'mapboxAccessToken' | 'mapStyle' | 'projection'
  >
>((props, ref) => {
  const mapRef = useRef<MapRef | null>(null);
  const theme = useAppThemeMode();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !props.trackResize) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      mapRef.current?.resize();
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [mapRef, props.trackResize]);

  return (
    <Container ref={containerRef}>
      <Map
        accessToken={MAPBOX_ACCESS_TOKEN}
        ref={(node) => {
          mapRef.current = node;
          if (typeof ref === 'function') ref(node);
          else if (ref) ref.current = node;
        }}
        onMoveEnd={(event) => {
          // It is done due to a bug in react-map-gl
          // https://github.com/mapbox/mapbox-gl-js/issues/8982
          mapRef.current?.resize();
          props.onMoveEnd?.(event);
        }}
        mapboxAccessToken={
          'Cypress' in window ? undefined : MAPBOX_ACCESS_TOKEN
        }
        mapStyle={MAP_STYLE[theme]}
        {...props}
      />
    </Container>
  );
});

MapboxMap.displayName = 'MapboxMap';
