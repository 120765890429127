import { trackEvent } from 'shared/helpers/Analytics';
import { formatLoadStage, LoadStage, LoadStatus } from './LoadDTO';
import {
  LoadOrderByField,
  LoadSearchField,
  LoadsPageParams,
} from './LoadsPageParams';

export type LoadsPageType = 'loads_list' | 'view_load';

export type LoadsAnalyticsEvent =
  | {
      name: 'Carrier Assigned Driver';
    }
  | { name: 'Carrier Clicked Add Driver Button Drawer' }
  | { name: 'Carrier Clicked Invite Driver Button Drawer' }
  | { name: 'Carrier Clicked Upgrade Plan' }
  | { name: 'Carrier Closed Upgrade Plan Dialog' }
  | {
      name: 'Carrier Clicked Video Inspection';
    }
  | { name: 'Carrier Copied Load ID' }
  | {
      name: 'CTMS: Sorted Loads';
      field: LoadOrderByField;
    }
  | {
      name: 'CTMS: Searched Loads';
      field: LoadSearchField;
      value: unknown;
    }
  | { name: 'CTMS: Filtered Loads'; params: Partial<LoadsPageParams> }
  | { name: 'CTMS: Clicked Add Loads Filter'; params: LoadsPageParams }
  | { name: 'CTMS: Clicked Loads Tab'; tab: string }
  | { name: 'Carrier Clicked Create Load' }
  | { name: 'Carrier Clicked Save & Continue Editing' }
  | { name: 'CTMS: Clicked Shipper Name'; page: LoadsPageType }
  | {
      name: 'Carrier Clicked Edit Load';
      page: LoadsPageType;
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
    }
  | {
      name: 'Carrier Deleted Load';
      page: LoadsPageType;
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
    }
  | { name: 'CTMS: Restored Load'; page: LoadsPageType }
  | {
      name: 'Carrier Internal Note Added';
      page: LoadsPageType;
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
    }
  | {
      name: 'Carrier Internal Note Deleted';
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
    }
  | { name: 'CTMS: Load Archived'; page: LoadsPageType }
  | { name: 'CTMS: BOL Sent'; page: LoadsPageType }
  | {
      name: 'CTMS: Clicked View BOL';
      page: LoadsPageType;
      utm_medium?: string;
      status?: LoadStatus;
    }
  | { name: 'CTMS: View Invoice Clicked' }
  | {
      name: 'CTMS: Send Invoice Clicked';
      page: LoadsPageType;
      load_stage?: LoadStage;
    }
  | { name: 'CTMS: Marked as Picked Up'; page: LoadsPageType }
  | { name: 'CTMS: Marked as Delivered'; page: LoadsPageType }
  | { name: 'CTMS: Marked as New'; page: LoadsPageType }
  | { name: 'CTMS: Marked as Paid'; page: LoadsPageType }
  | { name: 'CTMS: Terminal Added'; page: LoadsPageType }
  | {
      name: 'Carrier Clicked Split Order';
      page: LoadsPageType;
      utm_medium: string;
      is_created_by_broker: boolean;
    }
  | {
      name: 'Carrier Load Duplicated';
      page: LoadsPageType;
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
    }
  | { name: 'Carrier Viewed Security Maintenance Banner' }
  | { name: 'Carrier Clicked Confirm Information' }
  | { name: 'CTMS: Dispatched To Carrier'; page: LoadsPageType }
  | { name: 'CTMS: Search for Similar Loads Clicked'; page: LoadsPageType }
  | { name: 'CTMS: Driver Assigned'; page: LoadsPageType }
  | { name: 'CTMS: Driver Unassigned'; page: LoadsPageType }
  | { name: 'CTMS: Driver Reassigned'; page: LoadsPageType }
  | { name: 'CTMS: View Load Page Opened'; referrer_button: 'load_id' | 'edit' }
  | { name: 'CTMS: Load Print Clicked' }
  | { name: 'CTMS: Opened Print Load Page' }
  | { name: 'CTMS: Pressed Manual Print Load Page' }
  | { name: 'CTMS: Clicked Add Vehicle' }
  | { name: 'CTMS: Clicked Delete Vehicle'; page: 'view_load' | 'edit_load' }
  | { name: 'CTMS: Expense Added' }
  | { name: 'CTMS: Customer Added' }
  | { name: 'CTMS: Customer Edited' }
  | { name: 'CTMS: Pickup Added' }
  | { name: 'CTMS: Pickup Edited' }
  | { name: 'CTMS: Delivery Added' }
  | { name: 'CTMS: Delivery Edited' }
  | { name: 'CTMS: Instructions Added' }
  | { name: 'CTMS: Selected Other Expense Type' }
  | { name: 'CTMS: Added Other Expense Type Description' }
  | { name: 'CTMS: Previewed BOL In Send BOL'; page: LoadsPageType }
  | {
      name: 'CTMS: Viewed Load';
      loadGuid: string;
      utm_source?: string;
      utm_medium?: string;
    }
  | {
      name: 'SLB: Viewed Load';
      guid: string;
      payment?: string;
      price: number;
      utm_medium: string;
      'tab name': string;
      'delivery state': string | undefined | null;
      'delivery state and city': string;
      'is exclusive': 'yes' | 'no' | undefined;
      'pickup state': string | undefined | null;
      'pickup state and city': string;
      'posted date': string | undefined | null;
      'posting guid': string | null;
      'price per mile': number | null;
      'shipper guid': string;
    }
  | {
      name: 'Carrier Created Load';
      is_load_details_added: boolean;
      is_instructions_added: boolean;
      is_pickup_information_added: boolean;
      is_delivery_information_added: boolean;
      is_vehicles_added: boolean;
      is_customer_information_added: boolean;
      is_payment_information_added: boolean;
      is_attachments_added: boolean;
      saved_by: 'Save Button' | 'Save and Continue Editing Button';
    }
  | {
      name: 'Carrier Edited Load';
      utm_medium: string;
      load_status: string;
      is_created_by_broker: boolean;
      load_details?: 'Added' | 'Edited';
      pickup_information?: 'Added' | 'Edited';
      delivery_information?: 'Added' | 'Edited';
      customer_information?: 'Added' | 'Edited';
      payment_information?: 'Added' | 'Edited';
      attachments?: 'Added' | 'Edited';
      expenses?: 'Added' | 'Edited';
      instructions?: 'Added' | 'Edited';
      vehicles?: 'Added' | 'Edited';
      utm_content: 'Edit Page' | 'View Page';
    }
  | {
      name: 'Carrier Opened Send Invoice Drawer';
      carrier_requested_payment_via_superpay: boolean;
    }
  | {
      name: 'Carrier Clicked Preview Invoice';
    }
  | {
      name: 'Carrier Sent Invoice';
      invoice_type: 'combined' | 'single';
      factor: boolean;
      sending_method: string[];
      bol_template: string;
      is_attachment_added: boolean;
      payment_method: string;
      carrier_requested_payment_via_superpay: boolean;
    }
  | {
      name: 'Carrier Clicked Try Again';
      sending_method: 'email' | 'qbo' | 'qbd';
    }
  | {
      name: 'Carrier Clicked Non-vehicle Photo Issues';
      page: 'loads_list' | 'view_load';
    }
  | {
      name: 'Carrier Viewes Sell Invoice Button Once';
    }
  | {
      name: 'Carrier Viewes Sell Invoice Button';
    }
  | {
      name: 'Carrier Clicked Sell Invoice Button';
    }
  | {
      name: 'Carrier Closed Upgrade Warning Dialog';
    }
  | {
      name: 'Carrier Clicked Upgrade Warning Button';
    };

function toCSV(iterable: Iterable<string>): string | undefined {
  const values = Array.from(iterable);

  return !values.length
    ? undefined
    : values.sort((a, b) => a.localeCompare(b)).join(', ');
}

function formatParams(
  params: LoadsPageParams,
  prefix?: string,
): Record<string, unknown> {
  const usedFilters: string[] = [];
  const result: Record<string, unknown> = {};

  for (let [filter, value] of Object.entries(params) as Array<
    [string, unknown]
  >) {
    if (Array.isArray(value)) {
      value = !value.length ? undefined : toCSV(value);
    }

    if (value == null || value === '') {
      value = undefined;
    }

    if (value != null) {
      result[prefix == null ? filter : `${prefix}_${filter}`] = value;

      if (filter !== 'page' && filter !== 'stage') {
        usedFilters.push(filter);
      }
    }
  }

  result.used_filters = toCSV(usedFilters);

  return result;
}

function extractProps(event: LoadsAnalyticsEvent): Record<string, unknown> {
  switch (event.name) {
    case 'CTMS: Clicked Add Loads Filter': {
      return formatParams(event.params, 'filter');
    }

    default: {
      const { name, ...props } = event;

      // Workaround for:
      //
      // TS2322: Type '{ tab: string; }' is not assignable to type 'Record<string, unknown>'.
      // Index signature is missing in type '{ tab: string; }'.
      return { ...props };
    }
  }
}
export function trackLoadsEvent(event: LoadsAnalyticsEvent) {
  const props = extractProps(event);

  trackEvent(event.name, props);
}

export function formatStageMedium(stage?: LoadStage) {
  if (!stage) {
    return 'New';
  }

  if (stage === 'active') {
    return 'Search Results';
  }

  return formatLoadStage(stage);
}
