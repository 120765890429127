import { FormikErrors, FormikValues } from 'formik';
import { set } from 'lodash-es';
import { isAPIError } from 'shared/api/APIError';

export function defaultFormErrorsGetter<TValues extends FormikValues>(
  error: unknown,
): FormikErrors<TValues> {
  const errors: FormikErrors<TValues> = {};

  if (isAPIError(error)) {
    const { context } = error;

    if (typeof context == 'object' && context != null) {
      for (const [key, value] of Object.entries(context)) {
        set(errors, key, Array.isArray(value) ? value[0] : value);
      }
    }
  }

  return errors;
}
