import { Color } from '@superdispatch/ui';
import { createSvgIcon } from './IconUtils';

export const LockIcon = createSvgIcon(
  'LockIcon',
  <path
    d="M11.4997 21.6667C12.233 21.6667 12.8608 21.4056 13.383 20.8833C13.9052 20.3611 14.1663 19.7333 14.1663 19C14.1663 18.2667 13.9052 17.6389 13.383 17.1167C12.8608 16.5944 12.233 16.3333 11.4997 16.3333C10.7663 16.3333 10.1386 16.5944 9.61634 17.1167C9.09412 17.6389 8.83301 18.2667 8.83301 19C8.83301 19.7333 9.09412 20.3611 9.61634 20.8833C10.1386 21.4056 10.7663 21.6667 11.4997 21.6667ZM3.49967 28.3333C2.76634 28.3333 2.13856 28.0722 1.61634 27.55C1.09412 27.0278 0.833008 26.4 0.833008 25.6667V12.3333C0.833008 11.6 1.09412 10.9722 1.61634 10.45C2.13856 9.92777 2.76634 9.66666 3.49967 9.66666H12.833V7C12.833 5.15555 13.483 3.58333 14.783 2.28333C16.083 0.983328 17.6552 0.333328 19.4997 0.333328C21.3441 0.333328 22.9163 0.983328 24.2163 2.28333C25.5163 3.58333 26.1663 5.15555 26.1663 7H23.4997C23.4997 5.88888 23.1108 4.94444 22.333 4.16666C21.5552 3.38888 20.6108 2.99999 19.4997 2.99999C18.3886 2.99999 17.4441 3.38888 16.6663 4.16666C15.8886 4.94444 15.4997 5.88888 15.4997 7V9.66666H19.4997C20.233 9.66666 20.8608 9.92777 21.383 10.45C21.9052 10.9722 22.1663 11.6 22.1663 12.3333V25.6667C22.1663 26.4 21.9052 27.0278 21.383 27.55C20.8608 28.0722 20.233 28.3333 19.4997 28.3333H3.49967Z"
    fill={Color.Blue300}
  />,
  {
    viewBox: '0 0 27 29',
  },
);
