import { DateTime } from 'luxon';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import {
  yupBoolean,
  yupDateString,
  yupNumber,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CargoInsuranceDTO = InferType<typeof cargoInsuranceSchema>;
export const cargoInsuranceSchema = yupObject({
  guid: yupString(),
  is_verified: yupBoolean(),
  is_current: yupBoolean(),
  file_url: yupString().required(),
  expiration_date: yupDateString('DateISO')
    .required('Enter Expiration Date')
    .test(
      'validate-date',
      'Expiration Date must be in future',
      (value) =>
        !!value &&
        DateTime.fromJSDate(new Date(value)) > DateTime.local().startOf('day'),
    ),
  cargo_limit: yupNumber()
    .positive('Value must be greater than zero')
    .required('Enter Cargo Limit'),
  deductible_amount: yupNumber()
    .min(0, 'Deductible can not be negative')
    .required('Enter Deductible Amount'),
  auto_policy_number: yupString().max(200),
  cargo_policy_number: yupString().max(200),
  agent_name: yupString().max(200).required('Enter Name'),
  agent_phone: yupPhone().required('Enter Phone'),
  agent_email: yupString().max(200).email().required('Enter Email'),
});

export interface TermsAndConditionsDTO {
  terms_and_conditions: string;
}

export type W9DTO = InferType<typeof w9Schema>;
export const w9Schema = yupObject({
  w9_form_url: yupString().required(),
});

export type USDOTDTO = InferType<typeof USDOTSchema>;
export const USDOTSchema = yupObject({
  usdot_certificate_url: yupString().required(),
});

export type TransporterPlateRegistrationDTO = InferType<
  typeof transporterPlateRegistrationSchema
>;
export const transporterPlateRegistrationSchema = yupObject({
  file_url: yupString().required(),
});

export type StateMotorCarrierPermitDTO = InferType<
  typeof transporterPlateRegistrationSchema
>;
export const stateMotorCarrierPermitSchema = yupObject({
  file_url: yupString().required(),
});

export type BusinessLicenseDTO = InferType<typeof businessLicenseSchema>;
export const businessLicenseSchema = yupObject({
  file_url: yupString().required(),
});

export type BillingInfoDTO = InferType<typeof billingInfoSchema>;
export const billingInfoSchema = yupObject({
  billing_contact_name: yupString(),
  billing_email: yupString().email(),
  billing_phone_numbers: yupString(),
});

export type ACHPaymentInfoDTO = InferType<typeof ACHPaymentInfoSchema>;
export const ACHPaymentInfoSchema = yupObject({
  bank_name: yupString().required('Please fill out the Bank Name field'),
  bank_routing_number: yupString()
    .required()
    .length(9, 'Routing number must have 9 digits')
    .test('only digits', 'Invalid routing number', hasOnlyDigits),
  bank_account_number: yupString()
    .required()
    .min(7, 'Account number must have more than 6 digits')
    .test('only digits', 'Invalid bank account number', hasOnlyDigits),
  voided_check_url: yupString().required(),
});
