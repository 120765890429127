import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { Help, WarningRounded } from '@material-ui/icons';
import {
  FormattedDate,
  NullableDateString,
  parseDate,
} from '@superdispatch/dates';
import {
  Color,
  Column,
  Columns,
  Image,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { LinkButton } from 'shared/routing/Links';
import styled from 'styled-components';
import microDepositIllustration from '../../assets/micro_deposit_illustration.png';
import microDepositIllustrationDark from '../../assets/micro_deposit_illustration_dark.png';

const ExpirationText = styled(Typography)`
  color: ${Color.Yellow300};
`;

interface Props {
  bankName?: string | null;
  bankAccountDisplayNumber?: string | null;
  bankAccountDateSent?: NullableDateString;
  bankAccountAvailableDate: NullableDateString;
  hasAccountFeature?: boolean;
  isVerificationAvailable: boolean;
  path: string;
}

export function BankVerification({
  bankAccountDisplayNumber,
  bankName,
  bankAccountDateSent,
  hasAccountFeature,
  isVerificationAvailable,
  bankAccountAvailableDate,
  path,
}: Props) {
  const { showLauncherMessanger } = useCustomerSupport();
  const expirationDate = useMemo(() => {
    const accountInitiatedDate = parseDate(bankAccountDateSent, {
      format: 'DateISO',
    });
    return accountInitiatedDate.plus({ days: 14 });
  }, [bankAccountDateSent]);

  const isMobile = useResponsiveValue(true, false);

  return (
    <Stack space="small">
      <Inline horizontalAlign="right" verticalAlign="center" space="xxsmall">
        <Help fontSize="small" color="action" />
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://support.superdispatch.com/hc/articles/35595408017939-How-To-Setup-SuperPay-In-Carrier-TMS"
        >
          How to set up SuperPay
        </Link>
      </Inline>
      <Card aria-label="superpay bank verification banner">
        <CardContent>
          {isVerificationAvailable ? (
            <Stack space="small">
              <Columns space="large">
                <Column>
                  <Stack space="small">
                    <Columns space="small">
                      <Column>
                        <Typography variant="h4">
                          Ready for Bank Account Verification
                        </Typography>
                      </Column>

                      {isMobile && (
                        <Column width="content">
                          <Box width="90px" height="52px">
                            <Image
                              src={microDepositIllustration}
                              srcDark={microDepositIllustrationDark}
                              width="100%"
                            />
                          </Box>
                        </Column>
                      )}
                    </Columns>

                    <Box maxWidth={['75%', 'initial', 'initial']}>
                      <Stack space="small">
                        <Typography>
                          Verify the three transactions appeared in{' '}
                          <b>{bankName || 'your account'}</b>{' '}
                          {bankAccountDisplayNumber && (
                            <>
                              {' '}
                              ending in <b>{bankAccountDisplayNumber}</b>
                            </>
                          )}
                          .
                        </Typography>
                        <Typography>
                          When you have received three transactions to your bank
                          account, click the Verify Bank Account button below.
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Column>

                {!isMobile && (
                  <Column width="content">
                    <Image
                      src={microDepositIllustration}
                      srcDark={microDepositIllustrationDark}
                      width="109px"
                    />
                  </Column>
                )}
              </Columns>
              <Box
                padding="small"
                borderRadius="small"
                backgroundColor="Yellow50"
              >
                <Inline verticalAlign="center">
                  <WarningRounded
                    fontSize="small"
                    htmlColor={Color.Yellow300}
                  />
                  <ExpirationText>
                    Deposits expire on&nbsp;
                    <FormattedDate variant="ShortDate" date={expirationDate} />
                  </ExpirationText>
                </Inline>
              </Box>
              {!hasAccountFeature && (
                <Typography>
                  To verify bank account, you need to have the accounting
                  feature enabled. Contact manager to resolve the issue.
                </Typography>
              )}

              <LinkButton
                to={`${path}/verify-bank-account?utm_medium=SuperPay Settings`}
                disabled={!hasAccountFeature}
              >
                Verify Bank Account
              </LinkButton>

              <Typography variant="caption" color="textSecondary">
                <Link
                  onClick={() => {
                    showLauncherMessanger();
                  }}
                  component="button"
                >
                  Contact support
                </Link>
                &nbsp;if transactions haven’t appeared or you want to change
                bank account.
              </Typography>
            </Stack>
          ) : (
            <Stack space="small">
              <Columns space="large">
                <Column>
                  <Stack space="small">
                    <Columns space="small">
                      <Column>
                        <Typography variant="h4">
                          Deposits should arrive by{' '}
                          <b>
                            <FormattedDate
                              variant="ShortDate"
                              date={bankAccountAvailableDate}
                            />
                          </b>
                        </Typography>
                      </Column>

                      {isMobile && (
                        <Column width="content">
                          <Box width="90px" height="52px">
                            <Image
                              src={microDepositIllustration}
                              srcDark={microDepositIllustrationDark}
                              width="100%"
                            />
                          </Box>
                        </Column>
                      )}
                    </Columns>

                    <Box maxWidth={['75%', 'initial', 'initial']}>
                      <Stack space="small">
                        <Typography>
                          We are sending three transactions to{' '}
                          <b>{bankName || 'your account'}</b>{' '}
                          {bankAccountDisplayNumber && (
                            <>
                              {' '}
                              ending in <b>{bankAccountDisplayNumber}</b>
                            </>
                          )}
                          .
                        </Typography>
                        <Typography>
                          Please wait until the transactions appear.
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Column>

                {!isMobile && (
                  <Column width="content">
                    <Image
                      src={microDepositIllustration}
                      srcDark={microDepositIllustrationDark}
                      width="109px"
                    />
                  </Column>
                )}
              </Columns>

              {!hasAccountFeature && (
                <Typography>
                  To verify bank account, you need to have the accounting
                  feature enabled. Contact manager to resolve the issue.
                </Typography>
              )}
              <Stack space="xsmall">
                <LinkButton
                  to={`${path}/verify-bank-account?utm_medium=SuperPay Settings`}
                  disabled={true}
                >
                  Verify Bank Account
                </LinkButton>

                <Inline verticalAlign="center">
                  <WarningRounded
                    fontSize="small"
                    htmlColor={Color.Yellow300}
                  />
                  <ExpirationText variant="caption">
                    The Verify Bank Account button will be available by the end
                    of&nbsp;
                    <FormattedDate
                      variant="ShortDate"
                      date={bankAccountAvailableDate}
                    />
                    .
                  </ExpirationText>
                </Inline>
              </Stack>

              <Typography variant="caption" color="textSecondary">
                <Link
                  onClick={() => {
                    showLauncherMessanger();
                  }}
                  component="button"
                >
                  Contact support
                </Link>
                &nbsp;if transactions haven’t appeared or you want to change
                bank account.
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
}
