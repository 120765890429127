import {
  Card,
  CardContent,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { CardButton, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { useSubscriptionDetails } from 'shared/modules/subscription/SubscriptionAPI';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';

const IconButton = styled(MuiIconButton)`
  padding: 4px;
`;
const CardNumber = styled.span`
  letter-spacing: 0.5px;
  vertical-align: baseline;
`;

export function SubscriptionPaymentInformation() {
  const navigate = useNavigate();
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const cardInformation = subscriptionDetails?.customer?.payment_method;
  const card = cardInformation?.card;
  const canReactivate =
    subscriptionDetails?.subscription?.plan?.name === 'lite' &&
    !!cardInformation;

  const billingAddress = subscriptionDetails?.customer?.billing_address;

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          {cardInformation && (
            <Stack space="xsmall">
              <TextBox variant="heading-3">Payment Information</TextBox>
              <Stack space="xsmall">
                <Columns align="center">
                  <Column>
                    <TextBox variant="heading-4">Card Details</TextBox>
                  </Column>
                  <Column>
                    <Inline horizontalAlign="right">
                      <IconButton
                        aria-label="edit card details"
                        onClick={() => {
                          navigate('/settings/subscription/edit_card_details');
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Inline>
                  </Column>
                </Columns>
                {card && (
                  <>
                    <Inline>
                      <TextBox color="secondary" variant="body">
                        Card Number:
                      </TextBox>
                      <CardNumber>{card.masked_number}</CardNumber>
                    </Inline>
                    <Inline>
                      <TextBox color="secondary" variant="body">
                        Expiration Date:
                      </TextBox>
                      <TextBox variant="body">
                        {card.expiry_month}/{card.expiry_year}
                      </TextBox>
                    </Inline>
                  </>
                )}
              </Stack>
            </Stack>
          )}

          {!!billingAddress && !doesBillingAddressNotExist && (
            <Stack space="xsmall">
              <Columns align="center">
                <Column>
                  <TextBox variant="heading-4">Billing Address</TextBox>
                </Column>
                <Column>
                  <Inline horizontalAlign="right">
                    <IconButton
                      onClick={() => {
                        navigate('/settings/subscription/edit_billing_address');
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Inline>
                </Column>
              </Columns>
              <Stack space="none">
                {billingAddress.address && (
                  <TextBox color="secondary">
                    {joinStrings(
                      ', ',
                      billingAddress.address + ' ' + billingAddress.city + ',',
                    )}
                  </TextBox>
                )}
                <TextBox color="secondary">
                  {joinStrings(
                    ', ',
                    billingAddress.state + ' ' + billingAddress.zip,
                    billingAddress.country,
                  )}
                </TextBox>
              </Stack>
            </Stack>
          )}

          {!billingAddress?.country && !doesBillingAddressNotExist ? (
            <Button
              onClick={() => {
                navigate('/settings/subscription/edit_billing_address', {
                  state: {
                    enableReactivation: canReactivate,
                    canAddBilling: true,
                  },
                });
              }}
              size="large"
              fullWidth={true}
            >
              Add Billing Address {canReactivate && '& Reactivate'}
            </Button>
          ) : canReactivate && !doesBillingAddressNotExist ? (
            <Button
              onClick={() => {
                navigate('/settings/subscription/reactivate_plan');
              }}
              fullWidth={true}
              size="large"
            >
              Reactivate
            </Button>
          ) : null}

          {doesBillingAddressNotExist && (
            <Stack space="small">
              <CardButton
                onClick={() => {
                  navigate('/settings/subscription/edit_billing_address', {
                    state: {
                      canAddBilling: true,
                    },
                  });
                }}
                startIcon={<Add />}
                size="small"
              >
                Billing Information
              </CardButton>
              <Alert severity="caution">
                Add your billing address so we can correctly calculate your tax.
              </Alert>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
