import { startCase } from 'lodash-es';
import {
  yupArray,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { LOAD_STATUSES } from '../../../carrier/loads/data/LoadDTO';

export type DelayedLoadsCountDTO = InferType<typeof delayedLoadsCountSchema>;
export const delayedLoadsCountSchema = yupObject({
  pickup: yupNumber().default(0),
  delivery: yupNumber().default(0),
  unassigned: yupNumber().default(0),
});

export type DriversCountDTO = InferType<typeof driversCountSchema>;
export const driversCountSchema = yupObject({
  available: yupNumber().default(0),
  assigned: yupNumber().default(0),
  delivering: yupNumber().default(0),
  total: yupNumber().default(0),
});

export const DELAYED_LOAD_STAGES = ['pickup', 'delivery'] as const;
export const DRIVERS_STAGES = ['available', 'assigned', 'delivering'] as const;

export type DelayedLoadStage = (typeof DELAYED_LOAD_STAGES)[number];
export type DriverStage = (typeof DRIVERS_STAGES)[number];
export function formatDelayedLoadStage(stage: DelayedLoadStage): string {
  return startCase(stage);
}

export const delayedLoadSchema = yupObject({
  guid: yupString(),
  number: yupString(),
  pickup_city: yupString(),
  pickup_state: yupString(),
  delivery_city: yupString(),
  delivery_state: yupString(),
  delayed_days: yupNumber(),
  vehicles: yupArray(
    yupObject({
      make: yupString(),
      model: yupString(),
      year: yupNumber(),
    }),
  ),
});

export type DelayedLoadDTO = InferType<typeof delayedLoadSchema>;

export const dashboardPageParamsSchema = yupObject({
  q: yupString(),
  delayed_load_stage: yupString(),
  driver_status: yupString(),
  ratings_stage: yupEnum(['ratings_received', 'ratings_to_give']),
});

export type UnassignedLoadDTO = InferType<typeof unassignedLoadSchema>;
const unassignedLoadSchema = yupObject({
  guid: yupString(),
  number: yupString(),
  pickup_city: yupString(),
  pickup_state: yupString(),
  delivery_city: yupString(),
  delivery_state: yupString(),
  vehicles: yupArray(
    yupObject({
      year: yupNumber(),
      make: yupString(),
      model: yupString(),
    }),
  ).required(),
});
export type DashboardPageParams = InferType<typeof dashboardPageParamsSchema>;

export const availableDriverSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  phone: yupString(),
  email: yupString(),
  trailer_capacity: yupNumber().nullable(),
  connection_status: yupEnum([
    'pending',
    'connected',
    'rejected',
    'deactivated',
  ]),
  connection_type: yupEnum(['invite', 'request']),
});

export type AvailableDriverDTO = InferType<typeof availableDriverSchema>;

export const assignedLoadSchema = yupObject({
  guid: yupString(),
  number: yupString(),
  status: yupEnum(LOAD_STATUSES),
  pickup_city: yupString(),
  pickup_state: yupString(),
  delivery_city: yupString(),
  delivery_state: yupString(),
  vehicles: yupArray(
    yupObject({
      year: yupNumber(),
      make: yupString(),
      model: yupString(),
    }),
  ),
});

export type AssignedLoadDTO = InferType<typeof assignedLoadSchema>;

export const assignedDriverSchema = availableDriverSchema.shape({
  loads: yupArray(assignedLoadSchema),
});

export type AssignedDriverDTO = InferType<typeof assignedDriverSchema>;

export const deliveringDriverSchema = availableDriverSchema.shape({
  loads: yupArray(assignedLoadSchema),
});

export type DeliveringDriverDTO = InferType<typeof deliveringDriverSchema>;
export const driverSchema = availableDriverSchema.shape({
  status: yupEnum(['available', 'assigned', 'delivering']),
  loads: yupArray(assignedLoadSchema),
});

export type DriverDTO = InferType<typeof driverSchema>;

export function formatDriverStatus(input: unknown): string {
  switch (input as DriverDTO['status']) {
    case 'available':
      return 'Available';
    case 'assigned':
      return 'Assigned';
    case 'delivering':
      return 'Delivering';
    default:
      return 'Unknown';
  }
}
