import { formatPaymentTerm } from '@superdispatch/sdk';
import { TextBox } from '@superdispatch/ui-lab';
import { formatCarrierPaymentMethod } from 'shared/helpers/CarrierPaymentTypes';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { LoadDTO } from '../data/LoadDTO';

interface LoadPaymentInfoProps {
  paymentDetails: LoadDTO['payment_details'];
}

export function LoadPaymentInfo({ paymentDetails }: LoadPaymentInfoProps) {
  if (paymentDetails?.payment_method === 'superpay') {
    return (
      <TextBox color="secondary">
        <InlineBulletItems>
          <SuperPayLabel source="Load List" />
          {formatPaymentTerm(paymentDetails.payment_terms)}
        </InlineBulletItems>
      </TextBox>
    );
  }

  return (
    <TextBox color="secondary">
      <InlineBulletItems>
        {paymentDetails?.payment_method &&
          formatCarrierPaymentMethod(paymentDetails.payment_method)}
        {paymentDetails?.payment_terms &&
          `${formatPaymentTerm(paymentDetails.payment_terms)}`}
      </InlineBulletItems>
    </TextBox>
  );
}
