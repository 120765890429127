import { useEffect, useState } from 'react';
import {
  getMobileAppToken,
  MobileAppBridge,
} from 'shared/data/MobileAppBridge';
import { logError } from 'shared/helpers/ErrorTracker';
import { getCookie } from 'shared/helpers/StorageHelpers';
import { CookieStore, LocalStore, Store } from 'shared/helpers/Store';

const COOKIE_TOKEN_KEY = 'userToken';
const STORAGE_TOKEN_KEY = 'tms:token';
const STORAGE_ROLE_KEY = 'tms:role';
export const DRIVER_ACCOUNT_TOKEN = 'driver_account_token';

try {
  const searchParams = new URLSearchParams(window.location.search);
  const queryToken = searchParams.get('token');
  const queryRole = searchParams.get('role');

  migrateStorageTokenToCookieToken();

  if (queryToken) {
    updateAuthToken(queryToken);
    searchParams.delete('token');

    if (queryRole) {
      saveAuthRole(queryRole);
      searchParams.delete('role');
    }

    const nextSearch = searchParams.toString();

    if (!nextSearch) window.location.replace(window.location.pathname);
    else window.location.replace(`${window.location.pathname}?${nextSearch}`);
  }
} catch (error: unknown) {
  logError(error, 'AuthToken');
}

export function getAuthToken(): string | undefined {
  const mobileToken = getMobileAppToken();
  if (mobileToken) return mobileToken;

  const driverAccountToken = getDriverAccountToken();
  if (driverAccountToken) return driverAccountToken;

  const localStorageToken = LocalStore.get(STORAGE_TOKEN_KEY);
  if (localStorageToken) return localStorageToken;

  return CookieStore.get(COOKIE_TOKEN_KEY) || undefined;
}

export function useAuthToken(): string {
  const [_, rerender] = useState({});

  useEffect(() => {
    return Store.subscribe(() => {
      rerender({});
    });
  }, []);

  return getAuthToken() || '';
}

export function updateAuthToken(token: string): void {
  CookieStore.set(COOKIE_TOKEN_KEY, token);
}

const shouldSaveTokenLocally =
  import.meta.env.VITE_APP_TARGET === 'local' ||
  import.meta.env.VITE_APP_TARGET === 'preview';

// Backend cannot set Cookies on local and preview domains, so we need to save the token locally
export function updateAuthTokenLocally(token: string) {
  if (shouldSaveTokenLocally) {
    updateAuthToken(token);
  }
}

export function clearAuthToken(): void {
  LocalStore.delete(STORAGE_TOKEN_KEY);
  // Loadboard backend does not clear cookies, so we need to clear the cookie manually
  CookieStore.delete(COOKIE_TOKEN_KEY);
}

export function saveAuthRole(role: string | null): void {
  LocalStore.set(STORAGE_ROLE_KEY, role || null);
}

export function getAuthRole(): string | null {
  return LocalStore.get(STORAGE_ROLE_KEY);
}

export function getDriverAccountToken(): string | null {
  return LocalStore.get(DRIVER_ACCOUNT_TOKEN);
}

function migrateStorageTokenToCookieToken() {
  const localStorageToken = LocalStore.get(STORAGE_TOKEN_KEY);

  if (localStorageToken) {
    CookieStore.set(COOKIE_TOKEN_KEY, localStorageToken);
    LocalStore.delete(STORAGE_TOKEN_KEY);
  }
}

export const CARRIER_GUID = 'carrier_guid';

export function getCarrierGuid(): string | null {
  return getCookie(CARRIER_GUID) || MobileAppBridge.getCarrierGuid() || null;
}
