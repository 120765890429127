import { Outlet, RouteObject } from 'react-router-dom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DashboardLoadsPage } from '../dashboard-legacy/DashboardLoadsPage';
import { DashboardPage } from '../dashboard-legacy/DashboardPage';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { DashboardLoadsList } from './core/DashboardLoadsList';
import { Dashboard } from './Dashboard';
import { DashboardProvider } from './data/DashboardContext';

function DashboardPageLoader() {
  const shouldShowNewDashboard = useFlag('ctms_new_dashboard');

  if (shouldShowNewDashboard) {
    return <Dashboard />;
  }

  return <DashboardPage />;
}

function DashboardLoadsPageLoader() {
  const shouldShowNewDashboard = useFlag('ctms_new_dashboard');

  if (shouldShowNewDashboard) {
    return <DashboardLoadsList />;
  }

  return <DashboardLoadsPage />;
}

const DashboardOutlet = () => {
  return (
    <LoadsPageContextProvider>
      <LoadsContextProvider>
        <DashboardProvider>
          <Outlet />
        </DashboardProvider>
      </LoadsContextProvider>
    </LoadsPageContextProvider>
  );
};

export const dashboardRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <DashboardOutlet />,
    children: [
      {
        index: true,
        element: <DashboardPageLoader />,
      },
    ],
  },
  {
    path: '/dashboard/loads',
    element: (
      <LoadsPageContextProvider>
        <LoadsContextProvider>
          <DashboardLoadsPageLoader />
        </LoadsContextProvider>
      </LoadsPageContextProvider>
    ),
  },
];
