import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';
import { loginRoutes } from 'shared/auth/LoginRoutes';
import { VerifyAccountContainer } from 'shared/auth/VerifyAccountContainer';
import { ServerErrorBoundary } from 'shared/errors/ServerErrorBoundary';
import { TrialExpiredPage } from 'shared/errors/TrialExpiredPage';
import { useZendeskInitialize } from 'shared/helpers/zendesk/ZendeskInitialize';
import { ExperimentsContainer } from 'shared/modules/experiments/ExperimentContainer';
import { LoadOffersPageMobile } from 'shared/modules/load-offers/LoadOffersPageMobile';
import { LoadSuggestionsPageMobile } from 'shared/modules/load-suggestions/LoadSuggestionsPageMobile';
import { OnboardingWrapper } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { OnboardingInviteTeamStepPage } from 'shared/modules/onboarding/OnboardingInviteTeamStepPage';
import { OnboardingQuestionaireStep1Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep1Page';
import { OnboardingQuestionaireStep2Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep2Page';
import { OnboardingQuestionaireStep3Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep3Page';
import { OnboardingWelcomeStepPage } from 'shared/modules/onboarding/OnboardingWelcomeStepPage';
import { useZendeskUser } from 'shared/modules/zendesk/ZendeskData';
import { basePath } from 'shared/utils/RouterUtils';
import { CarrierOutlet } from './CarrierOutlet';
import { contactsRoutes } from './contacts/ContactsRoutes';
import { dashboardRoutes } from './dashboard/DashboardRoutes';
import { DispatcherInvitationPage } from './dispatchers/dispatcher-invitation/DispatcherInvitationPage';
import { dispatcherRoutes } from './dispatchers/DispatcherRoutes';
import { DriverAccountPage } from './driver-account/DriverAccount';
import { DriverAccountContainerPage } from './driver-account/DriverAccountContainerPage';
import { DriverVerifyAccountPage } from './driver-account/DriverAccountVerifyAuthPage';
import { DriverAccountWrapper } from './driver-account/DriverAccountWrapper';
import { DriverDeleteAccountCompletionPage } from './driver-account/DriverDeleteAccountCompletionPage';
import { DriverDeleteAccountConfirmationPage } from './driver-account/DriverDeleteAccountConfirmationPage';
import { DriverDeleteAccountPage } from './driver-account/DriverDeleteAccountPage';
import { DriverAccountDeletionLayout } from './drivers/driver-account-deletion/DriverAccountDeletionPage';
import { CompletionStepPage } from './drivers/driver-account-deletion/steps/CompletionStepPage';
import { ConfirmationStepPage } from './drivers/driver-account-deletion/steps/ConfirmationStepPage';
import { MainStepPage } from './drivers/driver-account-deletion/steps/MainStepPage';
import { QuestionnaireStepPage } from './drivers/driver-account-deletion/steps/QuestionnaireStepPage';
import { DriverActivationPage } from './drivers/driver-activation/DriverActivationPage';
import { DriverInvitationPage } from './drivers/driver-invitation/DriverInvitationPage';
import { driversRoutes } from './drivers/DriversRoutes';
import { loadsRoutes } from './loads/LoadsRoutes';
import { OffersPage } from './offers/offers/OffersPage';
import { PublicOfferPage } from './offers/public-offers/pages/PublicOfferPage';
import { PublicOfferPageContextProvider } from './offers/public-offers/PublicOfferPageContext';
import { profileRoutes } from './profile/ProfileRoutes';
import { reportsRoutes } from './reports/ReportsRoutes';
import { settingsRoutes } from './settings/SettingsRoutes';
import { SplitLoadsPage } from './split-loads/SplitLoadsPage';
import { TrackingContainerPage } from './tracking/TrackingContainer';
import { tripsRoutes } from './trips/TripsRoutes';

export const carrierAppRoutes: RouteObject[] = [
  ...loadsRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
  ...tripsRoutes,
  ...profileRoutes,
  ...contactsRoutes,
  ...driversRoutes,
  ...dispatcherRoutes,
  ...dashboardRoutes,
  {
    path: 'offers/:offerGUID?',
    element: <OffersPage />,
  },
  {
    path: 'suggested-loads-screen',
    element: <LoadSuggestionsPageMobile />,
  },
  {
    path: 'load-offers',
    element: <LoadOffersPageMobile />,
  },
  {
    path: 'tracking',
    element: <TrackingContainerPage />,
  },
  {
    path: 'loads-split/:loadGUID',
    element: <SplitLoadsPage />,
  },
  {
    path: 'ratings',
    element: <Navigate to={`/profile/overview${window.location.search}`} />,
  },
  {
    path: '*',
    element: <Navigate to="/loads" />,
  },
];

function SplitOrderRedirectPage() {
  const params = useParams<{ id: string }>();
  const { search } = useLocation();

  return <Navigate to={`/loads-split/${params.id}${search}`} />;
}

function ViewOffersRedirectPage() {
  const params = useParams<{ key: string }>();
  const { search } = useLocation();

  return <Navigate to={`/public-offer/${params.key}${search}`} />;
}

function RootOutlet() {
  useZendeskUser();
  useZendeskInitialize('Carrier_TMS');

  return <Outlet />;
}

export const router = createBrowserRouter(
  [
    {
      element: <RootOutlet />,
      children: [
        ...loginRoutes,
        {
          path: '/orders/:id/split',
          element: <SplitOrderRedirectPage />,
        },
        {
          path: '/offers/:key/view/*',
          element: <ViewOffersRedirectPage />,
        },
        {
          path: '/public-offer/:guid',
          element: (
            <PublicOfferPageContextProvider>
              <PublicOfferPage />
            </PublicOfferPageContextProvider>
          ),
        },
        {
          path: '/driver-invite/:invitationCode',
          element: <DriverInvitationPage />,
        },
        {
          path: '/driver-activation/:activationCode',
          element: <DriverActivationPage />,
        },
        {
          path: '/driver-account-deletion/:token',
          element: <DriverAccountDeletionLayout />,
          children: [
            { path: 'questionnaire', element: <QuestionnaireStepPage /> },
            { path: 'confirmation', element: <ConfirmationStepPage /> },
            { path: 'completion', element: <CompletionStepPage /> },
            { index: true, element: <MainStepPage /> },
          ],
        },
        {
          path: '/dispatcher-invite/:invitationCode',
          element: <DispatcherInvitationPage />,
        },
        {
          path: '/driver-login',
          element: <DriverAccountContainerPage />,
        },
        {
          path: '/driver-account',
          children: [
            {
              index: true,
              element: (
                <DriverAccountWrapper>
                  <DriverAccountPage />
                </DriverAccountWrapper>
              ),
            },
            {
              path: 'verify',
              element: (
                <VerifyAccountContainer>
                  <DriverVerifyAccountPage />
                </VerifyAccountContainer>
              ),
            },
            {
              path: 'delete',
              children: [
                {
                  index: true,
                  element: (
                    <DriverAccountWrapper>
                      <DriverDeleteAccountPage />
                    </DriverAccountWrapper>
                  ),
                },
                {
                  path: 'completion',
                  element: <DriverDeleteAccountCompletionPage />,
                },
                {
                  path: 'confirmation',
                  element: (
                    <DriverAccountWrapper>
                      <DriverDeleteAccountConfirmationPage />
                    </DriverAccountWrapper>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: '/onboarding/:page?',
          children: [
            { path: 'invite-team', element: <OnboardingInviteTeamStepPage /> },
            {
              path: 'questions-step-3',
              element: <OnboardingQuestionaireStep3Page />,
            },
            {
              path: 'questions-step-2',
              element: <OnboardingQuestionaireStep2Page />,
            },
            {
              path: 'questions-step-1',
              element: <OnboardingQuestionaireStep1Page />,
            },
            { index: true, element: <OnboardingWelcomeStepPage /> },
          ],
        },
        {
          path: '/trial-expired',
          element: <TrialExpiredPage />,
        },
        {
          element: (
            <ServerErrorBoundary>
              <ExperimentsContainer>
                <OnboardingWrapper>
                  <CarrierOutlet />
                </OnboardingWrapper>
              </ExperimentsContainer>
            </ServerErrorBoundary>
          ),
          children: carrierAppRoutes,
        },
      ],
    },
  ],

  {
    basename: basePath,
  },
);
