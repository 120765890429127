import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { FormikValues } from 'formik';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { VerifyFmcsaPhone } from 'shared/modules/carrier-profile/core/VerifyFmcsaPhoneDialog';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { ResubmitNonVerifiableApplicationDialog } from './ResubmitNonVerifiableApplicationDialog';
import { ResubmitVerificationDialog } from './ResubmitVerificationDialog';

const verificationDialogsAtom = atom<
  'resubmit' | 'resubmit-non-verifiable' | 'verify-fmcsa-phone' | undefined
>(undefined);

export function useSubmitVerificationDocument(
  formik: FormikContextTypeEnhanced<FormikValues, APIResponse>,
) {
  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const [_, setVerificationDialog] = useAtom(verificationDialogsAtom);

  return useCallback(() => {
    const application = verificationStatus?.verified_carrier_application;
    if (!application?.progress.fmcsa_phone_verification_is_complete) {
      setVerificationDialog('verify-fmcsa-phone');
    } else if (application.status === 'non_verifiable') {
      setVerificationDialog('resubmit-non-verifiable');
    } else if (
      application.status === 'pending' ||
      application.status === 'approved' ||
      application.status === 'reviewed'
    ) {
      setVerificationDialog('resubmit');
    } else {
      void formik.submitForm();
    }
  }, [verificationStatus, setVerificationDialog, formik]);
}

interface VerificationDialogsProps {
  formik: FormikContextTypeEnhanced<FormikValues, APIResponse>;
}

export function VerificationDialogs({ formik }: VerificationDialogsProps) {
  const [currentModal, setCurrentModal] = useAtom(verificationDialogsAtom);

  return (
    <>
      <VerifyFmcsaPhone
        isOpen={currentModal === 'verify-fmcsa-phone'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
      />

      <ResubmitVerificationDialog
        open={currentModal === 'resubmit'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onResubmit={() => {
          setCurrentModal(undefined);
          formik.handleSubmit();
        }}
      />

      <ResubmitNonVerifiableApplicationDialog
        open={currentModal === 'resubmit-non-verifiable'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onResubmit={() => {
          setCurrentModal(undefined);
          formik.handleSubmit();
        }}
      />
    </>
  );
}
