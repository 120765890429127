import { CircularProgress, ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Inline, VisibilityObserver } from '@superdispatch/ui';
import { useDeliveringDrivers } from '../data/DashboardAPI';
import { DashboardDriverListItem } from './DashboardDriverListItem';
import { DashboardDriversLoad } from './DashboardDriversLoad';

export function DeliveringDrivers() {
  const deliveringDrivers = useDeliveringDrivers();

  if (deliveringDrivers.isLoading) {
    return (
      <Inline horizontalAlign="center">
        <CircularProgress />
      </Inline>
    );
  }

  return (
    <>
      {deliveringDrivers.data?.pages.flatMap((page) =>
        page.data.map((driver) => (
          <DashboardDriverListItem
            key={driver.guid}
            driver={driver}
            loads={driver.loads?.map((load) => (
              <DashboardDriversLoad key={load.guid} load={load} />
            ))}
          />
        )),
      )}

      {deliveringDrivers.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (
              !deliveringDrivers.isFetchingNextPage &&
              visibility === 'visible'
            ) {
              void deliveringDrivers.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </>
  );
}
