import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import Info from '@mui/icons-material/Info';
import { ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { getCookie, setCookie } from 'shared/helpers/StorageHelpers';
import { CampaignIcon } from 'shared/icons/CampaignIcon';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { Notification } from 'shared/layout/notification-stack/NotificationStack';
import styled from 'styled-components';
import { trackDashboardEvent } from '../DashboardAnalytics';
import { useDashboardNotifications } from '../data/DashboardAPI';
import { StickyTopBox } from './StickyTopContainer';

const CARRIER_ISSUES_RESOLVED = 'carrier_issues_resolved';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 16px;

  > *:nth-child(2) {
    flex-grow: 1;
  }

  .MuiAlert-root {
    background-color: ${ColorDynamic.White};
    border-color: ${ColorDynamic.Silver400};
    color: ${ColorDynamic.Dark500};
  }
`;

function DashboardNotificationsContent() {
  const carrierIssuesResolvedState =
    getCookie(CARRIER_ISSUES_RESOLVED) || 'open';
  const { data, isLoading } = useDashboardNotifications({
    onSuccess: (response) => {
      if (response.length === 0 && carrierIssuesResolvedState === 'open') {
        trackDashboardEvent({
          name: 'Carrier Resolved All Attention Required Notifications',
        });
        setCookie(CARRIER_ISSUES_RESOLVED, 'close');
      }

      if (response.length > 0 && carrierIssuesResolvedState === 'close') {
        setCookie(CARRIER_ISSUES_RESOLVED, 'open');
      }
    },
  });

  if (isLoading) {
    return <ContentProgressIndicator />;
  }

  if (!data?.length) {
    return <EmptyNotifications />;
  }

  return (
    <Container>
      <Stack space="small">
        <Stack space="xxsmall">
          <Inline verticalAlign="center">
            <Typography variant="h3">Attention Required</Typography>
            <Tooltip
              placement="right"
              interactive={true}
              title="Issues that need to be solved for the uninterrupted operation of Carrier TMS."
            >
              <Info
                htmlColor={ColorDynamic.Dark100}
                fontSize="small"
                sx={{ fontSize: '16px' }}
              />
            </Tooltip>
          </Inline>
          <Inline space="xsmall" verticalAlign="center">
            <CampaignIcon fontSize="large" color="action" />
            <Typography variant="h1">{data.length}</Typography>
          </Inline>
        </Stack>
      </Stack>
      <StickyTopBox>
        <Stack>
          {data.map((notification, index) => (
            <Notification
              key={index}
              notification={notification}
              superPayVerificationStatus={undefined}
            />
          ))}
        </Stack>
      </StickyTopBox>
    </Container>
  );
}

function EmptyNotifications() {
  return (
    <Stack space="medium" align="center">
      <CampaignIcon fontSize="large" color="action" />
      <Stack align="center">
        <TextBox variant="heading-3">No issues found</TextBox>
        <Box maxWidth="200px">
          <TextBox color="secondary" align="center">
            Great! You don&apos;t have any issues.
          </TextBox>
        </Box>
      </Stack>
    </Stack>
  );
}

export function DashboardNotifications() {
  return (
    <Card>
      <CardContent>
        <Box
          height="416px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <DashboardNotificationsContent />
        </Box>
      </CardContent>
    </Card>
  );
}
