import { useCallback } from 'react';
import { useAPIQuery } from 'shared/api/APIQuery';
import { requestJSONLoadboardAPI } from 'shared/api/LoadboardAPIClient';
import {
  becomeVerifiedCarrier,
  BecomeVerifiedCarrierOptions,
} from 'shared/modules/loadboard/core/BecomVerifiedCarrier';
import {
  yupBoolean,
  yupEnum,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type LoadboardAccessDTO = InferType<typeof loadboardAccessSchema>;
export const loadboardAccessSchema = yupObject({
  carrier_guid: yupString(),
  is_in_private_network: yupBoolean(),
  access: yupEnum(['unlimited', 'limited']),
  verification_status: yupEnum([
    'not_submitted',
    'pending',
    'reviewed',
    'approved',
    'declined',
    'revoked',
    'non_verifiable',
  ]),
});

export function useLoadboardAccess() {
  return useAPIQuery<LoadboardAccessDTO>(
    ['loadboard', 'access'],
    () => requestJSONLoadboardAPI('GET /internal/v3/loadboard/access'),
    { schema: loadboardAccessSchema, staleTime: Infinity },
  );
}

export function useBecomeVerifiedCarrierNavigation() {
  const { data: access } = useLoadboardAccess();

  return useCallback(
    (options: BecomeVerifiedCarrierOptions) => {
      becomeVerifiedCarrier(access?.verification_status, {
        ...options,
      });
    },
    [access],
  );
}
