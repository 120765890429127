import { WarningRounded } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { LockIcon } from 'shared/icons/LockIcon';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { trackLoadboardEvent } from '../../data/LoadboardAnalytics';
import { useLoadboardAccess } from './LoadboardAccessAPI';
import { LoadboardAccessTimerIcon } from './LoadboardAccessTimerIcon';

const Wrapper = styled.div`
  cursor: pointer;
  color: ${ColorDynamic.White};
  border-radius: 4px;
  padding: 16px;
  background-color: ${ColorDynamic.Dark500};
`;

const YellowText = styled.span`
  color: ${ColorDynamic.Yellow300};
`;

const BlueText = styled.span`
  color: ${ColorDynamic.Blue500};
`;

const StyledLink = styled(WebViewLink)`
  && {
    &,
    &:active,
    &:focus {
      color: ${ColorDynamic.White};
      background-image: linear-gradient(
        to right,
        ${ColorDynamic.White} 0%,
        ${ColorDynamic.White} 100%
      );
    }
  }
`;

interface InfoItemProps {
  icon: ReactNode;
  children: ReactNode;
}

function InfoItem({ icon, children }: InfoItemProps) {
  return (
    <Inline
      aria-label="Access banner"
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Columns space={['small', 'xsmall']} align={['top', 'top', 'center']}>
        <Column width="content">{icon}</Column>

        <Column width="adaptive">{children}</Column>
      </Columns>
    </Inline>
  );
}

function PendingBanner() {
  return (
    <Wrapper>
      <InfoItem icon={<LoadboardAccessTimerIcon />}>
        <Inline space={['xsmall', 'small']} verticalAlign="center">
          <TextBox variant="heading-4" color="white" wrapOverflow={true}>
            Verified Carrier Status is <BlueText>Pending.</BlueText>
          </TextBox>

          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            href="https://support.superdispatch.com/hc/articles/35599561462931-Carrier-Verification-Pending-Status"
          >
            Learn More →
          </StyledLink>
        </Inline>
      </InfoItem>
    </Wrapper>
  );
}

interface LimitedAccessBannerProps {
  showApprovedCarrierSubtitle?: boolean;
  onClick?: () => void;
  href?: string;
  linkText?: string;
}
function LimitedAccessBanner({
  onClick,
  href,
  linkText,
  showApprovedCarrierSubtitle,
}: LimitedAccessBannerProps) {
  return (
    <Wrapper onClick={onClick}>
      <InfoItem
        icon={
          <WarningRounded fontSize="large" htmlColor={ColorDynamic.Yellow300} />
        }
      >
        <Inline space={['xsmall', 'small']} verticalAlign="center">
          <Stack space="none">
            <TextBox variant="heading-4" color="white" wrapOverflow={true}>
              Your Access to Super Loadboard is{' '}
              <YellowText>Limited.</YellowText>
            </TextBox>
            {showApprovedCarrierSubtitle && (
              <TextBox variant="caption" color="white" wrapOverflow={true}>
                You can still make Requests, or Can Instantly Book from shippers
                who have given you permission.
              </TextBox>
            )}
          </Stack>

          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            onClick={(event) => {
              if (onClick) {
                event.preventDefault();
              }
            }}
            href={href || '#'}
          >
            {linkText || 'Learn More →'}
          </StyledLink>
        </Inline>
      </InfoItem>
    </Wrapper>
  );
}

interface BecomeVerifiedBannerProps {
  onClick?: () => void;
  href?: string;
}

function BecomeVerifiedBanner({ onClick, href }: BecomeVerifiedBannerProps) {
  return (
    <Wrapper onClick={onClick}>
      <InfoItem icon={<LockIcon />}>
        <Inline space={['xsmall', 'small']} verticalAlign="center">
          <Stack space="none">
            <TextBox variant="heading-4" color="white" wrapOverflow={true}>
              Unlock Free Access to the Super Loadboard Now!{' '}
            </TextBox>
          </Stack>

          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            onClick={(event) => {
              if (onClick) {
                event.preventDefault();
              }
            }}
            href={href || '#'}
          >
            Become Verified Carrier →
          </StyledLink>
        </Inline>
      </InfoItem>
    </Wrapper>
  );
}

interface LoadboardAccessBannerProps {
  onClick: () => void;
  onVerifiedClick: () => void;
}

export function LoadboardAccessBanner({
  onClick,
  onVerifiedClick,
}: LoadboardAccessBannerProps) {
  const { data } = useLoadboardAccess();
  const isCarrierOnboardingEnabled = useFlag('ctms_onboarding');

  if (!data || data.access === 'unlimited') {
    return null;
  }

  const isPending = data.verification_status === 'pending';
  const isReviewed = data.verification_status === 'reviewed';
  const isNonVerifiable = data.verification_status === 'non_verifiable';
  const isNotSubmitted = data.verification_status === 'not_submitted';

  const isOnboardingExpirementEnabled =
    isCarrierOnboardingEnabled &&
    isNotSubmitted &&
    !MobileAppBridge.isInjected();

  return isPending ? (
    <PendingBanner />
  ) : isReviewed ? (
    <LimitedAccessBanner
      showApprovedCarrierSubtitle={true}
      href="https://support.superdispatch.com/hc/articles/35653827823507-Adding-Super-Dispatch-As-A-Certificate-Holder-on-Your-COI"
    />
  ) : isNonVerifiable ? (
    <LimitedAccessBanner
      showApprovedCarrierSubtitle={true}
      href="https://support.superdispatch.com/hc/articles/35661834835859-Why-can-t-my-documents-be-verified"
    />
  ) : isOnboardingExpirementEnabled ? (
    <BecomeVerifiedBanner
      onClick={() => {
        trackLoadboardEvent({
          name: 'SLB: Tapped Become Verified Carrier Banner',
        });
        onVerifiedClick();
      }}
    />
  ) : (
    <LimitedAccessBanner
      linkText="Become Verified Now →"
      onClick={() => {
        trackLoadboardEvent({
          name: 'SLB: Tapped Become Verified Carrier Banner',
        });
        onClick();
      }}
    />
  );
}
