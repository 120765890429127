import { Card, CardContent, Link, Slide, Typography } from '@material-ui/core';
import { Call, Edit, Email, Person } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Alert, Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useParams } from 'react-router-dom';
import { WeakPasswordIcon } from 'shared/icons/WeakPasswordIcon';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import {
  useClickedUpdatePasswordBanner,
  useIsClickedUpdatePassword,
} from 'shared/layout/notification-stack/data/PasswordBannerStorage';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { useIsDispatcherProfileVisited } from 'shared/layout/sidebar/IsDispatcherProfileVisited';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { LinkButton, LinkIconButton } from 'shared/routing/Links';
import { useBypassNavigationPrompt } from 'shared/routing/NavigationBlock';
import { useFlag } from 'shared/settings/FeatureToggles';
import { FloatingButtonArea } from 'shared/ui/FloatingButtonArea';
import { useDispatcherPasswordEnhancement } from '../data/DispatcherProfileSecurityEnhancement';
import { DispatcherEditDrawer } from './DispatcherEditDrawer';
import { DispatcherEditPasswordDrawer } from './DispatcherEditPasswordDrawer';

export function DispatcherProfilePage() {
  const navigate = useBypassNavigationPrompt();
  const { action } = useParams();
  const { data } = useDispatcherProfile();
  const isShowUpdatePasswordEnabled = useFlag('show_update_password');
  const { setClickedUpdatePassword } = useIsClickedUpdatePassword();
  const { isOpen, closeBanner } = useClickedUpdatePasswordBanner();
  useIsDispatcherProfileVisited();

  const closeDrawer = () => {
    navigate('/dispatchers/profile', { replace: true });
  };

  const closeUpdatePassword = () => {
    closeBanner();
  };

  const shouldShowPasswordReminder = useDispatcherPasswordEnhancement(data);

  return (
    <>
      <DispatcherEditDrawer open={action === 'edit'} onClose={closeDrawer} />

      <DispatcherEditPasswordDrawer
        open={action === 'change-password'}
        onClose={closeDrawer}
      />

      <PageLayout loading={!data} header={<PageHeader title="My Profile" />}>
        <Columns>
          <Column width="fluid">
            <Inline horizontalAlign="center">
              {!!data && (
                <Card>
                  <DocumentTitle title="Dispatchers" />

                  <FloatingButtonArea
                    buttonVisible={true}
                    button={
                      <LinkIconButton
                        aria-label="Edit Profile Link"
                        to="/dispatchers/profile/edit"
                      >
                        <Edit />
                      </LinkIconButton>
                    }
                  >
                    <CardContent>
                      <Stack space="small">
                        <Stack>
                          <Typography variant="h4">Profile Info</Typography>
                          <Stack space={['medium', 'small']}>
                            <DescriptionItem
                              wrap={true}
                              icon={<Person />}
                              fallback="Not Available"
                              aria-label="dispatcher name"
                            >
                              {data.name}
                            </DescriptionItem>

                            <DescriptionItem
                              icon={<Call />}
                              fallback="Not Available"
                            >
                              {data.phone && (
                                <SuspendedPhoneLink
                                  phone={data.phone}
                                  fallback={data.phone}
                                  aria-label="dispatcher phone"
                                />
                              )}
                            </DescriptionItem>
                          </Stack>
                        </Stack>

                        <Stack>
                          <Typography variant="h4">Account Info</Typography>

                          <DescriptionItem
                            icon={<Email />}
                            fallback="Not Available"
                          >
                            {data.email && (
                              <Link
                                href={`mailto:${data.email}`}
                                aria-label="dispatcher email"
                              >
                                {data.email}
                              </Link>
                            )}
                          </DescriptionItem>
                        </Stack>

                        <LinkButton
                          variant="neutral"
                          to="/dispatchers/profile/change-password"
                          onClick={() => {
                            setClickedUpdatePassword();
                            closeBanner();
                          }}
                        >
                          Change Password
                        </LinkButton>

                        <Slide
                          direction="up"
                          in={
                            !isOpen &&
                            isShowUpdatePasswordEnabled &&
                            shouldShowPasswordReminder
                          }
                          mountOnEnter={true}
                          unmountOnExit={true}
                          timeout={300}
                        >
                          <Alert
                            icon={<WeakPasswordIcon />}
                            severity="info"
                            onClose={closeUpdatePassword}
                          >
                            <Box width="260px">
                              <TextBox color="blue" wrapOverflow={true}>
                                Enhance account security. We recommend that you
                                update your password to meet the new standards.
                              </TextBox>
                            </Box>
                          </Alert>
                        </Slide>
                      </Stack>
                    </CardContent>
                  </FloatingButtonArea>
                </Card>
              )}
            </Inline>
          </Column>
        </Columns>
      </PageLayout>
    </>
  );
}
