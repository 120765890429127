import { startCase } from 'lodash-es';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { trackEvent } from 'shared/helpers/Analytics';
import { joinStrings } from 'shared/utils/StringUtils';
import {
  AcceptPublicOfferResponseDTO,
  PublicOfferDeclinePayloadDTO,
  PublicOfferDTO,
} from './PublicOfferDTO';

export type PublicOfferAnalyticsEvent =
  | {
      name: 'CTMS: Viewed Load Offer';
      guid: string;
      is_counter_offer: boolean | null;
    }
  | { name: 'CTMS: Assigned Load Offer' }
  | {
      name: 'Declined Load Offer';
      offer: PublicOfferDTO;
      payload: PublicOfferDeclinePayloadDTO;
    }
  | {
      name: 'Accepted Load Offer';
      offer: PublicOfferDTO;
      response: Pick<
        AcceptPublicOfferResponseDTO,
        'user_guid' | 'order_guid' | 'carrier_guid' | 'broker_order_guid'
      >;
    };

function formatOfferDetails(offer: PublicOfferDTO) {
  const { order } = offer;
  return {
    offer_price: order.price,
    vehicle_count: order.vehicles?.length,
    is_counter_offer: offer.is_counter_offer,
    is_price_negotiation: offer.is_price_negotiation,
    payment_type: offer.order.payment_method_display,
    payment_terms: offer.order.payment_terms_display,
    pickup_business_type: offer.order.pickup_business_type,
    delivery_business_type: offer.order.delivery_business_type,

    origin: formatAddressLine(
      order.pickup_zip,
      order.pickup_city,
      order.pickup_state,
    ),
    destination: formatAddressLine(
      order.delivery_zip,
      order.delivery_city,
      order.delivery_state,
    ),
    lane: joinStrings(
      ' -> ',
      joinStrings(', ', order.pickup_city, order.pickup_state),
      joinStrings(', ', order.delivery_city, order.delivery_state),
    ),
  };
}

export function trackPublicOfferEvent(event: PublicOfferAnalyticsEvent) {
  const baseParams = { is_public_offer: true };

  switch (event.name) {
    case 'Accepted Load Offer': {
      const { offer, response } = event;

      trackEvent(event.name, {
        ...baseParams,
        ...formatOfferDetails(offer),

        user_guid: response.user_guid,
        order_guid: response.order_guid,
        carrier_guid: response.carrier_guid,
        broker_order_guid: response.broker_order_guid,
        payment_type: offer.order.payment_method_display,
        payment_terms: offer.order.payment_terms_display,
      });

      break;
    }
    case 'Declined Load Offer': {
      const {
        offer,
        payload: { decline_comment, decline_reasons = [] },
      } = event;

      trackEvent(event.name, {
        ...baseParams,
        ...formatOfferDetails(offer),

        decline_reason: [...decline_reasons, decline_comment && 'Comment']
          .filter(Boolean)
          .map(startCase)
          .join(', '),
      });

      break;
    }
    default:
      trackEvent(event.name, baseParams);
  }
}
