import { Card, CardContent } from '@material-ui/core';
import { Column, Columns } from '@superdispatch/ui';
import { useSubscriptionDetails } from 'shared/modules/subscription/SubscriptionAPI';
import { SubscriptionPaymentInformation } from '../subscription/SubscriptionPaymentInformation';
import { SubscriptionPlanInformation } from './SubscriptionPlanInformation';

export function SubscriptionDetailsCard() {
  const { data: subscriptionDetails } = useSubscriptionDetails();

  if (!subscriptionDetails?.subscription?.plan) {
    return null;
  }

  return (
    <Columns space="small" collapseBelow="tablet">
      <Column>
        <SubscriptionPaymentInformation />
      </Column>
      <Column>
        <Card>
          <CardContent>
            <SubscriptionPlanInformation />
          </CardContent>
        </Card>
      </Column>
    </Columns>
  );
}
