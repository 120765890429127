import { useNavigate } from 'react-router-dom';
import { useBasename } from 'shared/helpers/RoutingHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';

export const { BASE_URL: basePath } = import.meta.env;
const isLocal: boolean = import.meta.env.VITE_APP_TARGET === 'local';

/**
 * Redirect to base path for local development
 */
export function redirectToBasePath() {
  if (!window.location.href.includes(basePath) && isLocal) {
    openExternalURL(basePath, { target: '_self' });
  }
}

export function useRedirectToLoadboardLoad() {
  const baseName = useBasename();
  const navigate = useNavigate();

  function redirectToLoadboardLoad(guid: string) {
    if (baseName.startsWith('/loadboard')) {
      navigate({
        pathname: '/loads',
        search: `?back_url=${window.location.pathname}${window.location.search}&current_load_guid=${guid}&utm_medium=Suggested Load`,
      });
    } else {
      openExternalURL(
        '/loadboard/loads/{?back_url,current_load_guid,utm_medium}',
        {
          back_url: window.location.pathname + window.location.search,
          current_load_guid: guid,
          utm_medium: 'Suggested Load',
          target: '_blank',
        },
      );
    }
  }

  return redirectToLoadboardLoad;
}
