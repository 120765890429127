import { formatCarrierPaymentMethod } from 'shared/helpers/CarrierPaymentTypes';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import { LoadDTO } from '../data/LoadDTO';
import { ViewLoadPaymentCardRow } from './ViewLoadPaymentRenderCardRow';

interface PaymentMethodProps {
  load: LoadDTO;
}

const defaultValuePlaceholder = 'No details';

export function ViewLoadPaymentMethod({ load }: PaymentMethodProps) {
  return (
    <ViewLoadPaymentCardRow
      title="Method"
      e2eValue="payment.payment_method"
      value={
        load.payment_details?.payment_method === 'superpay' ? (
          <SuperPayLabel source="Load Details" />
        ) : load.payment_details?.payment_method ? (
          formatCarrierPaymentMethod(load.payment_details.payment_method)
        ) : (
          defaultValuePlaceholder
        )
      }
    />
  );
}
