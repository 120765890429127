import { Typography } from '@material-ui/core';
import { Image, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import DriverAccountDeletedIcon from '../assets/DriverAccountDeletedIcon.svg';
import DriverAccountDeletedIconDark from '../assets/DriverAccountDeletedIconDark.svg';

const Wrapper = styled.div`
  padding: 24px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function CompletionStepPage() {
  return (
    <Wrapper>
      <Stack space="medium">
        <Box maxWidth="150px" margin="auto">
          <Image
            src={DriverAccountDeletedIcon}
            srcDark={DriverAccountDeletedIconDark}
            alt="Driver Account Deleted"
          />
        </Box>
        <Typography variant="h1" align="center">
          Your account has been deleted
        </Typography>
        <Typography align="center">
          You&apos;ve successfully deleted your Super Dispatch driver account.
          You can create a new one at anytime
        </Typography>
      </Stack>
    </Wrapper>
  );
}
