import { Divider } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { FormikNumberField } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import styled from 'styled-components';
import {
  useEstimateSeatsUpgrade,
  useEstimateTaxPrice,
  useSubscriptionBillableSeats,
  useSubscriptionDetails,
} from '../SubscriptionAPI';
import { SubscriptionSettingsDTO } from '../SubscriptionDTO';

const StyledNumberField = styled(FormikNumberField)`
  .MuiInputBase-root {
    background-color: ${ColorDynamic.White};
    min-width: 144px;
  }

  @media (max-width: 768px) {
    .MuiInputBase-root {
      min-width: 108px;
    }
  }

  .MuiInputBase-input {
    text-align: center;
  }
`;
const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    padding: 4px 0;
    font-size: 16px;
    min-width: 26px;
  }
`;

export function getPerDriverPrice(
  seatsCount: number,
  plan: NonNullable<SubscriptionSettingsDTO['plan']>,
) {
  const currentTier = plan.seats_pricing_tiers?.find(
    (tier) =>
      tier.starting_seat <= seatsCount && tier.ending_seat >= seatsCount,
  );

  //eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (!currentTier || !currentTier.price) {
    return Number(plan.price) / Number(plan.drivers_count);
  }

  return currentTier.price;
}

interface SeatBasedPricingBoxProps {
  isMinusDisabled: boolean;
  isReadOnly?: boolean;
}

export function SeatBasedPricingBox({
  isMinusDisabled,
  isReadOnly,
}: SeatBasedPricingBoxProps) {
  const [field, _, { setValue }] = useField<number>('seats_count');
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';
  const isSubscriptionInTrial =
    subscriptionDetails?.subscription?.status === 'in_trial';
  const isMobile = useResponsiveValue(true, false);
  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: isSubscriptionOnLitePlan
      ? subscriptionDetails?.upgrade_option?.plan?.measurement_type
      : subscriptionDetails?.subscription?.plan?.measurement_type,
  });

  const isEstimateInvoiceEnabled =
    (isSubscriptionInTrial || isSubscriptionOnLitePlan) &&
    !!billableSeats?.billable_seats;
  const { data: taxPrice } = useEstimateTaxPrice(
    {
      seats_count: field.value || 1,
      include_total_invoice: false,
    },
    {
      enabled: isEstimateInvoiceEnabled,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  const { data: estimateSeatsUpgrade } = useEstimateSeatsUpgrade(
    field.value || 1,
    {
      enabled: !isSubscriptionOnLitePlan && !isSubscriptionInTrial,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  const unitPrice =
    taxPrice?.plan?.unit_price || estimateSeatsUpgrade?.plan?.unit_price;
  const totalPrice =
    taxPrice?.plan?.amount || estimateSeatsUpgrade?.plan?.amount;

  const isMinusButtonDisabled = field.value <= 1 || isMinusDisabled;

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingLeft="medium"
      paddingRight="medium"
      backgroundColor="Silver200"
      borderRadius="small"
    >
      <Stack space="small">
        <Columns space="large" align="center">
          <Column width="content">
            <Box maxWidth="140px">
              <Stack space="xxsmall">
                <TextBox variant="heading-6">Pro</TextBox>
                <TextBox variant="caption" color="secondary">
                  {formatCurrency(unitPrice)} / driver / month
                </TextBox>
                <TextBox variant="caption" color="secondary">
                  dispatchers are free
                </TextBox>
              </Stack>
            </Box>
          </Column>
          <Column width="fluid">
            <Stack space="xsmall">
              <Columns space="xsmall" align="center" collapseBelow="desktop">
                <Column width="4/5">
                  <StyledNumberField
                    name="seats_count"
                    InputProps={{
                      readOnly: isReadOnly,
                      startAdornment: (
                        <StyledButton
                          onClick={() => {
                            void setValue(field.value - 1);
                          }}
                          variant="neutral"
                          size="small"
                          disabled={isMinusButtonDisabled}
                        >
                          <Remove
                            fontSize="inherit"
                            color={isMinusButtonDisabled ? 'inherit' : 'action'}
                          />
                        </StyledButton>
                      ),
                      endAdornment: (
                        <StyledButton
                          onClick={() => {
                            void setValue(field.value + 1);
                          }}
                          variant="neutral"
                          size="small"
                        >
                          <Add
                            fontSize="inherit"
                            htmlColor={ColorDynamic.Dark100}
                          />
                        </StyledButton>
                      ),
                    }}
                    inputProps={{
                      decimalScale: 0,
                      allowNegative: false,
                      allowLeadingZeros: false,
                    }}
                  />
                </Column>

                <Column width="content">
                  <TextBox variant="body-semibold" color="secondary">
                    {field.value > 1 ? 'drivers' : 'driver'}
                  </TextBox>
                </Column>
              </Columns>
              {!isMobile && (
                <TextBox variant="caption" color="secondary">
                  adjust the number if you need more
                </TextBox>
              )}
            </Stack>
          </Column>
        </Columns>
        {isMobile && (
          <TextBox variant="caption" color="secondary">
            adjust the number if you need more
          </TextBox>
        )}
        <Divider />

        <Inline space="small" verticalAlign="center" horizontalAlign="center">
          <Inline verticalAlign="center" space="xxsmall">
            <DriveawayIcon htmlColor={ColorDynamic.Dark100} />
            <TextBox variant="body-semibold" color="secondary">
              {field.value} driver {field.value > 1 ? 'seats' : 'seat'}
            </TextBox>
          </Inline>
          <Inline space="none" verticalAlign="center">
            <TextBox variant="heading-4">{formatCurrency(totalPrice)}</TextBox>
            <TextBox variant="body-semibold" color="secondary">
              /month
            </TextBox>
          </Inline>
        </Inline>
      </Stack>
    </Box>
  );
}
