import { Typography } from '@material-ui/core';
import { Image, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import microDepositExample from '../../assets/micro_deposit_example.png';
import microDepositExampleDark from '../../assets/micro_deposit_example_dark.png';
import microDepositIllustration from '../../assets/micro_deposit_illustration.png';
import microDepositIllustrationDark from '../../assets/micro_deposit_illustration_dark.png';

export function MicroDepositInitiatedContent() {
  return (
    <Stack space="large" align="center">
      <Image
        src={microDepositIllustration}
        srcDark={microDepositIllustrationDark}
        width="133px"
        alt="micro deposit illustration"
      />
      <Stack space="xsmall" align="center">
        <Stack space="none" align="center">
          <Typography variant="h2">Micro-deposits Initiated.</Typography>
          <Typography variant="h2">Please wait 1-2 business days.</Typography>
        </Stack>

        <Typography>
          You should expect <b>three small transactions</b> to appear in your
          bank account.
        </Typography>
      </Stack>
      <Box maxWidth="368px" margin="auto">
        <Image
          src={microDepositExample}
          srcDark={microDepositExampleDark}
          width="100%"
          alt="micro deposit example"
        />
      </Box>
    </Stack>
  );
}
