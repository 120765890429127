import {
  ACH_PAYMENT_METHODS,
  ALL_PAYMENT_METHODS,
  ALL_PAYMENT_TERMS,
  CASH_PAYMENT_METHODS,
  CHECK_PAYMENT_METHODS,
  CREDIT_CARD_PAYMENT_METHODS,
  ELECTRONIC_PAYMENT_METHODS,
  formatPaymentMethod,
  listPaymentTerms,
  LoadPaymentMethod,
  LOAD_PAYMENT_METHODS,
  LOAD_PAYMENT_TERMS,
  OTHER_PAYMENT_METHODS,
  PaymentMethod,
  SUPERPAY_PAYMENT_METHODS,
} from '@superdispatch/sdk';

export const LOAD_PAYMENT_TYPES = [
  ...LOAD_PAYMENT_METHODS.filter(
    (method): method is Exclude<LoadPaymentMethod, 'other'> =>
      method !== 'other',
  ),
  ...LOAD_PAYMENT_TERMS,
] as const;

// Carrier TMS mixed payment methods and terms. This constant holds all possible payment types.
export const PAYMENT_TERMS_AND_METHODS = [
  ...LOAD_PAYMENT_METHODS,
  ...LOAD_PAYMENT_TERMS,
  ...ALL_PAYMENT_METHODS,
  ...ALL_PAYMENT_TERMS,
] as const;

export type CarrierPaymentMethod = PaymentMethod | 'factoring';

export const CARRIER_PAYMENT_METHODS: CarrierPaymentMethod[] = [
  ...SUPERPAY_PAYMENT_METHODS,
  ...CASH_PAYMENT_METHODS,
  ...CHECK_PAYMENT_METHODS,
  ...ACH_PAYMENT_METHODS,
  ...CREDIT_CARD_PAYMENT_METHODS,
  ...ELECTRONIC_PAYMENT_METHODS,
  'factoring',
  ...OTHER_PAYMENT_METHODS,
];

export function formatCarrierPaymentMethod(
  method: unknown,
  options?: { fallback?: string },
) {
  if (method === 'factoring') {
    return 'Factoring';
  }

  return formatPaymentMethod(method, options);
}

export function listCarrierPaymentTerms(method: CarrierPaymentMethod) {
  if (method === 'factoring') {
    return ALL_PAYMENT_TERMS;
  }

  return listPaymentTerms(method);
}
