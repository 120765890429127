import { useResponsiveValue } from '@superdispatch/ui';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { launchIntercomTour } from 'shared/helpers/Intercom';
import { useNavbarContext } from 'shared/layout/sidebar/NavbarContext';
import { useLoadSuggestionCountSubscription } from './data/LoadSuggestionsAPI';
import { LoadSuggestionsDrawer } from './LoadSuggestionsDrawer';

interface LoadSuggestionsButtonProps {
  render: (props: {
    loadSuggestionsCount?: number;
    onClick: () => void;
  }) => ReactNode;
}

export function LoadSuggestionsButton({ render }: LoadSuggestionsButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useResponsiveValue(true, true, false);
  const data = useLoadSuggestionCountSubscription();
  const { setDrawerOpen } = useNavbarContext();
  const navigate = useNavigate();

  useEffect(() => {
    launchIntercomTour('load_suggestions_menu_item');
  }, []);

  return (
    <>
      <LoadSuggestionsDrawer
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      {render({
        loadSuggestionsCount: data?.number_of_unseen_suggestions,
        onClick: () => {
          if (isMobile) {
            navigate('/suggested-loads-screen');
            setDrawerOpen(null);
          } else {
            setIsOpen(true);
          }
        },
      })}
    </>
  );
}
