import { Typography } from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  InfoCard,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useRef } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { driverNoteSchema } from 'shared/modules/driver/DriverDTO';
import { useDriversAPI } from '../data/DriversAPI';

export interface DriverPageNotesProps {
  currentDriverGUID: string;
  notes: string;
}

export function DriverPageNotes({
  currentDriverGUID,
  notes,
}: DriverPageNotesProps) {
  const isMobile = useResponsiveValue(true, false);
  const isUpdated = useRef<boolean>(false);
  const { addSnackbar } = useSnackbarStack();
  const { updateDriverNotes } = useDriversAPI();

  const formik = useFormikEnhanced({
    initialValues: {
      notes,
    },
    validationSchema: driverNoteSchema,
    onSubmit: (values) => {
      return updateDriverNotes(currentDriverGUID, values.notes);
    },
    onSubmitSuccess: () => {
      isUpdated.current = true;
      trackEvent('Carrier Updated Driver Notes');
      addSnackbar('Internal Notes updated.', { variant: 'success' });
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const isDisabled = isUpdated.current || !formik.dirty;

  return (
    <Box minWidth={isMobile ? '100%' : '550px'}>
      <FormikProvider value={formik}>
        <Form>
          <InfoCard>
            <Stack space="small">
              <Typography variant="h3" gutterBottom={true}>
                Internal Notes about Driver
              </Typography>
              <FormikMaxLengthTextField
                name="notes"
                minRows={4}
                maxLength={300}
                fullWidth={true}
                multiline={true}
                helperText="Visible only to dispatchers."
                onChange={() => (isUpdated.current = false)}
              />
              <Button
                disabled={isDisabled}
                pending={formik.isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </Stack>
          </InfoCard>
        </Form>
      </FormikProvider>
    </Box>
  );
}
