import { useResponsiveValue } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { ZENDESK_TOKEN_KEY } from 'shared/modules/zendesk/ZendeskData';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { hideIntercomLauncher } from '../Intercom';
import { LocalStore } from '../Store';
import {
  hideZendeskMessenger,
  loginZendeskUser,
  setZendeskConversationFields,
  setZendeskConversationTags,
  setZendeskZIndex,
  showZendeskMessenger,
} from './Zendesk';
import './zendesk.css';
import type { Scope, ZendeskMethod } from './ZendeskTypes';

export const ZendeskApi = (
  scope: Scope,
  method: ZendeskMethod,
  ...args: unknown[]
) => {
  window.zE?.apply(null, [scope, method, ...args]);
};

const API_KEY = '5e126156-0153-445a-bc39-9b442fa5c8ab';

export function initialize(onLoad: () => void) {
  if (
    typeof window === 'undefined' ||
    window.zE ||
    MobileAppBridge.isInjected() ||
    document.getElementById('ze-snippet')
  ) {
    return;
  }

  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => {
    onLoad();
    loginZendeskUser(LocalStore.get(ZENDESK_TOKEN_KEY));
    setZendeskZIndex(1);
  };
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${API_KEY}`;

  document.body.appendChild(script);
}

export function useZendeskInitialize(tag: 'Super_Loadboard' | 'Carrier_TMS') {
  const isZendeskEnabled = useFlag('ctms_zendesk_launcher');
  const isMobile = useResponsiveValue(true, true, false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: settings } = useCarrierSettings();
  const { data: dispatcher } = useDispatcherProfile();

  useEffect(() => {
    window.addEventListener('beforeprint', hideZendeskMessenger);
    window.addEventListener('afterprint', showZendeskMessenger);

    return () => {
      window.removeEventListener('beforeprint', hideZendeskMessenger);
      window.removeEventListener('afterprint', showZendeskMessenger);
    };
  }, []);

  useEffect(() => {
    if (!isZendeskEnabled) return;

    initialize(() => {
      setIsLoaded(true);
    });
  }, [isZendeskEnabled, isMobile]);

  useEffect(() => {
    if (isLoaded) {
      setZendeskConversationTags([tag]);
      setZendeskConversationFields([
        {
          id: 'carrier_name',
          value: settings?.carrier.name || 'Unknown',
        },
        {
          id: 'carrier_guid',
          value: settings?.carrier.guid || '',
        },
        {
          id: 'email',
          value: dispatcher?.email || '',
        },
        {
          id: 'phone',
          value: dispatcher?.phone || '',
        },
        {
          id: 'name',
          value: dispatcher?.name || '',
        },
        {
          id: 'current_url',
          value: window.location.href,
        },
      ]);
    }
  }, [
    isLoaded,
    tag,
    settings?.carrier.name,
    settings?.carrier.guid,
    dispatcher?.email,
    dispatcher?.phone,
    dispatcher?.name,
  ]);

  useEffect(() => {
    if (isLoaded && isMobile) {
      hideZendeskMessenger();
      ZendeskApi('messenger:on', 'close', hideZendeskMessenger);
    }
    if (isMobile) {
      hideIntercomLauncher();
    }
  }, [isLoaded, isMobile]);
}
