import { useDeepEqualValue } from '@superdispatch/hooks';
import { renderChildren, useResponsiveValue } from '@superdispatch/ui';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { clearAuthToken, saveAuthRole } from 'shared/auth/AuthToken';
import { useVerification } from 'shared/auth/core/VerificationStorage';
import { useDriverAccount } from 'shared/auth/data/useDriverAccount';
import {
  listenToServerError,
  ServerError,
} from 'shared/errors/ServerErrorEvents';
import { clearErrorTrackerUser } from 'shared/helpers/ErrorTracker';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useSubscriptionCelebrationState } from 'shared/modules/subscription/core/useSubscriptionCelebrationState';
import { RedirectToLogin } from 'shared/routing/RedirectToLogin';
import { LoadboardMobileCarrierConnectionDialog } from '../../loadboard/webview/LoadboardMobileCarrierConnectionErrorDialog';
import { useShowSubscriptionCancelledDialog } from './data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from './data/useShowTrialExpiredDialog';
interface Props {
  children?: ReactNode;
  subscribe?: Array<ServerError['type']>;
}

export function ServerErrorBoundary({
  children,
  subscribe: subscribeProp = [
    'Unauthorized',
    'AUTH_ERROR',
    'CARRIER_CONNECTION_ERROR',
    'trial_expired',
    'payment_past_due',
    'subscription_cancelled',
  ],
}: Props) {
  const isMobile = useResponsiveValue(true, true, false);
  const [serverError, setServerError] = useState<ServerError>();
  const subscribe = useDeepEqualValue(subscribeProp);
  const location = useLocation();
  const { setOpenTrialExpiredDialog, closeTrialExpiredDialog } =
    useShowTrialExpiredDialog();
  const { removeVerification } = useVerification();
  const { removeDriverToken } = useDriverAccount();
  const { removeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { removeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();
  const { removeStatuses } = useSubscriptionCelebrationState();
  const {
    setOpenSubscriptionCancelledDialog,
    closeSubscriptionCancelledDialog,
  } = useShowSubscriptionCancelledDialog();

  const clearStorage = useCallback(() => {
    clearErrorTrackerUser();
    clearAuthToken();
    saveAuthRole(null);
    removeVerification();
    removeTrialExpiredDialog();
    removeSubscriptionCancelledDialog();
    removeDriverToken();
    removeStatuses();
  }, [
    removeVerification,
    removeTrialExpiredDialog,
    removeSubscriptionCancelledDialog,
    removeDriverToken,
    removeStatuses,
  ]);

  useEffect(
    () =>
      listenToServerError((nextServerError) => {
        if (!subscribe.length || subscribe.includes(nextServerError.type)) {
          setServerError(nextServerError);
        }

        if (
          nextServerError.type === 'Unauthorized' ||
          nextServerError.type === 'AUTH_ERROR'
        ) {
          clearStorage();
        }
      }),
    [subscribe, clearStorage],
  );

  switch (serverError?.type) {
    case 'trial_expired': {
      if (!isMobile) {
        setOpenTrialExpiredDialog();
        closeSubscriptionCancelledDialog();
        return renderChildren(children);
      }
      closeTrialExpiredDialog();
      return (
        <Navigate
          to={`/trial-expired?page=${location.pathname}&type=trial_expired`}
        />
      );
    }

    case 'payment_past_due': {
      openExternalURL('/loadboard');
      return renderChildren(children);
    }

    case 'subscription_cancelled': {
      if (!isMobile) {
        setOpenSubscriptionCancelledDialog('cancelled');
        return renderChildren(children);
      }
      return (
        <Navigate
          to={`/trial-expired?page=${location.pathname}&type=cancelled`}
        />
      );
    }

    case 'Unauthorized':
    case 'AUTH_ERROR': {
      return <RedirectToLogin />;
    }

    case 'CARRIER_CONNECTION_ERROR': {
      return <LoadboardMobileCarrierConnectionDialog />;
    }

    default:
      return renderChildren(children);
  }
}
