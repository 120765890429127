import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSubscriptionDetails } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverSubscriptionStatusBanner } from './core/DriverSubscriptionStatusBanner';
import { trackDriversEvent } from './data/DriversAnalytics';
import { useDriversPageContext } from './DriversPageContext';
import { DriversPageList } from './DriversPageList';

export function DriversPage() {
  const isMobile = useResponsiveValue(true, false);
  const { currentDriverGUID } = useDriversPageContext();
  const { data: subscription } = useSubscriptionDetails();
  const shouldShowTrialExpiredBanner = useFlag('trial_expired_banner');
  const isSubscriptionOnLitePlan =
    subscription?.subscription?.plan?.name === 'lite';
  useEffect(() => {
    trackDriversEvent({ name: 'CTMS: Opened React Drivers Page' });
  }, []);

  if (currentDriverGUID && isMobile) {
    return <Outlet />;
  }

  return (
    <>
      {shouldShowTrialExpiredBanner &&
        isSubscriptionOnLitePlan &&
        !isMobile && <DriverSubscriptionStatusBanner />}
      <SidebarContainer sidebar={<DriversPageList />}>
        <Outlet />
      </SidebarContainer>
    </>
  );
}
