import { Card, CardContent, Collapse, Typography } from '@material-ui/core';
import { Check, Info, WarningRounded } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Alert, Box, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreditCard } from 'shared/errors/PaymentService';
import { ChargebeeResponse } from 'shared/helpers/ChargebeeWeb';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { EncryptIcon } from 'shared/icons/EncryptIcon';
import { SeatBasedPricingBox } from 'shared/modules/subscription/core/SeatBasedPricingBox';
import { billingAddressFormErrorsGetter } from 'shared/modules/subscription/core/SubscriptionUtils';
import { trackSubscriptionEvent } from 'shared/modules/subscription/data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useSubscriptionAPI,
  useSubscriptionBillableSeats,
  useSubscriptionDetails,
} from 'shared/modules/subscription/SubscriptionAPI';
import { subscriptionSchema } from 'shared/modules/subscription/SubscriptionDTO';
import { LocationState } from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import { LinkAnchor } from 'shared/routing/Links';
import { ChargebeeEmptyPlaceholder } from 'shared/ui/ChargebeeEmptyPlaceholder';
import { SubscriptionForm } from '../subscription/SubscriptionForm';
import { SubscriptionSummary } from './SubscriptionSummary';

export function SubscriptionPaymentForm() {
  const navigate = useNavigate();
  const { data: subscriptionBillingAddress } = useBillingAddress();
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';
  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: isSubscriptionOnLitePlan
      ? subscriptionDetails?.upgrade_option?.plan?.measurement_type
      : subscriptionDetails?.subscription?.plan?.measurement_type,
  });

  const { cardRef, isChargebeeAvailable, addCreditCard } = useCreditCard(
    subscriptionDetails?.client_token || '',
  );
  const [shouldEnableReinitialize, setShouldEnableReinitialize] =
    useState(true);
  const { saveBillingAddress } = useSubscriptionAPI();
  const isMobile = useResponsiveValue(true, false);

  const formik = useFormikEnhanced({
    enableReinitialize: shouldEnableReinitialize,
    validateOnBlur: false,
    validationSchema: subscriptionSchema,
    initialValues: {
      zip: subscriptionBillingAddress?.billing_zip || '',
      country: subscriptionBillingAddress?.billing_country || 'US',
      state: subscriptionBillingAddress?.billing_state || '',
      city: subscriptionBillingAddress?.billing_city || '',
      address: subscriptionBillingAddress?.billing_address || '',
      seats_count: billableSeats?.billable_seats || 1,
    },
    getFormErrors: (error) => {
      return billingAddressFormErrorsGetter(error);
    },
    onSubmit: async (values) => {
      setShouldEnableReinitialize(false);
      if (formik.dirty) {
        await saveBillingAddress({
          billing_country: values.country,
          billing_zip: values.zip,
          billing_state: values.state,
          billing_address: values.address,
          billing_city: values.city,
        });
      }
      return addCreditCard({});
    },
    onSubmitSuccess: (responseValues: ChargebeeResponse, values) => {
      const {
        token,
        vaultToken,
        additional_information: { braintree },
      } = responseValues;

      const locationState: LocationState = {
        token,
        vaultToken,
        billingAddress: {
          zip: values.zip,
          country: values.country,
          state: values.state,
        },
        cardDetails: braintree,
        seatsCount: values.seats_count,
      };

      if (formik.dirty) {
        trackSubscriptionEvent({
          name: 'Carrier Edited Subscription Billing Address',
        });
      }

      navigate('/settings/subscription/upgrade_plan', { state: locationState });
    },
  });

  if (!subscriptionDetails?.subscription?.plan) {
    return null;
  }

  const billableSeatsCount = Number(billableSeats?.billable_seats);
  const seatsToDecrease = billableSeatsCount - formik.values.seats_count;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Columns space="small" collapseBelow="tablet">
          <Column>
            <Card>
              <CardContent>
                <Stack space="medium">
                  <Typography variant="h3">Payment Information</Typography>
                  <SeatBasedPricingBox
                    isMinusDisabled={formik.values.seats_count === 1}
                  />
                  <Collapse in={formik.values.seats_count < billableSeatsCount}>
                    <Box
                      borderRadius="small"
                      backgroundColor="Yellow50"
                      padding="xsmall"
                    >
                      <Columns space="xsmall">
                        <Column width="content">
                          <WarningRounded
                            htmlColor={Color.Yellow300}
                            fontSize={isMobile ? 'small' : 'medium'}
                          />
                        </Column>
                        <Column width="fluid">
                          <Stack space="xsmall">
                            <Box>
                              <Typography variant="body1">
                                You have {billableSeatsCount} active drivers on
                                your team
                              </Typography>
                              <Typography>
                                To decrease team size, deactivate{' '}
                                {seatsToDecrease}{' '}
                                {formatPlural(
                                  seatsToDecrease,
                                  'driver',
                                  'drivers',
                                )}
                                .
                              </Typography>
                            </Box>

                            <LinkAnchor to="/drivers">
                              Manage Drivers
                            </LinkAnchor>
                          </Stack>
                        </Column>
                      </Columns>
                    </Box>
                  </Collapse>
                  <Stack space="xsmall">
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      gutterBottom={true}
                    >
                      Card Details
                    </Typography>
                    <Columns space="xxsmall" align="top">
                      <Column width="content">
                        <EncryptIcon />
                      </Column>
                      <Column width="fluid">
                        <TextBox color="secondary" variant="body">
                          We use industry-standard encryption and security
                          protocols to safeguard your information. Your card
                          details are not stored on our internal servers for
                          your protection.
                        </TextBox>
                      </Column>
                    </Columns>
                    <Columns space="xxsmall" align="top">
                      <Column width="content">
                        <Check
                          fontSize="small"
                          htmlColor={ColorDynamic.Dark100}
                        />
                      </Column>
                      <Column width="fluid">
                        <TextBox color="secondary" variant="body">
                          We accept all major credit cards
                        </TextBox>
                      </Column>
                    </Columns>
                  </Stack>

                  <Stack space="large">
                    {isChargebeeAvailable ? (
                      <SubscriptionForm ref={cardRef} />
                    ) : (
                      <ChargebeeEmptyPlaceholder />
                    )}

                    {formik.status.type === 'rejected' && (
                      <Alert
                        icon={
                          <Info
                            fontSize="small"
                            htmlColor={ColorDynamic.Red500}
                          />
                        }
                        severity="critical"
                      >
                        {formik.status.payload.message}
                      </Alert>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Column>
          <Column>
            <Card>
              <CardContent>
                <SubscriptionSummary
                  seatsCount={formik.values.seats_count}
                  disabled={seatsToDecrease > 0}
                  onContinue={() => {
                    void formik.submitForm();
                  }}
                />
              </CardContent>
            </Card>
          </Column>
        </Columns>
      </Form>
    </FormikProvider>
  );
}
