import { Typography } from '@material-ui/core';
import { AvatarButton, Inline, Stack } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { LoadboardAccessBannerController } from 'shared/modules/loadboard/core/loadboard-access/LoadboardAccessBannerController';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { DashboardNotifications } from './core/DashboardNotifications';
import { DashboardRatings } from './core/DashboardRatings';
import { DelayedLoads } from './core/DelayedLoads';
import { DriversStatistics } from './core/DriversStatistics';
import { UnassignedLoads } from './core/UnassignedLoads';
import { trackDashboardEvent } from './DashboardAnalytics';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 456px);
  gap: 16px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export function Dashboard() {
  const { data: settings } = useCarrierSettings();
  const { data: carrierInfo } = useCarrierInfo();

  const companyName = settings?.carrier.name || 'Unknown';
  const companyNameInitials = useMemo(
    () => carrierInfo?.name && getInitials(carrierInfo.name),
    [carrierInfo?.name],
  );

  useEffect(() => {
    trackDashboardEvent({ name: 'Carrier Opened Dashboard' });
  }, []);

  return (
    <PageLayout
      header={
        <PageHeader
          title={
            <Inline space="xsmall" verticalAlign="center">
              <AvatarButton src={carrierInfo?.logo_url}>
                {companyNameInitials}
              </AvatarButton>
              <Typography variant="h2">{companyName}</Typography>
            </Inline>
          }
        />
      }
    >
      <DocumentTitle title="Dashboard" />
      <Stack space="small">
        <LoadboardAccessBannerController />
        <Container>
          <DelayedLoads />
          <UnassignedLoads />
          <DriversStatistics />
          <DashboardNotifications />
          <DashboardRatings />
        </Container>
      </Stack>
    </PageLayout>
  );
}
