import { Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { ArrowForwardIcon } from 'shared/icons/ArrowForwardIcon';
import { LinkAnchor } from 'shared/routing/Links';
import { formatLoadStatus } from '../../loads/data/LoadDTO';
import { AssignedLoadDTO } from '../data/DashboardDTO';

interface DashboardDriversLoadProps {
  load: AssignedLoadDTO;
  actions?: React.ReactNode;
}

export function DashboardDriversLoad({
  load,
  actions,
}: DashboardDriversLoadProps) {
  const origin = formatAddressLine('', load.pickup_city, load.pickup_state);
  const destination = formatAddressLine(
    '',
    load.delivery_city,
    load.delivery_state,
  );
  return (
    <Box padding="xsmall" backgroundColor="Silver200">
      <Stack space="xxsmall">
        <Inline space="xxsmall">
          <OverflowText color="textPrimary">
            <LinkAnchor
              to={`/loads/${load.guid}`}
              variant="body1"
              key={load.guid}
            >
              {load.number || 'Load does not have ID'}
            </LinkAnchor>
          </OverflowText>

          <Tag
            aria-label="status tag"
            variant="subtle"
            color={
              load.status === 'new'
                ? 'teal'
                : load.status === 'picked_up'
                ? 'yellow'
                : 'green'
            }
          >
            {formatLoadStatus(load.status)}
          </Tag>
        </Inline>
        <Columns space="xxsmall">
          <Column width="fluid">
            <Inline space="xxsmall" verticalAlign="center">
              <Typography color={origin ? 'textPrimary' : 'textSecondary'}>
                {origin || 'N/A'}
              </Typography>

              <ArrowForwardIcon />

              <Typography color={destination ? 'textPrimary' : 'textSecondary'}>
                {destination || 'N/A'}
              </Typography>
            </Inline>
          </Column>
          {actions}
        </Columns>
        <Typography color="textSecondary">
          {load.vehicles?.length}{' '}
          {formatPlural(load.vehicles?.length, 'vehicle', 'vehicles')}
        </Typography>
      </Stack>
    </Box>
  );
}
