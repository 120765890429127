import { Card, Divider, Typography } from '@material-ui/core';
import { Image, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import AccountDeletedIconDark from '../assets/account-deleted-icon-dark.png';
import AccountDeletedIcon from '../assets/account-deleted-icon.svg';
import AskForInviteIconDark from '../assets/ask-dispatcher-for-invite-icon-dark.svg';
import AskForInviteIcon from '../assets/ask-dispatcher-for-invite-icon.svg';
import { DispatcherActivationStepItem } from '../core/DispatcherActivationStepItem';

const DispatcherBox = styled(Box)`
  padding: 40px 32px;
`;

export function DispatcherInvitationDeclinedPage() {
  return (
    <Box
      maxWidth="600px"
      marginLeft="auto"
      marginRight="auto"
      marginTop={['none', 'large']}
      marginBottom={['none', 'large']}
    >
      <Card>
        <DispatcherBox>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Image
                width="84px"
                height="80px"
                alt="expired"
                src={AccountDeletedIcon}
                srcDark={AccountDeletedIconDark}
              />
              <Typography variant="h2">
                Your invite has expired or does not exist
              </Typography>
            </Stack>
            <Divider />
            <Stack space="medium">
              <Typography variant="h6" align="center">
                WHAT IS THE NEXT STEP?
              </Typography>

              <Box paddingTop="large" paddingBottom="large">
                <DispatcherActivationStepItem
                  iconSrc={AskForInviteIcon}
                  iconSrcDark={AskForInviteIconDark}
                  title="Ask for Invite"
                  description="Ask your carrier to add you as a dispatcher from their Carrier TMS account. Follow the link you receive to set your password and manage loads."
                />
              </Box>
            </Stack>
          </Stack>
        </DispatcherBox>
      </Card>
    </Box>
  );
}
