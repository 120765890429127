import { ListItem, ListItemText } from '@material-ui/core';
import Skeleton from '@mui/lab/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import { Inline, VisibilityObserver } from '@superdispatch/ui';
import { useAvailableDrivers } from '../data/DashboardAPI';
import { DashboardDriverListItem } from './DashboardDriverListItem';

export function AvailableDrivers() {
  const availableDrivers = useAvailableDrivers({
    keepPreviousData: false,
  });

  if (availableDrivers.isLoading) {
    return (
      <Inline horizontalAlign="center">
        <CircularProgress />
      </Inline>
    );
  }

  return (
    <>
      {availableDrivers.data?.pages.flatMap((page) =>
        page.data.map((driver) => (
          <DashboardDriverListItem key={driver.guid} driver={driver} />
        )),
      )}

      {availableDrivers.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (
              !availableDrivers.isFetchingNextPage &&
              visibility === 'visible'
            ) {
              void availableDrivers.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </>
  );
}
