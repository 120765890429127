import {
  Card,
  CardContent,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Error, MonetizationOn, Send } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useMemo } from 'react';
import { BankErrorSimpleBanner } from 'shared/errors/SuperPayBankErrors';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { DriverSidebarIcon } from 'shared/icons/DriverSidebarIcon';
import { NewMessageIcon } from 'shared/icons/NewMessageIcon';
import {
  splitTextByLine,
  StyledNotesIcon,
} from 'shared/modules/driver/DriverUtils';
import { SuperPayExpeditedPayNudging } from 'shared/modules/superpay/SuperPayExpeditedPayNudging';
import { SuperPayNudgingBanner } from 'shared/modules/superpay/SuperPayNudgingBanner';
import { SuperPayRequestShipperNudging } from 'shared/modules/superpay/SuperPayRequestShipperNudging';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import styled from 'styled-components';
import { getDriverLabel } from '../../../drivers/data/DriverPageUtils';
import { DriverDisconnectedTag } from '../../core/DriverDisconnectedTag';
import { LoadLegsDiagram } from '../../core/LoadLegsDiagram';
import { LoadPaymentStatus } from '../../core/LoadPaymentStatus';
import { LoadTags } from '../../core/LoadTags';
import {
  LoadDTO,
  LoadPaymentDTO,
  sortVehiclesByStatus,
} from '../../data/LoadDTO';
import { trackLoadsEvent } from '../../data/LoadsAnalytics';
import {
  LoadsPageLoadAction,
  LoadsPageLoadActions,
} from '../LoadsPageLoadActions';
import { LoadsPageLoadId } from '../LoadsPageLoadId';
import { LoadsPageLoadMobileVehicles } from './LoadsPageLoadMobileVehicles';

const LoadCard = styled(Card)`
  margin-right: -17px;
  margin-left: -17px;
`;

export interface LoadsPageLoadProps {
  load: LoadDTO;
  onAction: (action: LoadsPageLoadAction) => void;
}

export function LoadsPageLoadMobile({ load, onAction }: LoadsPageLoadProps) {
  const isInspectionPhotoIssuesEnabled = useFlag(
    'ctms_photo_inspection_issues',
  );
  const isExpeditedPayEnabled = useFlag('payments_expedited_pay');
  const isMessagingProjectEnabled = useFlag('messaging_project');

  const [currentPayment] = load.payments as Array<undefined | LoadPaymentDTO>;
  const { data: settings } = useCarrierSettings();
  const sortedVehicles = useMemo(
    () => sortVehiclesByStatus(load.vehicles),
    [load.vehicles],
  );

  const hasDisconnectedDriver =
    load.driver?.connection_status === 'deactivated';
  const driverLabel = getDriverLabel(load.driver || {});
  const isSuperPayRequestShipperNudging =
    settings?.superpay.is_invite_available &&
    (load.stage === 'paid' || load.stage === 'active') &&
    currentPayment?.paid_at &&
    load.is_created_by_broker;

  const paymentMethod = useMemo(() => {
    if (currentPayment) {
      if (currentPayment.method) {
        return currentPayment.method === 'superpay' ? (
          <TextBox color="secondary">
            <InlineBulletItems>
              <SuperPayLabel source="Load List" />
              {currentPayment.terms}
            </InlineBulletItems>
          </TextBox>
        ) : (
          formatLoadPayment(currentPayment.method)
        );
      }

      if (Number(currentPayment.price) === 0) {
        return 'No payment info';
      }
    }

    return 'No payment method';
  }, [currentPayment]);

  const loadPickupDate = load.pickup.actual_date || load.pickup.scheduled_date;
  const loadDeliveryDate =
    load.delivery.actual_date || load.delivery.scheduled_date;

  return (
    <LoadCard square={true}>
      <CardContent>
        <Stack space="small">
          <Columns align="center" space="small">
            <Column width="fluid">
              <Stack space="xsmall" aria-label="Load Header">
                <LoadsPageLoadId load={load} />

                <Inline>
                  <LoadTags load={load} />

                  {!!currentPayment?.price && (
                    <Typography>
                      <Inline>
                        <TextBox variant="body-semibold">
                          {formatCurrency(currentPayment.price)}
                        </TextBox>
                        {paymentMethod &&
                          (typeof paymentMethod === 'string'
                            ? `(${paymentMethod})`
                            : paymentMethod)}
                      </Inline>
                    </Typography>
                  )}
                </Inline>

                <LoadLegsDiagram terminalsDiagram={load.terminals_diagram} />

                {isMessagingProjectEnabled && <NewMessageIcon />}
              </Stack>
            </Column>
            <Column width="content">
              <LoadsPageLoadActions load={load} onAction={onAction} />
            </Column>
          </Columns>

          <Stack space="xsmall">
            {!!load.driver && (
              <Typography color="textSecondary">
                <Inline space="xsmall" verticalAlign="center">
                  <DriverSidebarIcon fontSize="small" color="action" />
                  <Typography>
                    Assigned to <strong>{driverLabel}</strong>{' '}
                  </Typography>

                  {load.driver.notes && (
                    <Tooltip
                      title={splitTextByLine(load.driver.notes)}
                      placement="top"
                      interactive={true}
                    >
                      <StyledNotesIcon />
                    </Tooltip>
                  )}

                  <DriverDisconnectedTag disconnected={hasDisconnectedDriver} />

                  <Typography>{load.driver.phone_number}</Typography>
                </Inline>
              </Typography>
            )}

            {!!currentPayment?.invoice.sent_at && (
              <Columns space="xsmall">
                <Column width="content">
                  <Send fontSize="small" color="action" />
                </Column>
                <Column width="fluid">
                  {currentPayment.invoice.errors.length ? (
                    currentPayment.invoice.errors.map((error) => (
                      <Typography color="error" key={error.channel}>
                        {error.message}
                      </Typography>
                    ))
                  ) : (
                    <Inline>
                      Invoiced on
                      <Typography color="textSecondary">
                        <FormattedDate
                          variant="DateTime"
                          date={currentPayment.invoice.sent_at}
                        />
                      </Typography>
                    </Inline>
                  )}
                </Column>
              </Columns>
            )}

            <Inline>
              <MonetizationOn fontSize="small" color="action" />
              {currentPayment?.method === 'superpay' &&
                (settings?.superpay.payment_error ? (
                  <BankErrorSimpleBanner
                    source="Load List"
                    paymentError={settings.superpay.payment_error}
                  />
                ) : (
                  <SuperPayNudgingBanner source="Load List" />
                ))}

              {isExpeditedPayEnabled &&
              currentPayment?.is_expedited_pay_available ? (
                <SuperPayExpeditedPayNudging
                  onClick={() => {
                    onAction('expedite_payment');
                  }}
                />
              ) : (
                isSuperPayRequestShipperNudging && (
                  <SuperPayRequestShipperNudging
                    isSuperPay={currentPayment.method === 'superpay'}
                  />
                )
              )}
              <Box maxWidth="283px">
                {currentPayment && (
                  <LoadPaymentStatus payment={currentPayment} />
                )}
              </Box>
            </Inline>

            {!!load.latest_internal_note && (
              <div>
                <Typography color="textSecondary" variant="h6">
                  Last Note
                </Typography>

                <Typography>{load.latest_internal_note}</Typography>
              </div>
            )}
          </Stack>

          {isInspectionPhotoIssuesEnabled &&
            load.stage !== 'new' &&
            load.stage !== 'assigned' &&
            !!load.undetected_vehicle_count && (
              <Box
                backgroundColor="Red50"
                display="inline-block"
                paddingLeft="xxsmall"
                paddingRight="xxsmall"
                borderRadius="small"
              >
                <Inline verticalAlign="center" space="xxsmall">
                  <Error fontSize="small" color="error" />
                  <Link
                    href={parseURITemplate(
                      `${load.online_bol_url}{?utm_source,utm_medium,utm_content}`,
                      {
                        utm_source: 'CTMS Web',
                        utm_medium: 'View Load Page',
                        utm_content: 'Non-vehicle Photo Issues Link',
                      },
                    )}
                    onClick={() => {
                      trackLoadsEvent({
                        name: 'Carrier Clicked Non-vehicle Photo Issues',
                        page: 'loads_list',
                      });
                    }}
                  >
                    {load.undetected_vehicle_count} Inspection photo{' '}
                    {formatPlural(
                      load.undetected_vehicle_count,
                      'issue',
                      'issues',
                    )}
                  </Link>
                </Inline>
              </Box>
            )}

          <LoadWaypoints
            space="medium"
            pickupAddress={
              <Stack>
                {load.pickup.venue.name && (
                  <Typography variant="body1">
                    {load.pickup.venue.name}
                  </Typography>
                )}
                {formatAddressLine(
                  load.pickup.venue.address,
                  load.pickup.venue.zip,
                  load.pickup.venue.city,
                  load.pickup.venue.state,
                ) || 'N/A'}
              </Stack>
            }
            pickupBusinessType={load.pickup.venue.business_type}
            pickupDate={
              <InlineBulletItems space="xxsmall">
                {loadPickupDate && (
                  <FormattedDate variant="Date" date={loadPickupDate} />
                )}
                <>
                  {load.pickup.venue.contacts[0]?.name}
                  {load.pickup.venue.contacts[0]?.phone}
                </>
              </InlineBulletItems>
            }
            deliveryAddress={
              <Stack>
                {load.delivery.venue.name && (
                  <Typography variant="body1">
                    {load.delivery.venue.name}
                  </Typography>
                )}
                {formatAddressLine(
                  load.delivery.venue.address,
                  load.delivery.venue.zip,
                  load.delivery.venue.city,
                  load.delivery.venue.state,
                ) || 'N/A'}
              </Stack>
            }
            deliveryBusinessType={load.delivery.venue.business_type}
            deliveryDate={
              <InlineBulletItems space="xxsmall">
                {loadDeliveryDate && (
                  <FormattedDate variant="Date" date={loadDeliveryDate} />
                )}
                <>
                  {load.delivery.venue.contacts[0]?.name}
                  {load.delivery.venue.contacts[0]?.phone}
                </>
              </InlineBulletItems>
            }
          />

          <LoadsPageLoadMobileVehicles vehicles={sortedVehicles} />

          <Stack>
            <Typography variant="body1">Customer</Typography>

            <Stack space="xxsmall">
              <Typography noWrap={true}>
                {load.customer.venue.name &&
                load.customer.shipper_profile_url ? (
                  <Link
                    href={load.customer.shipper_profile_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      trackLoadsEvent({
                        name: 'CTMS: Clicked Shipper Name',
                        page: 'loads_list',
                      });
                    }}
                  >
                    {load.customer.venue.name}
                  </Link>
                ) : (
                  load.customer.venue.name || 'N/A'
                )}
              </Typography>

              {load.customer.venue.contacts.map(
                (contact, idx) =>
                  !!contact.phone && (
                    <Typography key={idx}> {contact.phone}</Typography>
                  ),
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </LoadCard>
  );
}
