import { Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  Image,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';

interface StepItemProps {
  title: string;
  description: string;
  iconSrc: string;
  iconSrcDark?: string;
}

export function DriverActivationStepItem({
  title,
  description,
  iconSrc,
  iconSrcDark,
}: StepItemProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Columns align="center" space={['none', 'large']} collapseBelow="tablet">
      <Column width="content">
        <Image src={iconSrc} srcDark={iconSrcDark} />
      </Column>
      <Column width="fluid">
        <Stack align={isMobile ? 'center' : 'left'}>
          <Typography variant="h2">{title}</Typography>
          <Typography align={isMobile ? 'center' : 'left'}>
            {description}
          </Typography>
        </Stack>
      </Column>
    </Columns>
  );
}
