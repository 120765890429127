import { formatDate, NullableDateInput, parseDate } from '@superdispatch/dates';
import { useDeepEqualValue } from '@superdispatch/hooks';
import {
  formatCustomerType,
  formatPaymentMethod,
  formatPaymentTerm,
  formatVehicleType,
} from '@superdispatch/sdk';
import { round } from 'lodash-es';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { trackEvent } from 'shared/helpers/Analytics';
import {
  convertPricePerKmToPricePerMile,
  kmToMile,
} from 'shared/modules/loadboard/LoadboardUtils';
import {
  formatPaymentTermsLabel,
  parsePaymentTerms,
  PostingSearchCriteriaDTO,
  PostingSearchCriteriaVenueDTO,
  VenueDTO,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import { FeatureToggles } from 'shared/settings/CarrierSettingsAPI';
import { capitalize, joinStrings } from 'shared/utils/StringUtils';
import { parseSearchQuery } from 'shared/utils/URLUtils';
import { PostingCarrierLoadDTO, PostingDTO } from '../data/dto/PostingDTO';
import {
  getBookingTimeRange,
  hasVehicleSamplePhoto,
  PostingLoadDTO,
  PostingStepDTO,
} from '../data/dto/PostingLoadDTO';
import { PostingRequestValuesDTO } from '../data/dto/PostingRequestDTO';
import {
  alertDurationOptions,
  isSavedSearchMuted,
  PostingSavedSearchDTO,
} from '../data/dto/PostingSavedSearchDTO';
import {
  LoadSearchSource,
  PostingsPageParamsDTO,
  PostingsPageTab,
} from '../data/dto/PostingsPageParamsDTO';
import {
  NotificationSettingsValuesDTO,
  NotificationsSettingActivity,
  NotificationsSettingDTO,
} from './notifications-settings/data/LoadboardNotificationsSettingDTO';
import { formatRegions } from './RegionsHelper';

interface ShipperProfileEventShipper {
  name: string;
  email: string;
  phone: string;
  contactName: string;
}

interface ShipperProfileEventCarrier {
  name: string;
  email: string;
  phone: string;
}

interface ShipperProfileEventDispatcher {
  name: string | null;
  email: string | null;
  phone: string | null;
}

export type LoadboardEvent =
  | {
      name: 'SLB: Clicked Search Similar Loads Button';
      source?: LoadSearchSource;
    }
  | {
      name: 'SLB: Clicked Add Saved Search';
    }
  | {
      name: 'SLB: Clicked Manage Alert Notifications';
    }
  | {
      name: 'SLB: Clicked Create Load Alert';
      source?: LoadSearchSource;
    }
  | { name: 'SLB: Clicked Copy Share Link' }
  | { name: 'SLB: Opened Loadboard' }
  | {
      name: 'Carrier Viewed Become Verified Popup';
      utm_medium: 'First View' | 'Verified Carrier Banner';
    }
  | { name: 'SLB: Viewed Access Expired Popup' }
  | { name: 'SLB: Tapped Become Verified Carrier Banner' }
  | { name: 'SLB: Clicked on Map View' }
  | { name: 'SLB: Interested in Map View'; email: string }
  | {
      name: 'SLB: Closed Load Details';
      source: 'close button' | 'outside click' | 'esc press';
    }
  | { name: 'SLB: Interacted with Map' }
  | { name: 'SLB: Clicked Terms and Conditions' }
  | {
      name: 'SLB: Clicked Tab';
      tabName:
        | 'available'
        | 'booked'
        | 'suggested'
        | 'requested'
        | 'private-loadboard'
        | 'saved-loads'
        | 'saved-search';
      tabItemCount?: number;
    }
  | {
      name: "SLB: Clicked Shipper's Private Loadboard Tab";
      shipper: {
        name: string;
        guid: string;
      };
    }
  | { name: 'SLB: Clicked Saved Search'; savedSearch: PostingSavedSearchDTO }
  | { name: 'SLB: Clicked Load Alert'; savedSearch: PostingSavedSearchDTO }
  | {
      name: 'SLB: Saved A Search';
      source?: LoadSearchSource;
      savedSearch: PostingSavedSearchDTO;
    }
  | {
      name: 'SLB: Shortcut Key Pressed';
      keyCombination: string;
    }
  | {
      name: 'SLB: Created Load Alert';
      source?: LoadSearchSource;
      savedSearch: PostingSavedSearchDTO;
    }
  | {
      name: 'SLB: Updated Saved Search Criteria';
      savedSearch: PostingSavedSearchDTO;
      prevSavedSearch: PostingSavedSearchDTO;
    }
  | {
      name: 'SLB: Updated Load Alert Criteria';
      savedSearch: PostingSavedSearchDTO;
      prevSavedSearch: PostingSavedSearchDTO;
    }
  | {
      name: 'SLB: Remove Saved Search';
      savedSearch: PostingSavedSearchDTO;
    }
  | {
      name: 'SLB: Remove Load Alert';
      savedSearch: PostingSavedSearchDTO;
    }
  | { name: 'SLB: Clicked Shipper Phone In Load Details' }
  | { name: 'SLB: Clicked Shipper Phone In Shipper Profile' }
  | {
      name: 'SLB: Saved Load';
      load: PostingDTO;
    }
  | {
      name: 'SLB: Canceled Load Request';
      load: PostingLoadDTO;
      tabName: PostingsPageTab;
    }
  | {
      name: 'SLB: Updated Load Request';
      bookQuery: PostingRequestValuesDTO;
      carrierPostingInfo: PostingCarrierLoadDTO;
      tabName: PostingsPageTab;
      savedSearchGuid?: string;
      criteria: PostingSearchCriteriaDTO;
    }
  | {
      name: 'SLB: Requested Load';
      posting: PostingDTO;
      bookQuery: PostingRequestValuesDTO;
      params: PostingsPageParamsDTO;
      carrierPostingInfo?: PostingCarrierLoadDTO | null;
    }
  | {
      name: 'SLB: Booked Load';
      posting: PostingDTO;
      bookQuery: PostingRequestValuesDTO;
      params: PostingsPageParamsDTO;
    }
  | {
      posting: PostingDTO;
      params: PostingsPageParamsDTO;
      name: 'SLB: Opened Load Details';
      source?: undefined;
      position?: undefined;
    }
  | {
      posting: PostingDTO;
      params: PostingsPageParamsDTO;
      name: 'SLB: Viewed Load';
      source?: 'Suggested Load';
      position: number;
    }
  | {
      name: 'SLB: Searched For Loads';
      resultCount: number;
      features: null | FeatureToggles;
      params: PostingsPageParamsDTO;
      criteria: PostingSearchCriteriaDTO;
    }
  | {
      name: 'SLB: Request/Book Time';
      type: 'book' | 'request';
      openTime: NullableDateInput;
      postedToLoadboardAt?: NullableDateInput;
    }
  | { name: 'SLB: Clicked View Order'; medium: 'list' | 'details' }
  | {
      name: 'SLB: Clicked View Load Offer';
      type: 'offer' | 'counter_offer';
      medium: 'list' | 'details';
    }
  | { name: 'SLB: Clicked View Route'; medium: 'list' | 'details' }
  | {
      name: 'SLB: UI Interaction';
      type:
        | 'Clicked View More Suggested Loads'
        | 'Clicked: Loads Along Route'
        | 'Vehicle list: Clicked Image Enlarge'
        | 'Vehicle list: Clicked Image Diminish'
        | 'Vehicle list: Clicked Show'
        | 'Vehicle list: Clicked Hide'
        | 'Load Alert: Clicked Active Bell Icon'
        | 'Load Alert: Clicked Muted Bell Icon'
        | 'Pagination: Clicked Page Number'
        | 'Pagination: Clicked Prev'
        | 'Pagination: Clicked Next'
        | 'FilterForm: Clicked Clear'
        | 'Filter: Enabled Loads Along Route'
        | 'Filter: Disabled Loads Along Route'
        | 'Filter: Clicked Search'
        | 'Filter: Clicked Book Now filter'
        | 'Filter: Clicked Swap Locations'
        | 'Filter: Clicked Clear';
    }
  | { name: 'SLB: Notification settings opened' }
  | { name: 'SLB: Tapped Reply To Blacklisted SMS' }
  | {
      name: 'SLB: Notification settings updated';
      notificationSettings: NotificationSettingsValuesDTO;
      updatedNotificationSettings: NotificationSettingsValuesDTO;
    }
  | {
      name: 'SLB: All notification settings updated';
      updatedNotificationSettings: NotificationsSettingDTO[];
    }
  | {
      name: 'SLB: Viewed Shipper Profile';
      shipper: ShipperProfileEventShipper;
      carrier?: ShipperProfileEventCarrier;
      dispatcher?: ShipperProfileEventDispatcher;
    }
  | {
      name: 'SLB: Clicked Link in About-Us in Shipper Profile';
      shipper: ShipperProfileEventShipper;
      carrier?: ShipperProfileEventCarrier;
      dispatcher?: ShipperProfileEventDispatcher;
    }
  | {
      name: 'SLB: Clicked Personal Page URL in Shipper Profile';
      shipper: ShipperProfileEventShipper;
      carrier?: ShipperProfileEventCarrier;
      dispatcher?: ShipperProfileEventDispatcher;
    }
  | {
      name: 'SLB: Clicked Link in Additional Carrier Requirements in Shipper Profile';
      shipper: ShipperProfileEventShipper;
      carrier?: ShipperProfileEventCarrier;
      dispatcher?: ShipperProfileEventDispatcher;
    }
  | {
      name: 'SLB: Clicked Link in Terms and Conditions in Shipper Profile';
      shipper?: ShipperProfileEventShipper;
      carrier?: ShipperProfileEventCarrier;
      dispatcher?: ShipperProfileEventDispatcher;
    }
  | { name: 'SLB: Opened Shipper’s Ratings' }
  | { name: 'Carrier Viewed Cargo Minimum Details' }
  | {
      name: 'Carrier Updated Carrier Info';
      largest_trailer_capacity?: number;
      utm_medium: string;
    }
  | {
      name: 'Carrier Clicked Access Free Loadboard';
    }
  | {
      name: 'Carrier Clicked Upgrade Now';
      utm_medium: string;
    }
  | {
      name: 'Carrier Clicked Reactivate Now';
      utm_medium: string;
    }
  | {
      name: 'Carrier Viewed State Selection Dialog for Intrastate Carriers';
    }
  | {
      name: 'Carrier Updated Search on State Selection Dialog for Intrastate Carriers';
      selected_state: string;
    };

function formatBooleanValue(value: boolean | null | undefined) {
  if (value == null) {
    return undefined;
  }

  return value ? 'yes' : 'no';
}

function formatChangedValues(
  prev: Record<string, unknown>,
  next: Record<string, unknown>,
): Record<string, unknown> {
  return {
    ...next,
    ...Object.keys(prev).reduce((acc: Record<string, unknown>, key) => {
      if (next[key] !== prev[key]) {
        acc[`${key} changed from`] = prev[key];
      }

      return acc;
    }, {}),
  };
}

function calculateRangeDateDelta(
  date: DateTime,
  postingStep: PostingStepDTO | undefined,
): number | undefined {
  if (!postingStep) {
    return undefined;
  }

  const [start, end] = getBookingTimeRange(postingStep);

  if (!start || !end) {
    return undefined;
  }

  // Diff with start date with minus sign
  // ----date--start--end----
  if (date < start) {
    return date.diff(start).as('day');
  }

  // Diff with end date with plus sign
  // ----start--end--date----
  if (date > end) {
    return date.diff(end).as('day');
  }

  // Zero result
  // ----start--date--end----
  return 0;
}

function formatPostingRequest(
  {
    load,
    bookQuery,
    carrierPostingInfo,
  }: {
    load?: PostingLoadDTO;
    bookQuery: PostingRequestValuesDTO;
    carrierPostingInfo?: null | PostingCarrierLoadDTO;
  },
  prefix = 'request ',
): Record<string, unknown> {
  const prevPrice = load
    ? load.price
    : carrierPostingInfo
    ? carrierPostingInfo.price
    : undefined;

  const postedToLoadboardAt = parseDate(load?.posted_to_loadboard_at, {
    format: 'JodaISO',
  });

  const nextPickupDate = parseDate(bookQuery.pickup_date, {
    format: 'JodaISO',
  });
  const nextDeliveryDate = parseDate(bookQuery.delivery_date, {
    format: 'JodaISO',
  });

  const prevPickupDate = parseDate(
    load?.pickup.scheduled_at ?? carrierPostingInfo?.pickup_date,
    { format: 'JodaISO' },
  );
  const prevDeliveryDate = parseDate(
    load?.delivery.scheduled_at ?? carrierPostingInfo?.delivery_date,
    { format: 'JodaISO' },
  );

  return {
    [`${prefix}load guid`]: load?.guid,
    [`${prefix}posting guid`]: load?.posting_guid,
    [`${prefix}price`]: bookQuery.price,
    [`${prefix}price delta`]:
      prevPrice == null ? undefined : round(bookQuery.price - prevPrice, 2),
    [`${prefix}pickup date`]: formatDate(nextPickupDate, { variant: 'Date' }),
    [`${prefix}pickup date delta`]:
      prevPickupDate.isValid && nextPickupDate.diff(prevPickupDate).as('days'),

    [`${prefix}delivery date`]: formatDate(nextDeliveryDate, {
      variant: 'Date',
    }),
    [`${prefix}delivery date delta`]:
      prevDeliveryDate.isValid &&
      nextDeliveryDate.diff(prevDeliveryDate).as('days'),

    [`${prefix}pickup range delta`]: calculateRangeDateDelta(
      nextPickupDate,
      load?.pickup,
    ),
    [`${prefix}delivery range delta`]: calculateRangeDateDelta(
      nextDeliveryDate,
      load?.delivery,
    ),
    [`${prefix}posted date`]: !postedToLoadboardAt.isValid
      ? undefined
      : postedToLoadboardAt.toUTC().toISO(),
    [`${prefix}time from posted`]: !postedToLoadboardAt.isValid
      ? undefined
      : Math.abs(Math.round(postedToLoadboardAt.diffNow().as('minutes'))),
    'is counter bid from carrier':
      carrierPostingInfo?.status === 'COUNTER_OFFER_SENT',
    'counter offer price from shipper': carrierPostingInfo?.counter_offer_price,
  };
}

function stringifyVenues(venues: PostingSearchCriteriaVenueDTO[]) {
  return JSON.stringify(venues);
}

function formatSearchCriteria(
  {
    delivery_radius,
    delivery_venues,
    payment: { terms },
    pickup_radius,
    pickup_venues,
    price,
    price_per_km,
    show_bookable,
    transport_type,
    vehicle_count,
    max_vehicle_count,
    vehicle_is_inoperable,
    vehicle_types,
    search_along_route,
    distance_off_route,
    latr_waypoints,
    shipper,
    ready_within_days,
  }: PostingSearchCriteriaDTO,
  prefix = '',
) {
  const formatVenues = (venues: PostingSearchCriteriaVenueDTO[]) =>
    venues
      .map((address: VenueDTO) =>
        formatAddressLine(address.zip, address.city, address.state),
      )
      .join('; ');

  const formatRadius = (value?: number | null) =>
    value != null ? kmToMile(value, 2) : 50;

  return {
    [`${prefix}origin`]: !pickup_venues.length
      ? undefined
      : formatVenues(pickup_venues),
    [`${prefix}origin_regions`]: !pickup_venues.length
      ? undefined
      : formatRegions(pickup_venues),
    [`${prefix}originJSON`]: !pickup_venues.length
      ? undefined
      : stringifyVenues(pickup_venues),
    [`${prefix}origin count`]: pickup_venues.length,
    [`${prefix}is default origin radius`]: formatBooleanValue(
      pickup_radius == null,
    ),
    [`${prefix}origin radius`]: formatRadius(pickup_radius),
    [`${prefix}destination`]: !delivery_venues.length
      ? undefined
      : formatVenues(delivery_venues),
    [`${prefix}destination_regions`]: !delivery_venues.length
      ? undefined
      : formatRegions(delivery_venues),
    [`${prefix}destinationJSON`]: !delivery_venues.length
      ? undefined
      : stringifyVenues(delivery_venues),
    [`${prefix}destination count`]: delivery_venues.length,
    [`${prefix}is default destination radius`]: formatBooleanValue(
      delivery_radius == null,
    ),
    [`${prefix}destination radius`]: formatRadius(delivery_radius),
    [`${prefix}search lane`]:
      !pickup_venues.length && !delivery_venues.length
        ? undefined
        : joinStrings(
            ' -> ',
            formatVenues(pickup_venues),
            formatVenues(delivery_venues),
          ),
    [`${prefix}vehicle condition`]:
      vehicle_is_inoperable === true
        ? 'inoperable'
        : vehicle_is_inoperable === false
        ? 'operable'
        : undefined,
    [`${prefix}vehicle type`]: !vehicle_types.length
      ? undefined
      : vehicle_types.map((type) => formatVehicleType(type)).join('; '),
    [`${prefix}payment terms`]:
      terms && formatPaymentTermsLabel(parsePaymentTerms(terms)).toLowerCase(),
    [`${prefix}trailer type`]: !transport_type
      ? undefined
      : transport_type.toLowerCase(),
    [`${prefix}included shippers`]: !shipper.include
      ? undefined
      : shipper.include.map((x) => x.display_name).join('; '),
    [`${prefix}excluded shippers`]: !shipper.exclude
      ? undefined
      : shipper.exclude.map((x) => x.display_name).join('; '),
    [`${prefix}min. price per vehicle`]:
      (price_per_km && convertPricePerKmToPricePerMile(price_per_km, 2)) ||
      undefined,
    [`${prefix}min. number of vehicles`]: vehicle_count || undefined,
    [`${prefix}max. number of vehicles`]: max_vehicle_count || undefined,
    [`${prefix}min. total price`]: price || undefined,
    [`${prefix}book now`]: String(!!show_bookable),
    [`${prefix}search_along_route`]: formatBooleanValue(!!search_along_route),
    [`${prefix}latr_off_route_radius`]: distance_off_route,
    [`${prefix}ready_within_days`]: ready_within_days,
    [`${prefix}latr_waypoints`]: !latr_waypoints.length
      ? undefined
      : formatVenues(latr_waypoints),
  };
}

function formatSavedSearch(savedSearch: PostingSavedSearchDTO, prefix = '') {
  const { description, alert_duration, search_criteria } = savedSearch;
  return {
    ...formatSearchCriteria(search_criteria, prefix),
    [`${prefix}name`]: description,
    [`${prefix}alert status`]: isSavedSearchMuted(savedSearch)
      ? 'Muted'
      : alert_duration
      ? alertDurationOptions[alert_duration]
      : 'Unknown',
  };
}

function formatPostingTabName({
  current_tab,
  current_saved_search_guid,
}: PostingsPageParamsDTO) {
  const tab = current_saved_search_guid
    ? 'Load Alerts'
    : capitalize(current_tab);

  return `${tab} Tab`;
}

function formatPostingPageParams(params: PostingsPageParamsDTO, prefix = '') {
  return {
    [`${prefix}page`]: params.current_page,
    [`${prefix}page sort`]: params.page_sort,
    [`${prefix}page size`]: params.current_page_size,
    [`${prefix}saved search guid`]: params.current_saved_search_guid,
    [`${prefix}tab name`]: !params.current_saved_search_guid
      ? params.current_tab
      : 'saved-search',
  };
}

function formatPosting({ load, is_saved }: PostingDTO, prefix = 'load ') {
  const postedToLoadboardAt = parseDate(load.posted_to_loadboard_at, {
    format: 'JodaISO',
  });
  return {
    [`${prefix}guid`]: load.guid,
    [`${prefix}price`]: load.price,
    [`${prefix}payment terms`]: formatPaymentTerm(load.payment.terms, {
      fallback: load.payment.terms,
      short: true,
    }),
    [`${prefix}payment method`]: formatPaymentMethod(load.payment.method, {
      fallback: load.payment.method,
    }),
    [`${prefix}shipper guid`]: load.shipper?.guid,
    [`${prefix}posting guid`]: load.posting_guid,
    [`${prefix}is saved load`]: formatBooleanValue(is_saved),
    [`${prefix}price per mile`]:
      load.price_per_km == null
        ? undefined
        : round(convertPricePerKmToPricePerMile(load.price_per_km), 2),
    [`${prefix}is exclusive`]:
      load.is_exclusive == null
        ? undefined
        : formatBooleanValue(load.is_exclusive),
    [`${prefix}is private load`]:
      load.is_posted_to_private_loadboard == null
        ? undefined
        : formatBooleanValue(load.is_posted_to_private_loadboard),
    [`${prefix}posted date`]: postedToLoadboardAt.toUTC().toISO(),
    [`${prefix}time from posted`]: !postedToLoadboardAt.isValid
      ? undefined
      : Math.abs(Math.round(postedToLoadboardAt.diffNow().as('minutes'))),
    [`${prefix}lane`]: joinStrings(
      ' -> ',
      formatAddressLine(
        load.pickup.venue.state,
        load.pickup.venue.city,
        load.pickup.venue.zip,
      ),
      formatAddressLine(
        load.delivery.venue.state,
        load.delivery.venue.city,
        load.delivery.venue.zip,
      ),
    ),
    [`${prefix}pickup date type`]: load.pickup.date_type,
    [`${prefix}pickup date`]: load.pickup.scheduled_at,
    [`${prefix}pickup date end`]: load.pickup.scheduled_ends_at,
    [`${prefix}delivery date type`]: load.delivery.date_type,
    [`${prefix}delivery date`]: load.delivery.scheduled_at,
    [`${prefix}delivery date end`]: load.delivery.scheduled_ends_at,
    [`${prefix}pickup state`]: load.pickup.venue.state,
    [`${prefix}pickup state and city`]: joinStrings(
      ', ',
      load.pickup.venue.state,
      load.pickup.venue.city,
    ),
    [`${prefix}pickup business type`]:
      load.pickup.venue.business_type &&
      formatCustomerType(load.pickup.venue.business_type),
    [`${prefix}delivery state`]: load.delivery.venue.state,
    [`${prefix}delivery state and city`]: joinStrings(
      ', ',
      load.delivery.venue.state,
      load.delivery.venue.city,
    ),
    [`${prefix}delivery business type`]:
      load.delivery.venue.business_type &&
      formatCustomerType(load.delivery.venue.business_type),
    [`${prefix}can request`]: formatBooleanValue(
      load.carrier_access.can_request,
    ),
    [`${prefix}can book`]: formatBooleanValue(load.carrier_access.can_book),
    [`${prefix}vehicle count`]: load.vehicles.length,
    [`${prefix}has photos`]: formatBooleanValue(
      load.vehicles.some(hasVehicleSamplePhoto),
    ),
    [`${prefix}loads along route`]: formatBooleanValue(
      load.is_search_along_route,
    ),
  };
}

export function getDiffStatus<T>(prev: T, next: T): string | undefined {
  if (prev === next) {
    return undefined;
  }

  if (typeof prev === 'boolean' && typeof next === 'boolean') {
    return next ? 'enabled' : 'disabled';
  }

  return !prev && next ? 'added' : prev && !next ? 'removed' : 'changed';
}

function convertLoadboardEventProperties(
  event: LoadboardEvent,
): Record<string, unknown> {
  switch (event.name) {
    case 'SLB: Saved Load': {
      return formatPosting(event.load);
    }
    case 'SLB: Request/Book Time': {
      const openTime = parseDate(event.openTime, { format: 'JodaISO' });
      const postedToLoadboardAt = parseDate(event.postedToLoadboardAt, {
        format: 'JodaISO',
      });

      return {
        type: event.type,
        duration: Math.abs(Math.round(openTime.diffNow().as('seconds'))),
        'time from posted': !postedToLoadboardAt.isValid
          ? undefined
          : Math.abs(Math.round(postedToLoadboardAt.diffNow().as('minutes'))),
      };
    }
    case 'SLB: Searched For Loads': {
      return {
        'results count': event.resultCount,
        ...formatSearchCriteria(event.criteria),
        ...formatPostingPageParams(event.params),
      };
    }

    case 'SLB: Clicked Tab': {
      return {
        'tab name': event.tabName,
        'requested notifications': event.tabItemCount,
      };
    }

    case "SLB: Clicked Shipper's Private Loadboard Tab": {
      return {
        'shipper guid': event.shipper.guid,
        'shipper name': event.shipper.name,
      };
    }

    case 'SLB: Saved A Search':
    case 'SLB: Created Load Alert': {
      const { utm_medium } = parseSearchQuery(window.location.search);

      return {
        ...formatSavedSearch(event.savedSearch),
        utm_medium: event.source || utm_medium,
      };
    }

    case 'SLB: Updated Saved Search Criteria':
    case 'SLB: Updated Load Alert Criteria': {
      const next = formatSavedSearch(event.savedSearch);
      const prev = formatSavedSearch(event.prevSavedSearch);

      return formatChangedValues(prev, next);
    }

    case 'SLB: Remove Saved Search':
    case 'SLB: Remove Load Alert':
    case 'SLB: Clicked Saved Search':
    case 'SLB: Clicked Load Alert': {
      const { savedSearch } = event;
      return {
        guid: savedSearch.guid,
        'search name': savedSearch.description,
        ...formatSearchCriteria(savedSearch.search_criteria, 'filter '),
      };
    }

    case 'SLB: Opened Load Details':
    case 'SLB: Viewed Load': {
      const { params, posting, source, position } = event;
      const { utm_medium } = parseSearchQuery(window.location.search);

      return {
        ...formatPostingPageParams(params),
        ...formatSearchCriteria(params.search_criteria, 'filter '),
        ...formatPosting(posting),
        'load position': position,
        utm_medium: source || utm_medium || formatPostingTabName(params),
      };
    }

    case 'SLB: Requested Load': {
      const {
        params,
        bookQuery,
        posting,
        carrierPostingInfo,
        params: { search_criteria },
      } = event;
      const { utm_medium } = parseSearchQuery(window.location.search);

      return {
        ...formatPostingPageParams(params),
        ...formatPosting(event.posting),
        ...formatPostingRequest({
          bookQuery,
          load: posting.load,
          carrierPostingInfo,
        }),
        ...formatSearchCriteria(search_criteria, 'filter '),
        'request status': carrierPostingInfo
          ? carrierPostingInfo.status === 'REQUEST_CANCELED'
            ? 'canceled'
            : carrierPostingInfo.status === 'REQUEST_DECLINED_BY_BROKER'
            ? 'declined'
            : 'repeat sent'
          : 'sent',
        utm_medium: utm_medium || formatPostingTabName(params),
      };
    }

    case 'SLB: Updated Load Request': {
      return {
        ...formatPostingRequest({
          bookQuery: event.bookQuery,
          carrierPostingInfo: event.carrierPostingInfo,
        }),

        'filter saved search guid': event.savedSearchGuid,
        'tab name': !event.savedSearchGuid ? event.tabName : 'saved-search',
      };
    }

    case 'SLB: Canceled Load Request': {
      return {
        ...formatPosting({
          suggestion_id: null,
          is_suggestion_seen: null,
          load: event.load,
          carrier_load: null,
          pickup_distance: null,
          delivery_distance: null,
          is_saved: false,
        }),

        'tab name': event.tabName,
      };
    }

    case 'SLB: Booked Load': {
      const {
        params,
        posting,
        bookQuery,
        params: { search_criteria },
      } = event;
      const { utm_medium } = parseSearchQuery(window.location.search);

      return {
        ...formatPostingPageParams(params),
        ...formatPosting(posting),
        ...formatSearchCriteria(search_criteria, 'filter '),
        ...formatPostingRequest({
          bookQuery,
          load: posting.load,
        }),
        utm_medium: utm_medium || formatPostingTabName(params),
      };
    }
    case 'SLB: Notification settings updated': {
      const { notificationSettings, updatedNotificationSettings } = event;
      const activityLabels: Record<NotificationsSettingActivity, string> = {
        saved_search: 'Saved Search',
        load_suggested: 'Suggested Loads',
        request_declined: 'Declined Requests',
      };

      return Object.keys(notificationSettings).reduce<
        Record<string, { Email?: string; SMS?: string; Push?: string }>
      >((acc, activity: NotificationsSettingActivity) => {
        const title = activityLabels[activity];
        const prev = notificationSettings[activity];
        const next = updatedNotificationSettings[activity];

        if (prev && next) {
          acc[title] = {
            Email: getDiffStatus(prev.email_enabled, next.email_enabled),
            Push:
              'push_enabled' in prev && 'push_enabled' in next
                ? getDiffStatus(prev.push_enabled, next.push_enabled)
                : undefined,
            SMS:
              'sms_enabled' in prev && 'sms_enabled' in next
                ? getDiffStatus(prev.sms_enabled, next.sms_enabled)
                : undefined,
          };
        }

        return acc;
      }, {});
    }
    case 'SLB: All notification settings updated': {
      const { updatedNotificationSettings } = event;
      return {
        Active: updatedNotificationSettings.some(({ active }) => active)
          ? 'enabled'
          : 'disabled',
      };
    }
    case 'SLB: Clicked Create Load Alert': {
      const { utm_medium } = parseSearchQuery(window.location.search);
      return {
        utm_medium: utm_medium || event.source,
      };
    }
    case 'SLB: Clicked Search Similar Loads Button': {
      return { utm_medium: 'Similar Loads', utm_content: event.source };
    }

    default: {
      const { name, ...props } = event;

      return { ...props };
    }
  }
}

export function trackLoadboardEvent(
  event: LoadboardEvent,
  callback?: () => void,
) {
  const props = convertLoadboardEventProperties(event);

  trackEvent(event.name, props, callback);
}

export function useTrackLoadboardEvent(event: LoadboardEvent) {
  const pureEvent = useDeepEqualValue(event);

  useEffect(() => {
    trackLoadboardEvent(pureEvent);
  }, [pureEvent]);
}
