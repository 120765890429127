import { PaymentMethod, PAYMENT_METHODS } from '@superdispatch/sdk';
import { normalizeURL } from '@superdispatch/uri';
import { DateTime } from 'luxon';
import { joinStrings } from 'shared/utils/StringUtils';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const paymentMethodSchema = yupArray<PaymentMethod>(
  yupEnum(PAYMENT_METHODS),
).nullable();

export const SHIPPER_VERIFICATION_STATUSES = [
  'NOT_APPROVED',
  'APPROVED',
] as const;

export const INSURANCE_TYPE = ['BOND', 'INSURANCE'] as const;

export type ShipperProfileDTO = InferType<typeof shipperProfileSchema>;
export const shipperProfileSchema = yupObject({
  guid: yupString(),
  name: yupString(),

  verification_status: yupEnum(SHIPPER_VERIFICATION_STATUSES, null),

  accepted_loads_count: yupNumber(),
  overall_moved_vehicles_count: yupNumber(),
  six_month_moved_vehicles_count: yupNumber(),

  ach_payments: yupBoolean().default(null),
  carrier_certificate_of_insurance: yupEnum(
    ['CERTIFICATE_HOLDER', 'ADDITIONALLY_INSURED'],
    null,
  ),
  about_us: yupString().nullable(),
  source: yupString().nullable(),
  subscription_plan: yupString().nullable(),
  terms_and_conditions: yupString().nullable(),
  usdot_number: yupString().nullable(),

  state: yupString().nullable(),
  zip: yupString().nullable(),
  city: yupString().nullable(),
  address: yupString().nullable(),

  personal_page_url: yupString()
    .nullable()
    .transform((value) => normalizeURL(value)),
  agent_phone: yupString().nullable(),
  in_business_since: yupString().nullable(),
  is_test_account: yupBoolean().default(null),

  contact_email: yupString(),
  contact_phone: yupString(),
  contact_name: yupString(),

  bond_agent: yupString().nullable(),
  bond_company: yupString().nullable(),
  bond_expiration_date: yupDateString('DateISO'),
  company_insurance_type: yupEnum(INSURANCE_TYPE, null),

  billing_contact_name: yupString().nullable(),
  billing_email: yupString().nullable(),
  billing_phone: yupString().nullable(),

  logo_url: yupString().nullable(),
  carrier_requirements: yupString().nullable(),
  last_offer_accepted_date: yupDateString('DateISO'),
  alternative_payment_methods: paymentMethodSchema,
  preferred_payment_methods: paymentMethodSchema,

  referrals: yupArray(
    yupObject({
      name: yupString().nullable(),
      contact_name: yupString().nullable(),
      phone: yupString().nullable(),
    }),
  ).nullable(),

  rating_details: yupObject({
    overall_rating: yupNumber().default(0),
    total_rating_count: yupNumber().default(0),
  }).nullable(),

  carrier_insurance_requirements: yupArray(
    yupObject({
      trailer_size: yupNumber().default(0),
      cargo_limit: yupNumber().default(0),
    }),
  ).nullable(),
});

export type ShipperExperienceDTO = InferType<typeof shipperExperienceSchema>;
export const shipperExperienceSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  has_limited_experience: yupBoolean(),
});

export function formatInBusinessSince(inBusinessSince: string): string {
  const [year, month] = inBusinessSince.split('.');
  const monthName = DateTime.fromObject({ month: Number(month) }).toFormat(
    'LLLL',
  );

  return `${monthName}, ${year}`;
}

export function formatShipperAddress({
  address,
  city,
  state,
  zip,
}: ShipperProfileDTO) {
  return joinStrings(
    ', ',
    joinStrings(' ', address, city),
    joinStrings(' ', state, zip),
  );
}
