import { Tab, Tabs } from '@material-ui/core';
import { Inline, Stack, Tag, useResponsiveValue } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'shared/layout/PageLayout';
import { BackButton } from 'shared/ui/BackButton';
import styled from 'styled-components';
import { LoadStage } from '../../loads/data/LoadDTO';
import { useLoadsPageContext } from '../../loads/loads-page/data/LoadsPageParamsContext';
import { LoadsList } from '../../loads/LoadsList';
import { useDelayedLoadsCount } from '../data/DashboardAPI';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function DashboardLoadsList() {
  const navigate = useNavigate();
  const { params, updateParams } = useLoadsPageContext();
  const { data: delayedLoadsCount } = useDelayedLoadsCount();
  const isMobile = useResponsiveValue(true, false);

  const deliverCount = delayedLoadsCount?.delivery;
  const pickupCount = delayedLoadsCount?.pickup;
  const unassignedCount = delayedLoadsCount?.unassigned;

  return (
    <LoadsList
      showDelayedDays={true}
      pageHeader={
        <Stack space="none">
          <PageHeader
            title={
              <TitleContainer>
                <BackButton
                  onClick={() => {
                    navigate('/dashboard', { replace: true });
                  }}
                />
                <TextBox variant="heading-2">
                  Delayed and Unassigned Loads
                </TextBox>
              </TitleContainer>
            }
          />
          <Box paddingLeft={['none', 'large']}>
            <Tabs
              value={params.stage}
              variant="scrollable"
              scrollButtons={isMobile ? 'on' : 'auto'}
              onChange={(_, nextStage) => {
                updateParams(
                  { stage: nextStage as LoadStage, page: 1 },
                  { strategy: 'merge' },
                );
              }}
            >
              <Tab
                value="delayed-pickups"
                label={
                  <Inline space="xsmall" noWrap={true}>
                    <span>Delayed Pickups</span>
                    <Tag
                      variant="subtle"
                      color={
                        params.stage === 'delayed-pickups' ? 'blue' : 'grey'
                      }
                    >
                      {pickupCount}
                    </Tag>
                  </Inline>
                }
              />
              <Tab
                value="delayed-deliveries"
                label={
                  <Inline space="xsmall" noWrap={true}>
                    <span>Delayed Deliveries</span>
                    <Tag
                      variant="subtle"
                      color={
                        params.stage === 'delayed-deliveries' ? 'blue' : 'grey'
                      }
                    >
                      {deliverCount}
                    </Tag>
                  </Inline>
                }
              />
              <Tab
                value="aging"
                label={
                  <Inline space="xsmall" noWrap={true}>
                    <span>Unassigned</span>
                    <Tag
                      variant="subtle"
                      color={params.stage === 'aging' ? 'blue' : 'grey'}
                    >
                      {unassignedCount}
                    </Tag>
                  </Inline>
                }
              />
            </Tabs>
          </Box>
        </Stack>
      }
    />
  );
}
