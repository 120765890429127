import { Dialog, DialogContent, Link, Typography } from '@material-ui/core';
import { Image, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import JoinRequestPendingIconDark from '../assets/join-request-icon-dark.svg';
import JoinRequestPendingIcon from '../assets/join-request-icon.svg';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 24px;
    max-width: 464px;
    text-align: center;
  }
`;

export interface LoginJoinRequestErrorDialogProps {
  carrierName?: string;
  phoneNumber?: string;
  open: boolean;
  onClose: () => void;
}

export function LoginJoinRequestErrorDialog({
  carrierName,
  phoneNumber,
  open,
  onClose,
}: LoginJoinRequestErrorDialogProps) {
  return (
    <StyledDialog
      open={open}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <DialogContent>
        <Stack space="medium">
          <Image
            src={JoinRequestPendingIcon}
            srcDark={JoinRequestPendingIconDark}
            alt="Join Request Pending"
          />
          <Stack>
            <Typography variant="h2" align="center">
              Waiting for Confirmation
            </Typography>
            <Typography align="center" variant="subtitle2">
              An email has been sent to{' '}
              <Typography variant="body1" display="inline">
                {carrierName}
              </Typography>{' '}
              to review your join request.{' '}
              {phoneNumber && (
                <>
                  You can also contact dispatcher at{' '}
                  <Link
                    href={`tel:${phoneNumber}`}
                    color="primary"
                    underline="none"
                    noWrap={true}
                  >
                    {phoneNumber}
                  </Link>
                </>
              )}
            </Typography>
          </Stack>
          <Button onClick={onClose}>Got It</Button>
        </Stack>
      </DialogContent>
    </StyledDialog>
  );
}
