import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const ArrowForwardIcon = createSvgIcon(
  'ArrowForwardIcon',
  <path
    d="M6.99967 2.33325L6.17717 3.15575L9.43218 6.41659H2.33301V7.58325H9.43218L6.17717 10.8441L6.99967 11.6666L11.6663 6.99992L6.99967 2.33325Z"
    fill={ColorDynamic.Dark500}
  />,
  {
    viewBox: '0 0 14 14',
    style: {
      width: '14px',
      height: '14px',
    },
  },
);
