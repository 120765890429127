import { trackEvent } from 'shared/helpers/Analytics';

export type LoadboardEvent =
  | {
      name: 'SLB: Tapped Become Verified Carrier Banner';
    }
  | {
      name: 'Carrier Viewed Become Verified Popup';
      utm_medium: 'First View' | 'Verified Carrier Banner';
    };

export function trackLoadboardEvent(event: LoadboardEvent) {
  const { name, ...props } = event;

  trackEvent(name, { props });
}
