import { Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { ArrowForwardIcon } from 'shared/icons/ArrowForwardIcon';
import { DeliveryAltIcon } from 'shared/icons/DeliveryAltIcon';
import { PickupAltIcon } from 'shared/icons/PickupAltIcon';
import { LinkAnchor } from 'shared/routing/Links';
import { joinStrings } from 'shared/utils/StringUtils';
import { DelayedLoadDTO, DelayedLoadStage } from '../data/DashboardDTO';

export function DelayedLoadItem({
  load,
  stage,
}: {
  load: DelayedLoadDTO;
  stage: DelayedLoadStage;
}) {
  const vehicle = useMemo(() => {
    if (load.vehicles && load.vehicles.length === 1) {
      return joinStrings(
        ' ',
        load.vehicles[0]?.year,
        load.vehicles[0]?.make,
        load.vehicles[0]?.model,
      );
    } else if (load.vehicles && load.vehicles.length > 1) {
      return `${load.vehicles.length} vehicles`;
    }
    return null;
  }, [load.vehicles]);

  const origin = formatAddressLine('', load.pickup_city, load.pickup_state);
  const destination = formatAddressLine(
    '',
    load.delivery_city,
    load.delivery_state,
  );
  return (
    <Box
      width="100%"
      paddingLeft="medium"
      paddingTop="small"
      paddingBottom="small"
      paddingRight="xsmall"
    >
      <Columns space="xsmall">
        <Column width="content">
          {stage === 'pickup' ? (
            <PickupAltIcon htmlColor={Color.Dark100} />
          ) : (
            <DeliveryAltIcon htmlColor={Color.Dark100} />
          )}
        </Column>
        <Column width="fluid">
          <Stack space="xxsmall">
            <LinkAnchor
              to={`/loads/${load.guid}`}
              variant="body1"
              underline="always"
            >
              {load.number}
            </LinkAnchor>
            <Inline space="xxsmall" verticalAlign="center">
              <Typography color={origin ? 'textPrimary' : 'textSecondary'}>
                {origin || 'N/A'}
              </Typography>

              <ArrowForwardIcon />

              <Typography color={destination ? 'textPrimary' : 'textSecondary'}>
                {destination || 'N/A'}
              </Typography>
            </Inline>
            {load.vehicles?.length === 1 && (
              <Typography variant="body2" color="textSecondary">
                {vehicle}
              </Typography>
            )}
          </Stack>
        </Column>
        <Column width="content">
          <Tag variant="subtle" color="yellow">
            {load.delayed_days} {formatPlural(load.delayed_days, 'day', 'days')}
          </Tag>
        </Column>
      </Columns>
    </Box>
  );
}
