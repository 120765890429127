import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { isAPIError } from 'shared/api/APIError';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import { trackSubscriptionEvent } from './data/SubscriptionAnalytics';
import {
  useEstimateTaxPrice,
  useSubscriptionAPI,
  useSubscriptionBillableSeats,
  useSubscriptionDetails,
} from './SubscriptionAPI';

interface SubscriptionReactivationDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubscriptionReactivationDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: SubscriptionReactivationDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Reactivating Plan"
        endAction={
          <IconButton edge="end" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <ReactivationDrawerContent
        onSubmitSuccess={onSubmitSuccess}
        onClose={onClose}
      />
    </Drawer>
  );
}

function getCountryName(countryCode: CountryFieldOption) {
  return countryFieldOptions.get(countryCode);
}

interface ReactivationDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

function ReactivationDrawerContent({
  onClose,
  onSubmitSuccess,
}: ReactivationDrawerContentProps) {
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const isSubscriptionOnLitePlan =
    subscriptionDetails?.subscription?.plan?.name === 'lite';
  const { data: billableSeats } = useSubscriptionBillableSeats({
    measurement_type: isSubscriptionOnLitePlan
      ? subscriptionDetails?.upgrade_option?.plan?.measurement_type
      : subscriptionDetails?.subscription?.plan?.measurement_type,
  });
  const { data: taxPrice, isLoading: isTaxPriceLoading } = useEstimateTaxPrice({
    seats_count: billableSeats?.billable_seats,
    include_total_invoice: true,
  });
  const { reactivateSubscription } = useSubscriptionAPI();
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { closeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      return reactivateSubscription();
    },
    onSubmitSuccess: () => {
      closeTrialExpiredDialog();
      closeSubscriptionCancelledDialog();
      trackSubscriptionEvent({
        name: 'Carrier Reactivated Subscription',
      });
      onClose();
      onSubmitSuccess?.();
    },
  });

  const errorMessage = isAPIError(formik.status.payload)
    ? formik.status.payload.message
    : null;

  const billingAddress = subscriptionDetails?.customer?.billing_address;

  const country = getCountryName(billingAddress?.country as CountryFieldOption);
  const state = billingAddress?.state || '';
  const zipCode = billingAddress?.zip || '';
  const cardDetails = subscriptionDetails?.customer?.payment_method?.card;

  useEffect(() => {
    trackSubscriptionEvent({
      name: 'Carrier Opened Subscription Checkout',
    });
  }, []);

  return (
    <DrawerContent>
      {isTaxPriceLoading ? (
        <Stack space="small">
          <Skeleton width={240} />
          <Skeleton />
          <Skeleton />
          <Skeleton width={240} />
          <Skeleton />
          <Skeleton />
        </Stack>
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <Stack space="medium">
              <Box paddingBottom="small">
                <Stack space="large">
                  <Stack space="xsmall">
                    <Typography color="textSecondary" variant="h6">
                      CARD DETAILS
                    </Typography>
                    <Typography color="textSecondary">
                      Card Number: {cardDetails?.masked_number}
                    </Typography>
                    <Typography color="textSecondary">
                      Expiration Date: {cardDetails?.expiry_month}/
                      {cardDetails?.expiry_year}
                    </Typography>
                  </Stack>

                  <Stack space="xsmall">
                    <Typography color="textSecondary" variant="h6">
                      Billing Address
                    </Typography>
                    <Typography color="textSecondary">
                      {joinStrings(', ', state + ' ' + zipCode, country)}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Stack space="small">
                {subscriptionDetails?.subscription?.plan && (
                  <Stack space="small">
                    <Stack space="xxsmall">
                      <DescriptionLineItem
                        title={
                          <TextBox variant="body" color="primary">
                            Pro
                          </TextBox>
                        }
                      >
                        <TextBox align="right" color="primary">
                          {formatCurrency(taxPrice?.plan?.amount)}
                        </TextBox>
                      </DescriptionLineItem>

                      <TextBox color="secondary">
                        {formatCurrency(taxPrice?.plan?.unit_price)} / one
                        driver and TMS
                      </TextBox>
                      <TextBox color="secondary">Billed Monthly</TextBox>
                    </Stack>
                  </Stack>
                )}

                <Stack space="xsmall">
                  <DescriptionLineItem
                    title={
                      <TextBox variant="body" color="primary">
                        Subtotal
                      </TextBox>
                    }
                  >
                    <TextBox align="right" color="primary">
                      {formatCurrency(taxPrice?.sub_total)}
                    </TextBox>
                  </DescriptionLineItem>
                  {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                    <Columns>
                      <Column>
                        <Box />
                      </Column>
                      <Column width="2/3">
                        <Stack align="right" space="xxsmall">
                          {taxPrice.taxes.map((tax) => {
                            return (
                              <DescriptionLineItem
                                key={tax.name}
                                title={
                                  <TextBox color="secondary">
                                    {tax.description}
                                  </TextBox>
                                }
                              >
                                <TextBox align="right" color="secondary">
                                  {formatCurrency(tax.amount)}
                                </TextBox>
                              </DescriptionLineItem>
                            );
                          })}
                        </Stack>
                      </Column>
                    </Columns>
                  )}
                </Stack>

                <DescriptionLineItem
                  title={<TextBox variant="heading-4">Total</TextBox>}
                >
                  <TextBox variant="heading-4" align="right">
                    {formatCurrency(taxPrice?.total)}
                  </TextBox>
                </DescriptionLineItem>
              </Stack>
              <Box paddingTop="xsmall">
                <Button
                  pending={formik.isSubmitting}
                  type="submit"
                  size="large"
                  fullWidth={true}
                >
                  Reactivate Now
                </Button>
              </Box>

              {errorMessage && (
                <Alert
                  icon={
                    <Info fontSize="small" htmlColor={ColorDynamic.Red500} />
                  }
                  severity="critical"
                >
                  {errorMessage}
                </Alert>
              )}
            </Stack>
          </Form>
        </FormikProvider>
      )}
    </DrawerContent>
  );
}
