import { LocalStore } from 'shared/helpers/Store';
import { useShipperExperienceList } from 'shared/modules/shipper/LoadboardShipperAPI';
import { useFlag } from 'shared/settings/FeatureToggles';

export const IGNORE_WARNING_SHIPPER_LIST =
  'IgnoreLimitedExperienceWarningShipperList';

export function saveShippersExperienceWarningPreference(
  shipperGuids: string | string[],
) {
  const storeValue = LocalStore.get(IGNORE_WARNING_SHIPPER_LIST);
  const shippers = storeValue ? (JSON.parse(storeValue) as string[]) : [];
  const newShippers = new Set([
    ...shippers,
    ...(typeof shipperGuids === 'string' ? [shipperGuids] : shipperGuids),
  ]);

  LocalStore.set(
    IGNORE_WARNING_SHIPPER_LIST,
    JSON.stringify(Array.from(newShippers)),
  );
}

export function isShipperExperienceWarningDisabled(shipperGuid: string) {
  const storeValue = LocalStore.get(IGNORE_WARNING_SHIPPER_LIST);
  const shippers = new Set(
    storeValue ? (JSON.parse(storeValue) as string[]) : [],
  );
  return shippers.has(shipperGuid);
}

export function useShipperListHasLimitedExperience(
  guids: Array<string | undefined>,
) {
  const isShipperLimitedExperienceEnabled = useFlag(
    'shipper_limited_experience',
  );

  const notDisabledGuids = guids.filter(
    (guid) => !!guid && !isShipperExperienceWarningDisabled(guid),
  );

  const { data } = useShipperExperienceList(notDisabledGuids, {
    enabled: isShipperLimitedExperienceEnabled,
  });

  return {
    experienceList: data?.data,
    hasLimitedExperienceShipper:
      data?.data.some((shipper) => shipper.has_limited_experience) ?? false,
  };
}
