import {
  Divider,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core';
import { Error, ThumbUp } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { NavLink } from 'react-router-dom';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { SidebarLinkLegacy } from 'shared/layout/sidebar/AppSidebarMenuLegacy';
import styled from 'styled-components';

const SuperCarrierBadge = styled(SuperCarrierBadgeIcon)`
  align-self: center;
  color: ${Color.Green300};
  margin-left: 4px;
`;

interface CarrierMenuListProps {
  userName: string;
  ratingsLink: SidebarLinkLegacy;
  carrierItems: SidebarLinkLegacy[];
  dispatcherItems: SidebarLinkLegacy[];
  accountItems: SidebarLinkLegacy[];
  companyName: string;
  isVerified: boolean;
  carrierRating: { count: number; percentage: number };
  handleCloseMenu?: () => void;
}

export function AppCarrierMenuList({
  userName,
  companyName,
  isVerified,
  carrierRating,
  ratingsLink,
  carrierItems,
  dispatcherItems,
  accountItems,
  handleCloseMenu,
}: CarrierMenuListProps) {
  return (
    <Stack>
      <Box paddingTop="small" paddingLeft="small" paddingRight="small">
        <Box display="flex" paddingBottom="xsmall">
          <TextBox noWrap={true} variant="body">
            {companyName}
          </TextBox>

          {isVerified && <SuperCarrierBadge fontSize="small" />}
        </Box>

        <Inline verticalAlign="center" space="xsmall">
          <Inline verticalAlign="center" space="xxsmall">
            <ThumbUp fontSize="small" color="action" />

            <TextBox>{carrierRating.percentage}%</TextBox>

            <Typography color="textSecondary">
              ({carrierRating.count})
            </Typography>
          </Inline>

          <Link
            component={ratingsLink.localPath ? NavLink : 'a'}
            href="/tms/profile/overview"
            to={ratingsLink.localPath}
            color="primary"
            onClick={() => {
              handleCloseMenu?.();
            }}
          >
            View Ratings
          </Link>
        </Inline>
      </Box>
      <MenuList>
        {carrierItems
          .filter(({ hidden }) => !hidden)
          .map(
            ({
              href,
              label,
              Icon,
              localPath,
              target,
              onClick,
              hasError,
              tag,
            }) => (
              <MenuItem
                href={href}
                dense={true}
                to={localPath}
                key={label}
                onClick={() => {
                  onClick?.();
                  handleCloseMenu?.();
                }}
                component={localPath ? NavLink : 'a'}
                target={target}
                rel="noopener noreferrer"
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon htmlColor={Color.Dark200} />
                  </ListItemIcon>
                )}
                <ListItemText>
                  <Inline space="small">
                    {label}
                    {tag}
                  </Inline>
                </ListItemText>
                {hasError && <Error fontSize="small" color="error" />}
              </MenuItem>
            ),
          )}
      </MenuList>

      <Divider />

      <MenuList>
        <Box paddingLeft="small" paddingRight="small">
          <Typography noWrap={true} variant="body1" color="textSecondary">
            {userName}
          </Typography>
        </Box>

        {dispatcherItems
          .filter(({ hidden }) => !hidden)
          .map(
            ({ href, label, Icon, RightIcon, localPath, target, onClick }) => (
              <MenuItem
                href={href}
                dense={true}
                to={localPath}
                key={label}
                onClick={() => {
                  onClick?.();
                  handleCloseMenu?.();
                }}
                component={localPath ? NavLink : 'a'}
                target={target}
                alignItems="center"
                rel="noopener noreferrer"
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon htmlColor={Color.Dark200} />
                  </ListItemIcon>
                )}
                <ListItemText>{label}</ListItemText>
                {RightIcon && (
                  <ListItemSecondaryAction>
                    <RightIcon />
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
            ),
          )}
      </MenuList>

      <Divider />

      <MenuList>
        {accountItems
          .filter(({ hidden }) => !hidden)
          .map(
            ({
              href,
              label,
              Icon,
              localPath,
              target,
              dataIntercomTarget,
              onClick,
            }) => (
              <MenuItem
                href={href}
                dense={true}
                to={localPath}
                key={label}
                onClick={() => {
                  onClick?.();
                  handleCloseMenu?.();
                }}
                component={localPath ? NavLink : 'a'}
                target={target}
                rel="noopener noreferrer"
                data-intercom-target={dataIntercomTarget}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon htmlColor={Color.Dark200} />
                  </ListItemIcon>
                )}
                <ListItemText>{label}</ListItemText>
              </MenuItem>
            ),
          )}
      </MenuList>
    </Stack>
  );
}
