import { useEffect } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { AppVersionChecker } from 'shared/app/AppVersionChecker';
import { LocalStore } from 'shared/helpers/Store';
import { ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY } from 'shared/modules/onboarding/data/OnboardingConstants';
import { useFlag } from 'shared/settings/FeatureToggles';
import { AddLoadPage } from './add/AddLoadPage';
import { listenToLoadEvents } from './data/LoadEvents';
import { LoadsContextProvider } from './data/LoadsContext';
import { EditLoadPage } from './edit-page/EditLoadPage';
import { LoadsPageContextProvider } from './loads-page/data/LoadsPageParamsContext';
import { LoadsPage } from './loads-page/LoadsPage';
import { ViewLoadPage } from './view/ViewLoadPage';
import { ViewLoadPagePrintPage } from './view/ViewLoadPagePrintPage';

export function LoadsContainerOutlet() {
  useEffect(() => {
    listenToLoadEvents((event) => {
      if (event === 'created' || event === 'imported') {
        LocalStore.set(ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY, 'pending');
      }
    });
  }, []);

  return (
    <LoadsContextProvider>
      <AppVersionChecker />
      <Outlet />
    </LoadsContextProvider>
  );
}

export const loadsRoutes: RouteObject[] = [
  {
    path: 'loads',
    element: <LoadsContainerOutlet />,
    children: [
      {
        index: true,
        element: <LoadsPageRoute />,
      },
      {
        path: 'add',
        element: <AddLoadPage />,
      },
      {
        path: ':loadGUID',
        element: <LoadOutlet />,
        children: [
          {
            path: 'edit',
            element: <EditLoadPage />,
          },
          {
            path: 'print',
            element: <ViewLoadPagePrintPage />,
          },
          {
            path: ':action?/:actionGUID?',
            element: <ViewLoadPage />,
          },
        ],
      },
    ],
  },
];

function LoadOutlet() {
  const shouldPersistParams = useFlag('react_loads_page_filter_persist');

  return (
    <LoadsPageContextProvider shouldPersistParams={shouldPersistParams}>
      <Outlet />
    </LoadsPageContextProvider>
  );
}

function LoadsPageRoute() {
  const shouldPersistParams = useFlag('react_loads_page_filter_persist');
  return (
    <LoadsPageContextProvider shouldPersistParams={shouldPersistParams}>
      <LoadsPage />
    </LoadsPageContextProvider>
  );
}
